import React, { useContext, useState } from "react";
import { Pressable, Platform, ViewStyle } from "react-native";

import NewOrderContext from "../../../contexts/new-order.ctx";
import { StyleProp } from "react-native";

const BasePressable: React.FC<{
  children: React.ReactNode;
  style?: StyleProp<ViewStyle>;
  onClick: () => void;
}> = ({ children, onClick, style }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isPressed, setIsPressed] = useState(false);
  const newOrderCtx = useContext(NewOrderContext);

  const onPress = () => {
    onClick();
  };

  const handleMouseEnter = () => {
    if (Platform.OS === "web") {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    if (Platform.OS === "web") {
      setIsHovered(false);
    }
  };

  const handlePressIn = () => {
    setIsPressed(true);
  };

  const handlePressOut = () => {
    setIsPressed(false);
  };

  return (
    <Pressable
      style={[
        style,
        (isHovered || isPressed) && {
          opacity: 0.7,
        },
      ]}
      onPress={onPress}
      onHoverIn={handleMouseEnter}
      onHoverOut={handleMouseLeave}
      onPressIn={handlePressIn}
      onPressOut={handlePressOut}
    >
      {children}
    </Pressable>
  );
};

export default BasePressable;
