import * as React from "react";
import Svg, {
  Path,
  SvgProps,
  Defs,
  LinearGradient,
  Stop,
} from "react-native-svg";

const USFlagIcon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width={19} height={13} viewBox="0 0 19 13" fill="none" {...props}>
      <Path
        d="M18.6724 12.4483H0.327601C0.146693 12.4483 0 12.3016 0 12.1207V0.327601C0 0.146693 0.146693 0 0.327601 0H18.6724C18.8533 0 19 0.146693 19 0.327601V12.1207C19 12.3016 18.8533 12.4483 18.6724 12.4483Z"
        fill="#F5F5F5"
      />
      <Path
        d="M19 0.957606H0V0.327601C0 0.146693 0.146693 0 0.327601 0H18.6724C18.8533 0 19 0.146693 19 0.327601L19 0.957606Z"
        fill="#FF4B55"
      />
      <Path d="M19 3.8302H0V4.78777H19V3.8302Z" fill="#FF4B55" />
      <Path d="M19 1.91504H0V2.87261H19V1.91504Z" fill="#FF4B55" />
      <Path
        d="M19 6.70291H0.327601C0.146693 6.70291 0 6.55621 0 6.37531V5.7453H19V6.70291Z"
        fill="#FF4B55"
      />
      <Path d="M19 9.57581H0V10.5334H19V9.57581Z" fill="#FF4B55" />
      <Path
        d="M18.6724 12.4483H0.327601C0.146693 12.4483 0 12.3016 0 12.1207V11.4907H19V12.1207C19 12.3016 18.8533 12.4483 18.6724 12.4483Z"
        fill="#FF4B55"
      />
      <Path d="M19 7.66071H0V8.61827H19V7.66071Z" fill="#FF4B55" />
      <Path
        d="M8.51722 6.10352e-05H0.327601C0.146693 6.10352e-05 0 0.146717 0 0.327625V6.37533C0 6.55624 0.146693 6.70293 0.327601 6.70293H8.51725C8.69816 6.70293 8.84485 6.55624 8.84485 6.37533V0.327625C8.84482 0.146717 8.69812 6.10352e-05 8.51722 6.10352e-05Z"
        fill="#41479B"
      />
      <Path
        d="M0.920294 0.751894L0.992805 0.969354L1.22203 0.971098C1.25172 0.971358 1.264 1.00921 1.24014 1.02684L1.05574 1.16303L1.12491 1.38156C1.13389 1.40984 1.10165 1.43329 1.07749 1.416L0.891015 1.28274L0.70454 1.41604C0.680382 1.43329 0.648208 1.40988 0.657115 1.3816L0.726286 1.16306L0.54189 1.02687C0.518029 1.00921 0.530312 0.971395 0.56 0.971135L0.789224 0.969391L0.861735 0.751931C0.871087 0.723728 0.910868 0.723728 0.920294 0.751894Z"
        fill="#F5F5F5"
      />
      <Path
        d="M0.920294 1.88944L0.992805 2.1069L1.22203 2.10864C1.25172 2.1089 1.264 2.14676 1.24014 2.16438L1.05574 2.30057L1.12491 2.51911C1.13389 2.54739 1.10165 2.57084 1.07749 2.55355L0.891015 2.42029L0.70454 2.55358C0.680382 2.57084 0.648208 2.54742 0.657115 2.51915L0.726286 2.30061L0.54189 2.16442C0.518029 2.14676 0.530312 2.10894 0.56 2.10868L0.789224 2.10694L0.861735 1.88948C0.871087 1.86131 0.910868 1.86131 0.920294 1.88944Z"
        fill="#F5F5F5"
      />
      <Path
        d="M0.920294 3.02701L0.992805 3.24447L1.22203 3.24622C1.25172 3.24648 1.264 3.28433 1.24014 3.30196L1.05574 3.43815L1.12491 3.65668C1.13389 3.68496 1.10165 3.70841 1.07749 3.69112L0.891015 3.55786L0.70454 3.69116C0.680382 3.70841 0.648208 3.685 0.657115 3.65672L0.726286 3.43818L0.54189 3.30199C0.518029 3.28433 0.530312 3.24651 0.56 3.24625L0.789224 3.24451L0.861735 3.02705C0.871087 2.99888 0.910868 2.99888 0.920294 3.02701Z"
        fill="#F5F5F5"
      />
      <Path
        d="M0.920294 4.16461L0.992805 4.38207L1.22203 4.38382C1.25172 4.38408 1.264 4.42193 1.24014 4.43956L1.05574 4.57575L1.12491 4.79428C1.13389 4.82256 1.10165 4.84601 1.07749 4.82872L0.891015 4.69546L0.70454 4.82876C0.680382 4.84601 0.648208 4.8226 0.657115 4.79432L0.726286 4.57578L0.54189 4.43959C0.518029 4.42193 0.530312 4.38411 0.56 4.38386L0.789224 4.38211L0.861735 4.16465C0.871087 4.13645 0.910868 4.13645 0.920294 4.16461Z"
        fill="#F5F5F5"
      />
      <Path
        d="M0.920294 5.30209L0.992805 5.51955L1.22203 5.5213C1.25172 5.52156 1.264 5.55941 1.24014 5.57704L1.05574 5.71323L1.12491 5.93176C1.13389 5.96004 1.10165 5.98349 1.07749 5.9662L0.891015 5.83294L0.70454 5.96624C0.680382 5.98349 0.648208 5.96008 0.657115 5.9318L0.726286 5.71326L0.54189 5.57707C0.518029 5.55941 0.530312 5.52159 0.56 5.52133L0.789224 5.51959L0.861735 5.30213C0.871087 5.27389 0.910868 5.27389 0.920294 5.30209Z"
        fill="#F5F5F5"
      />
      <Path
        d="M1.80311 1.30961L1.87562 1.52707L2.10484 1.52881C2.13453 1.52907 2.14681 1.56693 2.12295 1.58455L1.93856 1.72074L2.00773 1.93928C2.01671 1.96756 1.98446 1.99101 1.9603 1.97372L1.77383 1.84042L1.58735 1.97372C1.56319 1.99097 1.53102 1.96756 1.53993 1.93928L1.6091 1.72074L1.4247 1.58455C1.40084 1.56689 1.41312 1.52907 1.44281 1.52881L1.67204 1.52707L1.74455 1.30961C1.75386 1.28148 1.79372 1.28148 1.80311 1.30961Z"
        fill="#F5F5F5"
      />
      <Path
        d="M1.80311 2.44721L1.87562 2.66467L2.10484 2.66642C2.13453 2.66668 2.14681 2.70453 2.12295 2.72215L1.93856 2.85834L2.00773 3.07688C2.01671 3.10516 1.98446 3.12861 1.9603 3.11132L1.77383 2.97802L1.58735 3.11132C1.56319 3.12857 1.53102 3.1052 1.53993 3.07688L1.6091 2.85834L1.4247 2.72215C1.40084 2.70449 1.41312 2.66668 1.44281 2.66642L1.67204 2.66467L1.74455 2.44721C1.75386 2.41904 1.79372 2.41904 1.80311 2.44721Z"
        fill="#F5F5F5"
      />
      <Path
        d="M1.80311 3.58478L1.87562 3.80224L2.10484 3.80399C2.13453 3.80425 2.14681 3.8421 2.12295 3.85973L1.93856 3.99592L2.00773 4.21445C2.01671 4.24273 1.98446 4.26619 1.9603 4.24889L1.77383 4.1156L1.58735 4.24889C1.56319 4.26615 1.53102 4.24273 1.53993 4.21445L1.6091 3.99592L1.4247 3.85973C1.40084 3.84206 1.41312 3.80425 1.44281 3.80399L1.67204 3.80224L1.74455 3.58478C1.75386 3.55662 1.79372 3.55662 1.80311 3.58478Z"
        fill="#F5F5F5"
      />
      <Path
        d="M1.80311 4.72233L1.87562 4.93979L2.10484 4.94153C2.13453 4.94179 2.14681 4.97965 2.12295 4.99727L1.93856 5.13346L2.00773 5.352C2.01671 5.38028 1.98446 5.40373 1.9603 5.38644L1.77383 5.25314L1.58735 5.38644C1.56319 5.40369 1.53102 5.38028 1.53993 5.352L1.6091 5.13346L1.4247 4.99727C1.40084 4.97961 1.41312 4.94179 1.44281 4.94153L1.67204 4.93979L1.74455 4.72233C1.75386 4.6942 1.79372 4.6942 1.80311 4.72233Z"
        fill="#F5F5F5"
      />
      <Path
        d="M2.68641 0.751898L2.75892 0.969359L2.98814 0.971103C3.01783 0.971363 3.03011 1.00921 3.00625 1.02684L2.82186 1.16303L2.89103 1.38157C2.90001 1.40985 2.86776 1.4333 2.8436 1.41601L2.65713 1.28271L2.47065 1.41601C2.4465 1.43326 2.41432 1.40985 2.42323 1.38157L2.4924 1.16303L2.308 1.02684C2.28414 1.00918 2.29643 0.971363 2.32611 0.971103L2.55534 0.969359L2.62785 0.751898C2.6372 0.723732 2.67702 0.723732 2.68641 0.751898Z"
        fill="#F5F5F5"
      />
      <Path
        d="M2.68641 1.88944L2.75892 2.1069L2.98814 2.10865C3.01783 2.10891 3.03011 2.14676 3.00625 2.16439L2.82186 2.30058L2.89103 2.51911C2.90001 2.54739 2.86776 2.57084 2.8436 2.55355L2.65713 2.42026L2.47065 2.55355C2.4465 2.57081 2.41432 2.54739 2.42323 2.51911L2.4924 2.30058L2.308 2.16439C2.28414 2.14672 2.29643 2.10891 2.32611 2.10865L2.55534 2.1069L2.62785 1.88944C2.6372 1.86131 2.67702 1.86131 2.68641 1.88944Z"
        fill="#F5F5F5"
      />
      <Path
        d="M2.68641 3.02702L2.75892 3.24448L2.98814 3.24622C3.01783 3.24648 3.03011 3.28433 3.00625 3.30196L2.82186 3.43815L2.89103 3.65669C2.90001 3.68497 2.86776 3.70842 2.8436 3.69113L2.65713 3.55783L2.47065 3.69113C2.4465 3.70838 2.41432 3.68497 2.42323 3.65669L2.4924 3.43815L2.308 3.30196C2.28414 3.2843 2.29643 3.24648 2.32611 3.24622L2.55534 3.24448L2.62785 3.02702C2.6372 2.99889 2.67702 2.99889 2.68641 3.02702Z"
        fill="#F5F5F5"
      />
      <Path
        d="M2.68641 4.16462L2.75892 4.38208L2.98814 4.38382C3.01783 4.38408 3.03011 4.42193 3.00625 4.43956L2.82186 4.57575L2.89103 4.79429C2.90001 4.82257 2.86776 4.84602 2.8436 4.82873L2.65713 4.69543L2.47065 4.82873C2.4465 4.84598 2.41432 4.82257 2.42323 4.79429L2.4924 4.57575L2.308 4.43956C2.28414 4.4219 2.29643 4.38408 2.32611 4.38382L2.55534 4.38208L2.62785 4.16462C2.6372 4.13645 2.67702 4.13645 2.68641 4.16462Z"
        fill="#F5F5F5"
      />
      <Path
        d="M2.68641 5.3021L2.75892 5.51956L2.98814 5.5213C3.01783 5.52156 3.03011 5.55941 3.00625 5.57704L2.82186 5.71323L2.89103 5.93177C2.90001 5.96005 2.86776 5.9835 2.8436 5.96621L2.65713 5.83291L2.47065 5.96621C2.4465 5.98346 2.41432 5.96005 2.42323 5.93177L2.4924 5.71323L2.308 5.57704C2.28414 5.55938 2.29643 5.52156 2.32611 5.5213L2.55534 5.51956L2.62785 5.3021C2.6372 5.27389 2.67702 5.27389 2.68641 5.3021Z"
        fill="#F5F5F5"
      />
      <Path
        d="M3.56922 1.30961L3.64173 1.52707L3.87096 1.52881C3.90064 1.52907 3.91293 1.56693 3.88906 1.58455L3.70467 1.72074L3.77384 1.93928C3.78282 1.96756 3.75057 1.99101 3.72641 1.97372L3.53994 1.84042L3.35347 1.97372C3.32931 1.99097 3.29713 1.96756 3.30604 1.93928L3.37521 1.72074L3.19082 1.58455C3.16695 1.56689 3.17924 1.52907 3.20893 1.52881L3.43815 1.52707L3.51066 1.30961C3.51998 1.28148 3.55983 1.28148 3.56922 1.30961Z"
        fill="#F5F5F5"
      />
      <Path
        d="M3.56922 2.44721L3.64173 2.66467L3.87096 2.66642C3.90064 2.66668 3.91293 2.70453 3.88906 2.72215L3.70467 2.85834L3.77384 3.07688C3.78282 3.10516 3.75057 3.12861 3.72641 3.11132L3.53994 2.97802L3.35347 3.11132C3.32931 3.12857 3.29713 3.1052 3.30604 3.07688L3.37521 2.85834L3.19082 2.72215C3.16695 2.70449 3.17924 2.66668 3.20893 2.66642L3.43815 2.66467L3.51066 2.44721C3.51998 2.41904 3.55983 2.41904 3.56922 2.44721Z"
        fill="#F5F5F5"
      />
      <Path
        d="M3.56922 3.58478L3.64173 3.80224L3.87096 3.80399C3.90064 3.80425 3.91293 3.8421 3.88906 3.85973L3.70467 3.99592L3.77384 4.21445C3.78282 4.24273 3.75057 4.26619 3.72641 4.24889L3.53994 4.1156L3.35347 4.24889C3.32931 4.26615 3.29713 4.24273 3.30604 4.21445L3.37521 3.99592L3.19082 3.85973C3.16695 3.84206 3.17924 3.80425 3.20893 3.80399L3.43815 3.80224L3.51066 3.58478C3.51998 3.55662 3.55983 3.55662 3.56922 3.58478Z"
        fill="#F5F5F5"
      />
      <Path
        d="M3.56922 4.72233L3.64173 4.93979L3.87096 4.94153C3.90064 4.94179 3.91293 4.97965 3.88906 4.99727L3.70467 5.13346L3.77384 5.352C3.78282 5.38028 3.75057 5.40373 3.72641 5.38644L3.53994 5.25314L3.35347 5.38644C3.32931 5.40369 3.29713 5.38028 3.30604 5.352L3.37521 5.13346L3.19082 4.99727C3.16695 4.97961 3.17924 4.94179 3.20893 4.94153L3.43815 4.93979L3.51066 4.72233C3.51998 4.6942 3.55983 4.6942 3.56922 4.72233Z"
        fill="#F5F5F5"
      />
      <Path
        d="M4.45256 0.751898L4.52507 0.969359L4.75429 0.971103C4.78398 0.971363 4.79626 1.00921 4.7724 1.02684L4.58801 1.16303L4.65718 1.38157C4.66616 1.40985 4.63391 1.4333 4.60975 1.41601L4.42324 1.28271L4.23677 1.41601C4.21261 1.43326 4.18044 1.40985 4.18934 1.38157L4.25851 1.16303L4.07412 1.02684C4.05026 1.00918 4.06254 0.971363 4.09223 0.971103L4.32145 0.969359L4.39396 0.751898C4.40335 0.723732 4.44317 0.723732 4.45256 0.751898Z"
        fill="#F5F5F5"
      />
      <Path
        d="M4.45256 1.88944L4.52507 2.1069L4.75429 2.10865C4.78398 2.10891 4.79626 2.14676 4.7724 2.16439L4.58801 2.30058L4.65718 2.51911C4.66616 2.54739 4.63391 2.57084 4.60975 2.55355L4.42324 2.42026L4.23677 2.55355C4.21261 2.57081 4.18044 2.54739 4.18934 2.51911L4.25851 2.30058L4.07412 2.16439C4.05026 2.14672 4.06254 2.10891 4.09223 2.10865L4.32145 2.1069L4.39396 1.88944C4.40335 1.86131 4.44317 1.86131 4.45256 1.88944Z"
        fill="#F5F5F5"
      />
      <Path
        d="M4.45256 3.02702L4.52507 3.24448L4.75429 3.24622C4.78398 3.24648 4.79626 3.28433 4.7724 3.30196L4.58801 3.43815L4.65718 3.65669C4.66616 3.68497 4.63391 3.70842 4.60975 3.69113L4.42324 3.55783L4.23677 3.69113C4.21261 3.70838 4.18044 3.68497 4.18934 3.65669L4.25851 3.43815L4.07412 3.30196C4.05026 3.2843 4.06254 3.24648 4.09223 3.24622L4.32145 3.24448L4.39396 3.02702C4.40335 2.99889 4.44317 2.99889 4.45256 3.02702Z"
        fill="#F5F5F5"
      />
      <Path
        d="M4.45256 4.16462L4.52507 4.38208L4.75429 4.38382C4.78398 4.38408 4.79626 4.42193 4.7724 4.43956L4.58801 4.57575L4.65718 4.79429C4.66616 4.82257 4.63391 4.84602 4.60975 4.82873L4.42324 4.69543L4.23677 4.82873C4.21261 4.84598 4.18044 4.82257 4.18934 4.79429L4.25851 4.57575L4.07412 4.43956C4.05026 4.4219 4.06254 4.38408 4.09223 4.38382L4.32145 4.38208L4.39396 4.16462C4.40335 4.13645 4.44317 4.13645 4.45256 4.16462Z"
        fill="#F5F5F5"
      />
      <Path
        d="M4.45256 5.3021L4.52507 5.51956L4.75429 5.5213C4.78398 5.52156 4.79626 5.55941 4.7724 5.57704L4.58801 5.71323L4.65718 5.93177C4.66616 5.96005 4.63391 5.9835 4.60975 5.96621L4.42324 5.83291L4.23677 5.96621C4.21261 5.98346 4.18044 5.96005 4.18934 5.93177L4.25851 5.71323L4.07412 5.57704C4.05026 5.55938 4.06254 5.52156 4.09223 5.5213L4.32145 5.51956L4.39396 5.3021C4.40335 5.27389 4.44317 5.27389 4.45256 5.3021Z"
        fill="#F5F5F5"
      />
      <Path
        d="M5.33533 1.30961L5.40784 1.52707L5.63707 1.52881C5.66676 1.52907 5.67904 1.56693 5.65518 1.58455L5.47078 1.72074L5.53995 1.93928C5.54893 1.96756 5.51669 1.99101 5.49253 1.97372L5.30605 1.84042L5.11958 1.97372C5.09542 1.99097 5.06325 1.96756 5.07215 1.93928L5.14133 1.72074L4.95693 1.58455C4.93307 1.56689 4.94535 1.52907 4.97504 1.52881L5.20426 1.52707L5.27677 1.30961C5.28616 1.28148 5.32594 1.28148 5.33533 1.30961Z"
        fill="#F5F5F5"
      />
      <Path
        d="M5.33533 2.44721L5.40784 2.66467L5.63707 2.66642C5.66676 2.66668 5.67904 2.70453 5.65518 2.72215L5.47078 2.85834L5.53995 3.07688C5.54893 3.10516 5.51669 3.12861 5.49253 3.11132L5.30605 2.97802L5.11958 3.11132C5.09542 3.12857 5.06325 3.1052 5.07215 3.07688L5.14133 2.85834L4.95693 2.72215C4.93307 2.70449 4.94535 2.66668 4.97504 2.66642L5.20426 2.66467L5.27677 2.44721C5.28616 2.41904 5.32594 2.41904 5.33533 2.44721Z"
        fill="#F5F5F5"
      />
      <Path
        d="M5.33533 3.58478L5.40784 3.80224L5.63707 3.80399C5.66676 3.80425 5.67904 3.8421 5.65518 3.85973L5.47078 3.99592L5.53995 4.21445C5.54893 4.24273 5.51669 4.26619 5.49253 4.24889L5.30605 4.1156L5.11958 4.24889C5.09542 4.26615 5.06325 4.24273 5.07215 4.21445L5.14133 3.99592L4.95693 3.85973C4.93307 3.84206 4.94535 3.80425 4.97504 3.80399L5.20426 3.80224L5.27677 3.58478C5.28616 3.55662 5.32594 3.55662 5.33533 3.58478Z"
        fill="#F5F5F5"
      />
      <Path
        d="M5.33533 4.72233L5.40784 4.93979L5.63707 4.94153C5.66676 4.94179 5.67904 4.97965 5.65518 4.99727L5.47078 5.13346L5.53995 5.352C5.54893 5.38028 5.51669 5.40373 5.49253 5.38644L5.30605 5.25314L5.11958 5.38644C5.09542 5.40369 5.06325 5.38028 5.07215 5.352L5.14133 5.13346L4.95693 4.99727C4.93307 4.97961 4.94535 4.94179 4.97504 4.94153L5.20426 4.93979L5.27677 4.72233C5.28616 4.6942 5.32594 4.6942 5.33533 4.72233Z"
        fill="#F5F5F5"
      />
      <Path
        d="M6.21815 0.751898L6.29066 0.969359L6.51988 0.971103C6.54957 0.971363 6.56185 1.00921 6.53799 1.02684L6.35359 1.16303L6.42277 1.38157C6.43175 1.40985 6.3995 1.4333 6.37534 1.41601L6.18887 1.28271L6.00239 1.41601C5.97823 1.43326 5.94606 1.40985 5.95497 1.38157L6.02414 1.16303L5.83974 1.02684C5.81588 1.00918 5.82816 0.971363 5.85785 0.971103L6.08708 0.969359L6.15959 0.751898C6.1689 0.723732 6.20876 0.723732 6.21815 0.751898Z"
        fill="#F5F5F5"
      />
      <Path
        d="M6.21815 1.88944L6.29066 2.1069L6.51988 2.10865C6.54957 2.10891 6.56185 2.14676 6.53799 2.16439L6.35359 2.30058L6.42277 2.51911C6.43175 2.54739 6.3995 2.57084 6.37534 2.55355L6.18887 2.42026L6.00239 2.55355C5.97823 2.57081 5.94606 2.54739 5.95497 2.51911L6.02414 2.30058L5.83974 2.16439C5.81588 2.14672 5.82816 2.10891 5.85785 2.10865L6.08708 2.1069L6.15959 1.88944C6.1689 1.86131 6.20876 1.86131 6.21815 1.88944Z"
        fill="#F5F5F5"
      />
      <Path
        d="M6.21815 3.02702L6.29066 3.24448L6.51988 3.24622C6.54957 3.24648 6.56185 3.28433 6.53799 3.30196L6.35359 3.43815L6.42277 3.65669C6.43175 3.68497 6.3995 3.70842 6.37534 3.69113L6.18887 3.55783L6.00239 3.69113C5.97823 3.70838 5.94606 3.68497 5.95497 3.65669L6.02414 3.43815L5.83974 3.30196C5.81588 3.2843 5.82816 3.24648 5.85785 3.24622L6.08708 3.24448L6.15959 3.02702C6.1689 2.99889 6.20876 2.99889 6.21815 3.02702Z"
        fill="#F5F5F5"
      />
      <Path
        d="M6.21815 4.16462L6.29066 4.38208L6.51988 4.38382C6.54957 4.38408 6.56185 4.42193 6.53799 4.43956L6.35359 4.57575L6.42277 4.79429C6.43175 4.82257 6.3995 4.84602 6.37534 4.82873L6.18887 4.69543L6.00239 4.82873C5.97823 4.84598 5.94606 4.82257 5.95497 4.79429L6.02414 4.57575L5.83974 4.43956C5.81588 4.4219 5.82816 4.38408 5.85785 4.38382L6.08708 4.38208L6.15959 4.16462C6.1689 4.13645 6.20876 4.13645 6.21815 4.16462Z"
        fill="#F5F5F5"
      />
      <Path
        d="M6.21815 5.3021L6.29066 5.51956L6.51988 5.5213C6.54957 5.52156 6.56185 5.55941 6.53799 5.57704L6.35359 5.71323L6.42277 5.93177C6.43175 5.96005 6.3995 5.9835 6.37534 5.96621L6.18887 5.83291L6.00239 5.96621C5.97823 5.98346 5.94606 5.96005 5.95497 5.93177L6.02414 5.71323L5.83974 5.57704C5.81588 5.55938 5.82816 5.52156 5.85785 5.5213L6.08708 5.51956L6.15959 5.3021C6.1689 5.27389 6.20876 5.27389 6.21815 5.3021Z"
        fill="#F5F5F5"
      />
      <Path
        d="M7.10145 1.30961L7.17396 1.52707L7.40318 1.52881C7.43287 1.52907 7.44515 1.56693 7.42129 1.58455L7.2369 1.72074L7.30607 1.93928C7.31505 1.96756 7.2828 1.99101 7.25864 1.97372L7.07217 1.84042L6.88569 1.97372C6.86153 1.99097 6.82936 1.96756 6.83827 1.93928L6.90744 1.72074L6.72304 1.58455C6.69918 1.56689 6.71146 1.52907 6.74115 1.52881L6.97038 1.52707L7.04289 1.30961C7.05224 1.28148 7.09209 1.28148 7.10145 1.30961Z"
        fill="#F5F5F5"
      />
      <Path
        d="M7.10145 2.44721L7.17396 2.66467L7.40318 2.66642C7.43287 2.66668 7.44515 2.70453 7.42129 2.72215L7.2369 2.85834L7.30607 3.07688C7.31505 3.10516 7.2828 3.12861 7.25864 3.11132L7.07217 2.97802L6.88569 3.11132C6.86153 3.12857 6.82936 3.1052 6.83827 3.07688L6.90744 2.85834L6.72304 2.72215C6.69918 2.70449 6.71146 2.66668 6.74115 2.66642L6.97038 2.66467L7.04289 2.44721C7.05224 2.41904 7.09209 2.41904 7.10145 2.44721Z"
        fill="#F5F5F5"
      />
      <Path
        d="M7.10145 3.58478L7.17396 3.80224L7.40318 3.80399C7.43287 3.80425 7.44515 3.8421 7.42129 3.85973L7.2369 3.99592L7.30607 4.21445C7.31505 4.24273 7.2828 4.26619 7.25864 4.24889L7.07217 4.1156L6.88569 4.24889C6.86153 4.26615 6.82936 4.24273 6.83827 4.21445L6.90744 3.99592L6.72304 3.85973C6.69918 3.84206 6.71146 3.80425 6.74115 3.80399L6.97038 3.80224L7.04289 3.58478C7.05224 3.55662 7.09209 3.55662 7.10145 3.58478Z"
        fill="#F5F5F5"
      />
      <Path
        d="M7.10145 4.72233L7.17396 4.93979L7.40318 4.94153C7.43287 4.94179 7.44515 4.97965 7.42129 4.99727L7.2369 5.13346L7.30607 5.352C7.31505 5.38028 7.2828 5.40373 7.25864 5.38644L7.07217 5.25314L6.88569 5.38644C6.86153 5.40369 6.82936 5.38028 6.83827 5.352L6.90744 5.13346L6.72304 4.99727C6.69918 4.97961 6.71146 4.94179 6.74115 4.94153L6.97038 4.93979L7.04289 4.72233C7.05224 4.6942 7.09209 4.6942 7.10145 4.72233Z"
        fill="#F5F5F5"
      />
      <Path
        d="M7.98426 0.751898L8.05677 0.969359L8.28599 0.971103C8.31568 0.971363 8.32797 1.00921 8.3041 1.02684L8.11971 1.16303L8.18888 1.38157C8.19786 1.40985 8.16561 1.4333 8.14145 1.41601L7.95498 1.28271L7.76851 1.41601C7.74435 1.43326 7.71217 1.40985 7.72108 1.38157L7.79025 1.16303L7.60586 1.02684C7.58199 1.00918 7.59428 0.971363 7.62396 0.971103L7.85319 0.969359L7.9257 0.751898C7.93505 0.723732 7.97483 0.723732 7.98426 0.751898Z"
        fill="#F5F5F5"
      />
      <Path
        d="M7.98426 1.88944L8.05677 2.1069L8.28599 2.10865C8.31568 2.10891 8.32797 2.14676 8.3041 2.16439L8.11971 2.30058L8.18888 2.51911C8.19786 2.54739 8.16561 2.57084 8.14145 2.55355L7.95498 2.42026L7.76851 2.55355C7.74435 2.57081 7.71217 2.54739 7.72108 2.51911L7.79025 2.30058L7.60586 2.16439C7.58199 2.14672 7.59428 2.10891 7.62396 2.10865L7.85319 2.1069L7.9257 1.88944C7.93505 1.86131 7.97483 1.86131 7.98426 1.88944Z"
        fill="#F5F5F5"
      />
      <Path
        d="M7.98426 3.02702L8.05677 3.24448L8.28599 3.24622C8.31568 3.24648 8.32797 3.28433 8.3041 3.30196L8.11971 3.43815L8.18888 3.65669C8.19786 3.68497 8.16561 3.70842 8.14145 3.69113L7.95498 3.55783L7.76851 3.69113C7.74435 3.70838 7.71217 3.68497 7.72108 3.65669L7.79025 3.43815L7.60586 3.30196C7.58199 3.2843 7.59428 3.24648 7.62396 3.24622L7.85319 3.24448L7.9257 3.02702C7.93505 2.99889 7.97483 2.99889 7.98426 3.02702Z"
        fill="#F5F5F5"
      />
      <Path
        d="M7.98426 4.16462L8.05677 4.38208L8.28599 4.38382C8.31568 4.38408 8.32797 4.42193 8.3041 4.43956L8.11971 4.57575L8.18888 4.79429C8.19786 4.82257 8.16561 4.84602 8.14145 4.82873L7.95498 4.69543L7.76851 4.82873C7.74435 4.84598 7.71217 4.82257 7.72108 4.79429L7.79025 4.57575L7.60586 4.43956C7.58199 4.4219 7.59428 4.38408 7.62396 4.38382L7.85319 4.38208L7.9257 4.16462C7.93505 4.13645 7.97483 4.13645 7.98426 4.16462Z"
        fill="#F5F5F5"
      />
      <Path
        d="M7.98426 5.3021L8.05677 5.51956L8.28599 5.5213C8.31568 5.52156 8.32797 5.55941 8.3041 5.57704L8.11971 5.71323L8.18888 5.93177C8.19786 5.96005 8.16561 5.9835 8.14145 5.96621L7.95498 5.83291L7.76851 5.96621C7.74435 5.98346 7.71217 5.96005 7.72108 5.93177L7.79025 5.71323L7.60586 5.57704C7.58199 5.55938 7.59428 5.52156 7.62396 5.5213L7.85319 5.51956L7.9257 5.3021C7.93505 5.27389 7.97483 5.27389 7.98426 5.3021Z"
        fill="#F5F5F5"
      />
    </Svg>
  );
};

export default USFlagIcon;
