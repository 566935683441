import React, { useContext, useState, useEffect } from "react";
import { Alert, Platform, View } from "react-native";
import { StackNavigationProp } from "@react-navigation/stack";

import authServices from "../../services/apis/auth-service";
import BaseButton from "../Base/BaseButton/BaseButton";
import { Alert as AlertType } from "../../types";
import routes from "../../routes";
import BaseAuthForm from "./BaseAuthForm";
import loginFormSchema from "../../forms/login.json";
import BaseForm from "../../components/Base/SalesFunnel/BaseFormSalesFunnel/BaseFormSalesFunnel";
import BaseText from "../Base/BaseText/BaseText";
import NewOrderContext from "../../contexts/new-order.ctx";
import AuthContainer from "./AuthContainer";
import AuthContext, { AuthStates } from "../../contexts/auth.ctx";
import useIsMobile from "../../utils/useIsMobile";
import colors from "../../configs/colors";
import { showAlert } from "react-native-customisable-alert";
import Ionicons from "@expo/vector-icons/Ionicons";
interface SignInProps {
  navigation: StackNavigationProp<any>;
}
const SignIn: React.FC<SignInProps> = ({ navigation }) => {
  const [isLoading, setIsLoading] = useState(false);
  const newOrderCtx = useContext(NewOrderContext);
  const authCtx = useContext(AuthContext);
  const isMobile = useIsMobile();

  const onSubmit = (data: Record<string, any>) => {
    if (!isLoading) {
      setIsLoading(true);
      authServices
        .signInWithEmailAndPassword(data.email, data.password)
        .then((userToken) => {
          setIsLoading(false);
          if (userToken.claims.admin) {
            navigation.navigate(routes.drawerAdmin.screenName, {
              screen: routes.drawerAdmin.screens.adminOrders.screenName,
            });
          } else {
            navigation.navigate(routes.drawer.screenName, {
              screen: routes.drawer.screens.dashboard.screenName,
            });
          }
        })
        .catch((alertMessage: AlertType) => {
          showAlert({
            title: alertMessage.header,
            message: alertMessage.detail,
            alertType: "error",
            customIcon: (
              <Ionicons name="close-circle-outline" size={50} color="#FF5B5B" />
            ),
            onPress: () => console.log(""),
          });
          console.log("error :", alertMessage);

          setIsLoading(false);
        });
    }
  };

  // useEffect(() => {
  //   console.log(authCtx.authState, AuthStates.AUTH);
  //   if (authCtx.authState === AuthStates.AUTH) {
  //     if (authCtx.isAdmin) {
  //       navigation.navigate(routes.drawerAdmin.screenName);
  //     } else {
  //       console.log("navigation");
  //       navigation.navigate(routes.drawer.screenName, {
  //         screen: routes.drawer.screens.dashboard.screenName,
  //       });
  //     }
  //   }
  // }, [authCtx.authState, navigation]);
  return (
    <AuthContainer
      navigation={navigation}
      hideOrderFlow={!newOrderCtx.isInOrderFlow}
    >
      <BaseAuthForm bgColor={!newOrderCtx.isInOrderFlow ? "#000" : "#fff"}>
        <View style={{ alignItems: "center", marginVertical: "5%" }}>
          <BaseText
            bold
            text="IDENTIFIEZ-VOUS"
            style={{
              fontSize: isMobile ? 22 : 30,
              color: colors.white,
            }}
          />
          <BaseText
            text="Déjà client ?"
            style={{
              fontSize: isMobile ? 18 : 22,
              color: colors.white,
              fontWeight: "400",
            }}
          />
        </View>
        <BaseForm
          schema={loginFormSchema}
          onSubmit={onSubmit}
          labelStyle={
            isMobile
              ? { color: colors.white, fontSize: 18 }
              : !newOrderCtx.isInOrderFlow
              ? { color: colors.white, fontSize: 18 }
              : {}
          }
          buttonTitle={
            newOrderCtx.isInOrderFlow
              ? "Valider mon nouvel investissement"
              : "Connexion"
          }
          buttonBgColor={colors.white}
          buttonTitleColor={colors.black}
          isLoading={isLoading}
          style={{
            flex: 1,
            width: "100%",
            display: "flex",
          }}
          inputBgColor={!newOrderCtx.isInOrderFlow ? "#C4C4C40D" : null}
          inputBorderColor={!newOrderCtx.isInOrderFlow ? "#E0E0E0" : null}
          inputFontColor={!newOrderCtx.isInOrderFlow ? "#E0E0E0" : null}
          inputBorderRadius={!newOrderCtx.isInOrderFlow ? 10 : null}
          inputBorderWidth={!newOrderCtx.isInOrderFlow ? 1 : null}
          inputTextStyle={isMobile ? { fontSize: 14 } : undefined}
        />

        {/* <BaseButton
          title={i18n.t("forgotPassword")}
          onPress={() => navigation.navigate(routes.resetPassword.screenName)}
        /> */}
        <View style={{ alignItems: "center" }}>
          <BaseButton
            title={"Mot de passe oublié ?"}
            notFullWidth
            smallPadding
            noGradient
            bgColor={
              isMobile || !newOrderCtx.isInOrderFlow ? colors.black : "#fff"
            }
            style={{
              alignSelf: "center",
              paddingHorizontal: "10%",
            }}
            textProps={{
              bold: false,

              style: {
                color:
                  isMobile || !newOrderCtx.isInOrderFlow
                    ? colors.white
                    : "#000",
                fontFamily: "PoppinsRegular",
                fontSize: isMobile ? 20 : 18,
              },
            }}
            onPress={() => {
              navigation.navigate(routes.sendResetPassword.screenName);
            }}
          />
        </View>

        {Platform.OS == "web" ? (
          <View style={{ marginVertical: "5%" }}>
            <View style={{ alignItems: "center", marginVertical: "4%" }}>
              <BaseText
                text="Nouveau client ?"
                style={{
                  fontSize: isMobile ? 20 : 22,
                  color:
                    isMobile || !newOrderCtx.isInOrderFlow
                      ? colors.white
                      : "#000",
                  fontWeight: "400",
                }}
              />
            </View>
            <View style={{ alignItems: "center" }}>
              <BaseButton
                title={"Créer un compte"}
                notFullWidth
                smallPadding
                noGradient
                bgColor={
                  isMobile || !newOrderCtx.isInOrderFlow ? colors.black : "#fff"
                }
                borderColor={
                  isMobile || !newOrderCtx.isInOrderFlow ? colors.white : "#000"
                }
                style={{
                  alignSelf: "center",
                  paddingHorizontal: "10%",
                }}
                textProps={{
                  bold: false,

                  style: {
                    color:
                      isMobile || !newOrderCtx.isInOrderFlow
                        ? colors.white
                        : "#000",
                    fontFamily: "PoppinsRegular",
                    fontSize: isMobile ? 20 : 18,
                  },
                }}
                onPress={() => {
                  if (!newOrderCtx.isInOrderFlow && Platform.OS == "web") {
                    navigation.navigate(routes.salesFunnel.screenName, {
                      screen:
                        routes.salesFunnel.screens.salesInvestingAmount
                          .screenName,
                    });
                  } else {
                    navigation.navigate(routes.accounType.screenName);
                  }
                }}
              />
              <BaseButton
                title={"Créer un compte sans commander"}
                notFullWidth
                smallPadding
                noGradient
                bgColor={
                  isMobile || !newOrderCtx.isInOrderFlow ? colors.black : "#fff"
                }
                borderColor={
                  isMobile || !newOrderCtx.isInOrderFlow ? colors.white : "#000"
                }
                style={{
                  alignSelf: "center",
                  paddingHorizontal: "10%",
                }}
                textProps={{
                  bold: false,

                  style: {
                    color:
                      isMobile || !newOrderCtx.isInOrderFlow
                        ? colors.white
                        : "#000",
                    fontFamily: "PoppinsRegular",
                    fontSize: isMobile ? 20 : 18,
                  },
                }}
                onPress={() => {
                  navigation.navigate(routes.signupNoSF.screenName);
                }}
              />
            </View>
          </View>
        ) : null}
      </BaseAuthForm>
    </AuthContainer>
  );
};

export default SignIn;
