import React from "react";
import {
  TextInputProps,
  TextInput,
  View,
  StyleSheet,
  Image,
  StyleProp,
  TextStyle,
  ViewStyle,
} from "react-native";
import colors from "../../../configs/colors";
import BaseText from "../BaseText/BaseText";
import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
interface BaseInputProps extends TextInputProps {
  label?: string;
  error?: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
  helpText?: string;
  logo?: any;
  leftLogo?: React.ReactNode;
  rightLogo?: React.ReactNode;
  inputStyle?: StyleProp<TextStyle>;
  inputContentStyle?: StyleProp<ViewStyle>;
}
const BaseInput: React.FC<BaseInputProps> = (props: BaseInputProps) => {
  return (
    <View style={styles.inputContainer}>
      {props.label ? (
        <BaseText style={styles.labelText} text={props.label}></BaseText>
      ) : null}
      <View style={[styles.inputContent, props.inputContentStyle]}>
        {props.logo ? (
          <Image resizeMode="contain" source={props.logo} style={styles.logo} />
        ) : null}
        {props.leftLogo ? props.leftLogo : null}
        <TextInput
          {...props}
          style={
            props.error
              ? [
                  styles.textInput,
                  styles.errorTextInput,
                  { width: props.rightLogo ? "92%" : "100%" },
                  props.inputStyle,
                ]
              : [
                  styles.textInput,
                  { width: props.rightLogo ? "92%" : "100%" },
                  props.inputStyle,
                ]
          }
          placeholderTextColor={colors.grayDark}
          value={props.value}
          selectionColor={colors.grayDark}
          cursorColor={colors.grayDark}
          underlineColorAndroid={"transparent"}
          onChange={props.onChange}
        ></TextInput>
        {props.rightLogo ? props.rightLogo : null}
      </View>
      <View style={styles.errorContainer}>
        {props.error ? (
          <BaseText
            style={styles.errorText}
            text={props.error.toString()}
          ></BaseText>
        ) : null}

        {props.helpText ? (
          <BaseText style={styles.helperText} text={props.helpText} />
        ) : null}
      </View>
    </View>
  );
};

export default BaseInput;

const styles = StyleSheet.create({
  labelText: {
    fontSize: 16,
    color: colors.white,
    paddingBottom: 6,
  },
  inputContainer: {
    marginVertical: 10,
    color: colors.white,
  },
  inputContent: {
    borderRadius: 8,
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: colors.dark,
    paddingHorizontal: 16,
  },
  textInput: {
    paddingVertical: 10,
    height: 50,
    fontSize: 16,
    color: colors.white,
    textDecorationColor: colors.white,
    outlineWidth: 0,
    outline: "none",
  },
  errorTextInput: {
    borderWidth: 1,
    borderColor: colors.red,
  },
  errorText: {
    color: colors.red,
    fontSize: 12,
  },
  errorContainer: {
    flexWrap: "wrap",
    flexDirection: "row",
  },
  helperText: {
    fontSize: 12,
  },
  logo: {
    height: 40,
    width: 40,
    marginHorizontal: 8,
  },
});
