import React, { useContext, useEffect } from "react";
import AuthContext, { AuthStates } from "../../contexts/auth.ctx";

import { createStackNavigator } from "@react-navigation/stack";

import SignIn from "../../pages/SignIn";
import SignUp from "../../pages/SignUp";
import SignUpNoSF from "../Auth/SignupNoSF";
import SendResetPasswordMail from "../../pages/SendResetPassMail";
import ResetPassword from "../Auth/ResetPassword";
import FullScreenLoader from "../Loader/FullScreenLoader";

import useIsMobile from "../../utils/useIsMobile";
import routes from "../../routes";
import OrderContext from "../../contexts/order.ctx";

import MachinesContext from "../../contexts/machines.ctx";
import AccountType from "../Auth/AccountType";

import { Platform } from "react-native";
import HostingCountriesContext from "../../contexts/hosting-country.ctx";
import PromoCodesContext from "../../contexts/promo-code.ctx";
import UsersContext from "../../contexts/users.ctx";
const LazyDrawerAdminNav = React.lazy(() => import("./DrawerAdminNav"));
const LazyDrawer = React.lazy(() => import("./DrawerNav"));
const LazySalesFunnel = React.lazy(() => import("./SalesFunnel"));

// import LazyDrawer from "./DrawerNav";
// import LazySalesFunnel from "./SalesFunnel";
const Stack = createStackNavigator();

export default function StartPage() {
  const authCtx = useContext(AuthContext);
  const isMobile = useIsMobile();
  const orderCtx = useContext(OrderContext);
  const machinesCtx = useContext(MachinesContext);
  const promoCodeCtx = useContext(PromoCodesContext);
  const hostingCountryCtx = useContext(HostingCountriesContext);
  const usersCtx = useContext(UsersContext);
  useEffect(() => {
    if (authCtx.userId) {
      authCtx.initUserData();
      orderCtx.initOrders();
      if (authCtx.isAdmin) {
        usersCtx.initUsersData();
      }
    }
  }, [authCtx.userId]);

  useEffect(() => {
    machinesCtx.initMachinesData();
    hostingCountryCtx.initHostingCountryData();
    promoCodeCtx.initPromoCodesData();
  }, []);
  if (authCtx.authState == AuthStates.NOT_INIT) {
    return <FullScreenLoader />;
  }

  return (
    <Stack.Navigator
      detachInactiveScreens
      screenOptions={{
        headerShown: false,
        detachPreviousScreen: true,
      }}
      initialRouteName={
        authCtx.authState == AuthStates.AUTH
          ? authCtx.isAdmin
            ? routes.drawerAdmin.screenName
            : routes.drawer.screenName
          : routes.signin.screenName
      }
    >
      <Stack.Screen name={routes.drawer.screenName}>
        {(props) => (
          <React.Suspense fallback={<FullScreenLoader />}>
            <LazyDrawer {...props} />
          </React.Suspense>
        )}
      </Stack.Screen>
      <Stack.Screen name={routes.drawerAdmin.screenName}>
        {(props) => (
          <React.Suspense fallback={<FullScreenLoader />}>
            <LazyDrawerAdminNav {...props} />
          </React.Suspense>
        )}
      </Stack.Screen>

      <Stack.Screen name={routes.signin.screenName} component={SignIn} />
      <Stack.Screen
        name={routes.signupNoSF.screenName}
        component={SignUpNoSF}
      />
      <Stack.Screen
        name={routes.accounType.screenName}
        component={AccountType}
      />
      <Stack.Screen name={routes.signup.screenName} component={SignUp} />

      <Stack.Screen
        name={routes.sendResetPassword.screenName}
        component={SendResetPasswordMail}
      />
      <Stack.Screen
        name={routes.resetPassword.screenName}
        component={ResetPassword}
      />
      {Platform.OS == "web" ? (
        <Stack.Screen name={routes.salesFunnel.screenName}>
          {(props) => (
            <React.Suspense fallback={<FullScreenLoader />}>
              <LazySalesFunnel {...props} />
            </React.Suspense>
          )}
        </Stack.Screen>
      ) : null}
    </Stack.Navigator>
  );
}
