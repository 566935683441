// React
import React, {
  useEffect,
  useState,
  PropsWithChildren,
  useContext,
} from "react";
import Ionicons from "@expo/vector-icons/Ionicons";
import { Platform, Alert } from "react-native";
import AbuFlagIcon from "../svg/AbuFlagIcon";
import USFlagIcon from "../svg/USFlagIcon";
import EmiratFlagIcon from "../svg/EmiratFlagIcon";
import DubaiFlagIcon from "../svg/DubaiFlagIcon";
import { Machine, PromoCode } from "../types";
import AsyncStorage from "@react-native-async-storage/async-storage";
import MachinesContext from "./machines.ctx";
import paymentServices from "../services/apis/payment-service";
import orderServices from "../services/apis/order-service";
import { showAlert } from "react-native-customisable-alert";
export enum AmountToInvest {
  "LESS_THAN_5000",
  "BETWEEN_5000_AND_50000",
  "MORE_THAN_50000",
}

export const hostingCountries = [
  {
    name: "Etats-Unis",
    icon: USFlagIcon,
    available: true,
  },
  {
    name: "UAE",
    icon: DubaiFlagIcon,
    available: false,
  },
];

export const corporationCarePrice = 199;
const NewOrderContext = React.createContext({
  amountToInvest: AmountToInvest.BETWEEN_5000_AND_50000,
  selectedMachine: null as null | Machine,
  machineAmountToBuy: 1,
  hostingCountryId: null as null | string,
  total: 0,
  corporationCare: false,
  isLoading: false,
  isInOrderFlow: false,
  promoCode: "",
  promoCodeData: null as PromoCode | null,
  promoCodeError: "",
  CGV: false,
  abandonedCartId: "",
  signUpDolphinUser: async (
    email: string,
    firstname: string,
    lastname: string,
    phone: string,
    country: string,
    dial_code: string,
    code: string
  ): Promise<any> => {},
  promoCodeHandler: (code: string) => {},
  isInOrderFlowHandler: (active: boolean) => {},
  coporationCareHandler: (active: boolean) => {},
  amountToInvestHandler: (amount: AmountToInvest, machine: Machine) => {},
  machineSelectionHandler: (machine: Machine) => {},
  machineAmountToBuyHandler: (amount: number) => {},
  hostingCountryIdHandler: (id: string) => {},
  createOrderVivaWallet: async (
    countryCode?: string,
    requestLang?: string
  ): Promise<any> => {},
  createOrderTransfer: async (): Promise<void> => {},
  createOrderCoinbase: async (): Promise<any> => {},
  resetContext: () => {},
  CGVHandler: (agree: boolean) => {},
  getPromoCode: async () => {},
  createAbandonedCart: async (): Promise<any> => {},
});

export const NewOrderContextProvider: React.FC<PropsWithChildren> = (
  props: any
) => {
  const [amountToInvest, setAmountToInvest] = useState(
    AmountToInvest.BETWEEN_5000_AND_50000
  );
  const [machineAmountToBuy, setMachineAmountToBuy] = useState(1);
  const [total, setTotal] = useState(0);
  const [promoCode, setPromoCode] = useState("");
  const [abandonedCartId, setAbandonedCartId] = useState("");
  const [corporationCare, setCorporationCare] = useState(false);
  const [isInOrderFlow, setIsInOrderFlow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [CGV, setCGV] = useState(false);
  const [hostingCountryId, sethostingCountryId] = useState<string | null>(null);
  const [selectedMachine, setSelectedMachine] = useState<Machine | null>(null);
  const [promoCodeData, setPromoCodeData] = useState<PromoCode | null>(null);
  const [promoCodeError, setPromoCodeError] = useState("");
  const machinesCtx = useContext(MachinesContext);

  const getPromoCode = async () => {
    try {
      if (!isLoading) {
        if (selectedMachine) {
          setTotal(
            corporationCare
              ? selectedMachine.price * machineAmountToBuy +
                  corporationCarePrice
              : selectedMachine.price * machineAmountToBuy
          );
          // AsyncStorage.setItem("machineAmountToBuy", machineAmountToBuy.toString());
        }
        setIsLoading(true);
        const promoCodeData = await orderServices.getPromoCode(promoCode);
        setPromoCodeError("");
        setPromoCodeData(promoCodeData);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      setPromoCodeError("Code invalide");
    }
  };
  const resetContext = () => {
    setTotal(0);
    setMachineAmountToBuy(1);
    setIsInOrderFlow(false);
    setPromoCode("");
    setPromoCodeData(null);
    sethostingCountryId(null);
    setSelectedMachine(null);
    setCorporationCare(false);
    setAmountToInvest(AmountToInvest.BETWEEN_5000_AND_50000);
  };

  const CGVHandler = (agree: boolean) => {
    setCGV(agree);
  };

  useEffect(() => {
    if (promoCodeData) {
      if (promoCodeData.type == "%") {
        setTotal(total - (total * promoCodeData.amount) / 100);
      } else {
        setTotal(total - promoCodeData.amount);
      }
    }
  }, [promoCodeData]);
  useEffect(() => {
    if (
      !selectedMachine &&
      machinesCtx.machines &&
      machinesCtx.machines.length
    ) {
      setSelectedMachine(
        machinesCtx.machines.find((machine) => machine.live === true)
      );
    }
  }, [machinesCtx.machines]);

  useEffect(() => {
    if (selectedMachine) {
      const newTotal = corporationCare
        ? selectedMachine.price * machineAmountToBuy + corporationCarePrice
        : selectedMachine.price * machineAmountToBuy;
      if (promoCodeData) {
        if (promoCodeData.type == "%") {
          setTotal(newTotal - (newTotal * promoCodeData.amount) / 100);
        } else {
          setTotal(newTotal - promoCodeData.amount);
        }
      } else {
        setTotal(newTotal);
      }

      // AsyncStorage.setItem("machineAmountToBuy", machineAmountToBuy.toString());
    }
  }, [machineAmountToBuy, selectedMachine, corporationCare]);

  const hostingCountryIdHandler = (hostingCountryId: string) => {
    sethostingCountryId(hostingCountryId);
  };
  const isInOrderFlowHandler = (active: boolean) => {
    setIsInOrderFlow(active);
  };
  const amountToInvestHandler = (amount: AmountToInvest, machine: Machine) => {
    setAmountToInvest(amount);
    setSelectedMachine(machine);
  };

  const machineSelectionHandler = (machine: Machine) => {
    setSelectedMachine(machine);
  };

  const promoCodeHandler = (code: string) => {
    setPromoCode(code);
  };
  const machineAmountToBuyHandler = (amount: number) => {
    setMachineAmountToBuy(amount);
  };

  const coporationCareHandler = (active: boolean) => {
    setCorporationCare(active);
  };

  const signUpDolphinUser = async (
    email: string,
    firstname: string,
    lastname: string,
    phone: string,
    country: string,
    countryDialCode: string,
    countryCode: string
  ) => {
    setIsLoading(true);
    try {
      const alertMessage = await orderServices.signUpDolphinUser(
        email,
        firstname,
        lastname,
        phone,
        country,
        countryDialCode,
        countryCode
      );
      setIsLoading(false);
      showAlert({
        title: alertMessage.header,
        message: alertMessage.detail,
        alertType: "success",
        onPress: () => console.log(""),
      });
    } catch (err) {
      setIsLoading(false);
      console.log(err);
      showAlert({
        title: err.header,
        message: err.detail,
        alertType: "error",
        customIcon: (
          <Ionicons name="close-circle-outline" size={50} color="#FF5B5B" />
        ),
        onPress: () => console.log(""),
      });
    }
  };
  const createAbandonedCart = async () => {
    setIsLoading(true);

    try {
      const res = await paymentServices.createAbandonedCart(
        selectedMachine.id,
        machineAmountToBuy,
        total,
        hostingCountryId,
        corporationCare,
        promoCodeData?.id
      );
      setAbandonedCartId(res.abandonedCartId);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
      // showAlert({
      //   title: err.header,
      //   message: err.detail,
      //   alertType: "error",
      //   customIcon: (
      //     <Ionicons name="close-circle-outline" size={50} color="#FF5B5B" />
      //   ),
      //   onPress: () => console.log(""),
      // });
    }
  };

  const createOrderTransfer = async () => {
    setIsLoading(true);

    try {
      await paymentServices.createOrderTransfer(
        selectedMachine.id,
        selectedMachine.name,
        machineAmountToBuy,
        selectedMachine.imageUrl,
        total,
        hostingCountryId,
        corporationCare,
        promoCodeData?.id,
        abandonedCartId
      );
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
      showAlert({
        title: err.header,
        message: err.detail,
        alertType: "error",
        customIcon: (
          <Ionicons name="close-circle-outline" size={50} color="#FF5B5B" />
        ),
        onPress: () => console.log(""),
      });
    }
  };
  const createOrderVivaWallet = async (
    countryCode?: string,
    requestLang?: string
  ) => {
    setIsLoading(true);
    try {
      const data = await paymentServices.createOrderVivaWallet(
        selectedMachine.id,
        selectedMachine.name,
        machineAmountToBuy,
        selectedMachine.imageUrl,
        total,
        hostingCountryId,
        corporationCare,
        countryCode,
        requestLang,
        promoCodeData?.id,
        abandonedCartId
      );
      setIsLoading(false);
      return data.orderCode;
    } catch (err) {
      setIsLoading(false);
      console.log(err);
      showAlert({
        title: err.header,
        message: err.detail,
        alertType: "error",
        customIcon: (
          <Ionicons name="close-circle-outline" size={50} color="#FF5B5B" />
        ),
        onPress: () => console.log(""),
      });

      return null;
    }
  };
  const createOrderCoinbase = async () => {
    setIsLoading(true);
    try {
      const data = await paymentServices.createOrderCoinbase(
        selectedMachine.id,
        selectedMachine.name,
        machineAmountToBuy,
        selectedMachine.imageUrl,
        total,
        hostingCountryId,
        corporationCare,
        promoCodeData?.id,
        abandonedCartId
      );
      setIsLoading(false);
      return data.hosted_url;
    } catch (err) {
      setIsLoading(false);
      console.log(err);
      showAlert({
        title: err.header,
        message: err.detail,
        alertType: "error",
        customIcon: (
          <Ionicons name="close-circle-outline" size={50} color="#FF5B5B" />
        ),
        onPress: () => console.log(""),
      });

      return null;
    }
  };

  return (
    <NewOrderContext.Provider
      value={{
        amountToInvest,
        selectedMachine,
        machineAmountToBuy,
        hostingCountryId,
        total,
        isLoading,
        corporationCare,
        isInOrderFlow,
        promoCode,
        promoCodeData,
        promoCodeError,
        CGV,
        abandonedCartId,
        signUpDolphinUser,
        promoCodeHandler,
        isInOrderFlowHandler,
        coporationCareHandler,
        amountToInvestHandler,
        machineSelectionHandler,
        machineAmountToBuyHandler,
        hostingCountryIdHandler,
        createOrderVivaWallet,
        createOrderTransfer,
        createOrderCoinbase,
        resetContext,
        CGVHandler,
        getPromoCode,
        createAbandonedCart,
      }}
    >
      {props.children}
    </NewOrderContext.Provider>
  );
};

export default NewOrderContext;
