// React
import React, { useEffect, useState, PropsWithChildren } from "react";

import { HostingCountry } from "../types";
import hostingCountryServices from "../services/apis/hostingCountry-service";
const HostingCountriesContext = React.createContext({
  hostingCountries: null as HostingCountry[] | null,
  initHostingCountryData: () => {},
});

export const HostingCountriesContextProvider: React.FC<PropsWithChildren> = (
  props: any
) => {
  const [hostingCountries, setHostingCountries] = useState<
    HostingCountry[] | null
  >(null);

  const [rtListenersList, setRtListenersList] = useState([] as Function[]);

  const initHostingCountryData = () => {
    const hostingCountriesListener: Function =
      hostingCountryServices.listentToHostingCountries(
        (hostingCountries: HostingCountry[]) =>
          setHostingCountries(hostingCountries)
      );

    rtListenersList.map((listener: Function) => listener());
    setRtListenersList([hostingCountriesListener]);
  };

  return (
    <HostingCountriesContext.Provider
      value={{
        hostingCountries,
        initHostingCountryData,
      }}
    >
      {props.children}
    </HostingCountriesContext.Provider>
  );
};

export default HostingCountriesContext;
