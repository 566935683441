// React
import React, {
  useEffect,
  useState,
  PropsWithChildren,
  useContext,
} from "react";
import cruxPoolServices from "../services/apis/cruxpool-service";
import {
  MinerEstEarnings,
  MinerHashrate,
  MinerHashrateHistory,
  MinerPayments,
  MinerAPIError,
  Workers,
  MinerHistory,
} from "../types";
import AuthContext from "./auth.ctx";

export type Crypto = {
  name: string;
  amount: number;
};

const updateData = (oldData: MinerHistory[], newData: MinerHistory[]) => {
  // Convert the newData array into a Map for efficient lookups
  const newDataMap = new Map(newData.map((item) => [item.start, item]));

  // Create a new array with updated values
  const updatedData = oldData.map((item) => {
    if (newDataMap.has(item.start)) {
      return newDataMap.get(item.start);
    }
    return item;
  });

  return updatedData;
};
const generateTimeSeries = (): MinerHistory[] => {
  let currentDate = new Date();

  // Round minutes down to the nearest quarter hour
  let minutes = currentDate.getMinutes();
  let remainder = minutes % 15;
  currentDate.setMinutes(minutes - remainder);

  // Subtract 24 hours
  currentDate.setHours(currentDate.getHours() - 24);
  currentDate.setSeconds(0);
  currentDate.setMilliseconds(0);

  const series = [];

  for (let i = 0; i < 24 * 4; i++) {
    series.push({
      start: currentDate.toISOString(),
      end: new Date(currentDate.getTime() + 15 * 60 * 1000).toISOString(),
      hashrate: 0,
      minedAmount: 0,
      validShares: 0,
      invalidShares: 0,
      duplicateShares: 0,
      staleShares: 0,
    });

    currentDate = new Date(currentDate.getTime() + 15 * 60 * 1000);
  }

  return series;
};

const defaultWorkers: Workers[] = [];

const defaultPayments = [
  {
    amount: 0,
    timestamp: 1688111860000,
    tx: "string",
  },
  {
    amount: 250,
    timestamp: 1688112700000,
    tx: "string",
  },
  {
    amount: 100,
    timestamp: 1688113500000,
    tx: "string",
  },
  {
    amount: 500,
    timestamp: 1688114400000,
    tx: "string",
  },
] as MinerPayments;

const defaultHashrateHistory = [] as MinerHashrateHistory;

const defaultHashrateHistoryValues = defaultHashrateHistory.map(
  (hashrate) => hashrate.amount
);

const defaultMinerHistory = generateTimeSeries();

const defaultBalance = 2.2;
const MinerContext = React.createContext({
  // convert data from API
  transactionsAmount: 0,
  totalPayments: 0,
  hashrateLast3h: 0,
  hashrateLast24h: 0,
  hashrateHistoryValues: defaultHashrateHistoryValues,
  btcBalanceRt: 0,
  btcBalance24h: 0,
  nextPayout: 0,
  lastPayout: 0,
  lastMonthPayment: 0,
  lastWeekPayment: 0,
  lastDayPayment: 0,
  invalidShares: 0,
  validShares: 0,
  staleShares: 0,
  // From API
  payments: null as MinerPayments | null,
  hashrate: null as MinerHashrate | null,
  hashrateHistoryMonth: null as MinerHashrateHistory | null,
  hashrateHistoryDay: null as MinerHashrateHistory | null,
  minerHistory: defaultMinerHistory as MinerHistory[] | null,
  estimatedEarnings: null as MinerEstEarnings | null,
  workers: [] as Workers[],
  errors: null as MinerAPIError | null,
  resetContext: () => {},
});

export const MinerContextProvider: React.FC<PropsWithChildren> = (
  props: any
) => {
  const authCtx = useContext(AuthContext);
  const [transactionsAmount, setTransactionsAmount] = useState(0);
  const [totalPayments, setTotalPayments] = useState(0);
  const [workers, setWorkers] = useState<Workers[]>(defaultWorkers);

  const [hashrateLast3h, setHashrateLast3h] = useState(0);
  const [hashrateLast24h, setHashrateLast24h] = useState(0);
  const [btcBalanceRt, setBtcBalanceRt] = useState(0);
  const [btcBalance24h, setBtcBalance24h] = useState(0);
  const [nextPayout, setNextPayout] = useState(0);
  const [lastPayout, setLastPayout] = useState(0);
  const [lastMonthPayment, setLastMonthPayment] = useState(0);
  const [lastWeekPayment, setLastWeekPayment] = useState(0);
  const [lastDayPayment, setLastDayPayment] = useState(0);

  const [invalidShares, setInvalidShares] = useState(0);
  const [validShares, setValidShares] = useState(0);
  const [staleShares, setstaleShares] = useState(0);
  const [payments, setPayments] = useState<MinerPayments | null>(null);
  const [hashrate, setHashrate] = useState<MinerHashrate | null>(null);
  const [hashrateHistoryMonth, setHashrateHistoryMonth] =
    useState<MinerHashrateHistory | null>(null);
  const [hashrateHistoryDay, setHashrateHistoryDay] =
    useState<MinerHashrateHistory | null>(null);

  const [minerHistory, setMinerHistory] = useState<MinerHistory[] | null>(
    defaultMinerHistory
  );

  const [hashrateHistoryValues, setHashrateHistoryValues] = useState<
    number[] | null
  >(defaultHashrateHistoryValues);
  const [estimatedEarnings, setEstimatedEarnings] =
    useState<MinerEstEarnings | null>(null);
  const [errors, setErrors] = useState<MinerAPIError | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const getAllDataFromAPI = async () => {
    try {
      setIsLoading(true);
      await getAndSetBalance();
      await getAndSetHashrate();
      await getAndSetHashrateHistoryMonth();
      await getAndSetHashrateHistoryDay();
      await getAndSetPayments();
      await getAndSetWorker();
      await getAndSetMinerHistoryDay();
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const resetContext = () => {
    setTransactionsAmount(0);
    setTotalPayments(0);
    setHashrateLast3h(0);
    setHashrateLast24h(0);
    setBtcBalanceRt(0);
    setBtcBalance24h(0);
    setNextPayout(0);
    setLastPayout(0);
    setLastMonthPayment(0);
    setLastWeekPayment(0);
    setLastDayPayment(0);
    setInvalidShares(0);
    setValidShares(0);
    setstaleShares(0);
    setPayments(null);
    setHashrate(null);
    setHashrateHistoryMonth(null);
    setHashrateHistoryDay(null);
    setMinerHistory(defaultMinerHistory);
    setHashrateHistoryValues(defaultHashrateHistoryValues);
    setEstimatedEarnings(null);
    setErrors(null);
  };
  const getAndSetWorker = async () => {
    try {
      const workers = await cruxPoolServices.getMinerWorkers(
        authCtx.userData?.walletAddress
      );

      setWorkers(workers);
    } catch (err) {
      console.log(err);
      setWorkers([]);
      setErrors({
        ...errors,
        balance: "API error",
      });
    }
  };

  const getAndSetBalance = async () => {
    try {
      const minerBalance = await cruxPoolServices.getMinerBalance(
        authCtx.userData?.walletAddress
      );
      if (minerBalance) {
        setBtcBalanceRt(parseInt(minerBalance) / 100000000);
      } else {
        setBtcBalanceRt(0);
      }
    } catch (err) {
      console.log(err);

      setErrors({
        ...errors,
        balance: "API error",
      });
    }
  };

  const getAndSetHashrate = async () => {
    try {
      const minerHashrate = await cruxPoolServices.getMinerHashrate(
        authCtx.userData?.walletAddress
      );
      if (minerHashrate) {
        setHashrate(minerHashrate);
      } else {
        setHashrate(null);
      }
    } catch (err) {
      console.log(err);
      setHashrate(null);
      setErrors({
        ...errors,
        hashrate: "API error",
      });
    }
  };

  const getAndSetHashrateHistoryMonth = async () => {
    try {
      const minerHashrateHistory =
        await cruxPoolServices.getMinerHashrateHistoryMonth(
          authCtx.userData?.walletAddress
        );
      if (minerHashrateHistory) {
        const sortedHashrateHistory = minerHashrateHistory.sort(
          (a, b) => b.timestamp - a.timestamp
        );
        setHashrateHistoryMonth(sortedHashrateHistory);
        setBtcBalance24h(
          sortedHashrateHistory.length
            ? sortedHashrateHistory[0].amount / 100000000
            : 0
        );
      } else {
        setHashrateHistoryMonth(null);
        setBtcBalance24h(0);
      }
    } catch (err) {
      console.log(err);
      setHashrateHistoryMonth(null);
      setBtcBalance24h(0);
      setErrors({
        ...errors,
        hashrateHistory: "API error",
      });
    }
  };

  const getAndSetMinerHistoryDay = async () => {
    try {
      let todayDate = new Date();

      // Round minutes down to the nearest quarter hour
      let minutesToday = todayDate.getMinutes();
      let remainderToday = minutesToday % 15;
      todayDate.setMinutes(minutesToday - remainderToday);

      let oneDayAgo = new Date();

      // Round minutes down to the nearest quarter hour
      let minutes = oneDayAgo.getMinutes();
      let remainder = minutes % 15;
      oneDayAgo.setMinutes(minutes - remainder);

      // Subtract 24 hours
      oneDayAgo.setHours(oneDayAgo.getHours() - 24);
      oneDayAgo.setSeconds(0);
      oneDayAgo.setMilliseconds(0);

      const newMinerHistory = await cruxPoolServices.getMinerHistory(
        authCtx.userData?.walletAddress,
        oneDayAgo.toISOString().slice(0, 19),
        todayDate.toISOString().slice(0, 19),
        "FifteenMins"
      );

      if (newMinerHistory) {
        const updatedMinerHistory = updateData(minerHistory, newMinerHistory);
        setMinerHistory(updatedMinerHistory);
      } else {
        setMinerHistory([]);
      }
    } catch (err) {
      console.log(err);
      setMinerHistory([]);
      setErrors({
        ...errors,
        hashrateHistory: "API error",
      });
    }
  };

  const getAndSetHashrateHistoryDay = async () => {
    try {
      const minerHashrateHistory =
        await cruxPoolServices.getMinerHashrateHistoryDay(
          authCtx.userData?.walletAddress
        );
      if (minerHashrateHistory) {
        const sortedHashrateHistory = minerHashrateHistory.sort(
          (a, b) => b.timestamp - a.timestamp
        );
        setHashrateHistoryDay(sortedHashrateHistory);
      } else {
        setHashrateHistoryDay(null);
      }
    } catch (err) {
      console.log(err);
      setHashrateHistoryDay(null);
      setErrors({
        ...errors,
        hashrateHistory: "API error",
      });
    }
  };

  const getAndSetEstimatedEarnings = async () => {
    try {
      const minerEstimatedEarnings =
        await cruxPoolServices.getEstimatedEarnings(
          hashrate.hashrate / 1000000
        );
      if (minerEstimatedEarnings) {
        setEstimatedEarnings(minerEstimatedEarnings);
      } else {
        setEstimatedEarnings(null);
      }
    } catch (err) {
      console.log(err);
      setEstimatedEarnings(null);
      setErrors({
        ...errors,
        estimatedEarnings: "API error",
      });
    }
  };

  const getAndSetPayments = async () => {
    try {
      setIsLoading(true);
      const minerPayments = await cruxPoolServices.getMinerPayments(
        authCtx.userData?.walletAddress
      );

      if (minerPayments) {
        const sortedTransactions = minerPayments.sort(
          (a, b) => b.timestamp - a.timestamp
        );
        setPayments(sortedTransactions);
      } else {
        setPayments([]);
      }

      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setPayments([]);
      setIsLoading(false);
      setErrors({
        ...errors,
        hashrateHistory: "API error",
      });
    }
  };

  useEffect(() => {
    if (authCtx.userData?.walletAddress) {
      getAllDataFromAPI();
    }
  }, [authCtx.userData?.walletAddress]);

  useEffect(() => {
    if (hashrateHistoryDay?.length) {
      const now = new Date();
      const threeHoursAgo = now.getTime() - 3 * 60 * 60 * 1000;
      const oneDayAgo = now.getTime() - 24 * 60 * 60 * 1000;

      const hashrateLastThreeHours = hashrateHistoryDay.filter(
        (payment) => payment.timestamp * 1000 >= threeHoursAgo
      );
      const hashrateLastDay = hashrateHistoryDay.filter(
        (payment) => payment.timestamp * 1000 >= oneDayAgo
      );
      const averageLastThreeHours =
        hashrateLastThreeHours.reduce(
          (total, hashrate) => total + hashrate.avg,
          0
        ) / hashrateLastThreeHours.length || 0;
      const averageLastDay =
        hashrateLastDay.reduce((total, hashrate) => total + hashrate.avg, 0) /
          hashrateLastDay.length || 0;
      const hashrateHistoryDayValuesTmp = hashrateHistoryDay.map(
        (hashrate) => hashrate.avg
      );
      setHashrateLast3h(averageLastThreeHours);
      setHashrateLast24h(averageLastDay);
      setHashrateHistoryValues(hashrateHistoryDayValuesTmp);
    }
  }, [hashrateHistoryDay]);

  useEffect(() => {
    if (hashrate?.hashrate) {
      getAndSetEstimatedEarnings();
    }
  }, [hashrate?.hashrate]);

  useEffect(() => {
    if (workers?.length) {
      let totalInvalidShares = 0;
      let totalValidShares = 0;
      let totalStalesShare = 0;
      for (const worker of workers) {
        totalInvalidShares = totalInvalidShares + worker.invalidShares;
        totalValidShares = totalValidShares + worker.shares;
        totalStalesShare = totalStalesShare + worker.staleShares;
      }
      setValidShares(totalValidShares);
      setInvalidShares(totalInvalidShares);
      setstaleShares(totalStalesShare);
    }
  }, [workers?.length]);

  useEffect(() => {
    if (payments?.length) {
      setTransactionsAmount(payments.length);
      const total = payments.reduce((accumulator, payment) => {
        return accumulator + payment.amount;
      }, 0);
      setTotalPayments(total);

      setLastPayout(payments[0].amount);

      const now = new Date();
      const oneDayAgo = now.getTime() - 24 * 60 * 60 * 1000;
      const oneWeekAgo = now.getTime() - 7 * 24 * 60 * 60 * 1000;
      const oneMonthAgo = new Date(
        now.getFullYear(),
        now.getMonth() - 1,
        now.getDate()
      ).getTime();

      const paymentsLastDay = payments.filter(
        (payment) => payment.timestamp * 1000 >= oneDayAgo
      );
      const paymentsLastWeek = payments.filter(
        (payment) => payment.timestamp * 1000 >= oneWeekAgo
      );
      const paymentsLastMonth = payments.filter(
        (payment) => payment.timestamp * 1000 >= oneMonthAgo
      );

      const totalLastDay = paymentsLastDay.reduce(
        (total, payment) => total + payment.amount,
        0
      );
      const totalLastWeek = paymentsLastWeek.reduce(
        (total, payment) => total + payment.amount,
        0
      );
      const totalLastMonth = paymentsLastMonth.reduce(
        (total, payment) => total + payment.amount,
        0
      );
      setLastDayPayment(totalLastDay);
      setLastWeekPayment(totalLastWeek);
      setLastMonthPayment(totalLastMonth);
    }
    setNextPayout(authCtx.payoutThreshold);
  }, [payments]);

  return (
    <MinerContext.Provider
      value={{
        transactionsAmount,
        totalPayments,

        hashrateLast3h,
        hashrateLast24h,
        btcBalanceRt,
        btcBalance24h,
        hashrateHistoryValues,
        nextPayout,
        lastPayout,
        lastMonthPayment,
        lastWeekPayment,
        lastDayPayment,
        invalidShares,
        validShares,
        staleShares,
        payments,
        hashrate,
        hashrateHistoryMonth,
        hashrateHistoryDay,
        minerHistory,
        estimatedEarnings,
        workers,
        errors,
        resetContext,
      }}
    >
      {props.children}
    </MinerContext.Provider>
  );
};

export default MinerContext;
