import {
  Alert,
  SignUpWithMailReq,
  SignUpWithProviderReq,
  SignUpRes,
  FunctionalAlert,
  UserData,
  WalletAddress,
} from "../../types";
import { auth, functions, firestore } from "../app/firebase-service";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import {
  doc,
  onSnapshot,
  updateDoc,
  collection,
  addDoc,
  deleteDoc,
  getDocs,
  where,
  query,
} from "firebase/firestore";
import {
  indexedDBLocalPersistence,
  browserSessionPersistence,
  deleteUser,
  confirmPasswordReset,
  IdTokenResult,
} from "firebase/auth";

import {
  signInWithEmailAndPassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
  sendPasswordResetEmail,
  updateEmail,
} from "firebase/auth";
import errors from "../../messages/error";
import success from "../../messages/success";

const authServices = {
  // SignUp Service
  signUpWithEmailAndPassword(
    email: string,
    password: string,
    userData?: UserData
  ): Promise<Alert> {
    return new Promise((res, rej) => {
      // Create new user in DB and in Firebase auth Cloud functions
      const createNewUserWithMail = httpsCallable<SignUpWithMailReq, SignUpRes>(
        functions,
        "createNewUserWithMail"
      );
      console.log(userData);
      createNewUserWithMail({ email, password, userData })
        .then((result) => {
          if (result.data.status == "ERROR") {
            if (result.data.fbCode == "auth/email-already-exists") {
              // If email already exists custom error
              rej(errors.USER_ALREADY_EXISTS_ERROR_ALERT);
            } else if (result.data.fbCode == "btcAdress-already-in-use") {
              rej({
                header: "Erreur",
                detail:
                  "L'adresse BTC est déjà enregistrée sur un autre compte",
              });
            } else {
              // generic error
              rej(errors.SIGNUP_ERROR_ALERT);
            }
          } else {
            return res(success.SIGNUP_SUCCESS_ALERT);
          }
        })
        .catch((e) => {
          console.log(e);
          // generic error
          if (e.code == "btcAdress-already-in-use") {
            return rej({
              header: "Erreur",
              detail: "L'adresse BTC est déjà enregistré sur un compte",
            });
          }
          return rej(errors.SIGNUP_ERROR_ALERT);
        });
    });
  },
  // SignIn service
  signInWithEmailAndPassword(
    email: string,
    password: string
  ): Promise<IdTokenResult> {
    return new Promise((res, rej) => {
      // handle user auth persistence depending on rememberMe value
      signInWithEmailAndPassword(auth, email, password)
        .then(async (data) => {
          if (auth.currentUser) {
            // Get user token and return
            const userToken = await auth.currentUser.getIdTokenResult();
            console.log("USER TOKEN : ", userToken);
            res(userToken);
          } else {
            // Generic error
            rej(errors.SIGNIN_ERROR_ALERT);
          }
        })
        .catch((e) => {
          if (e.code == "auth/user-not-found") {
            // Custom error
            rej(errors.USER_NOT_FOUND_ERROR_ALERT);
          } else if (e.code == "auth/wrong-password") {
            // Custom error
            rej(errors.WRONG_USER_PASSWORD_ERROR_ALERT);
          }
          // Generic error
          rej(errors.SIGNIN_ERROR_ALERT);
        });
    });
  },
  deleteUserAccount(): Promise<string> {
    return new Promise((res, rej) => {
      // handle user auth persistence depending on rememberMe value
      const user = auth.currentUser;
      const userDoc = doc(firestore, "users", user.uid);
      deleteUser(user)
        .then(async (data) => {
          return deleteDoc(userDoc);
        })
        .then(() => {
          res("OK");
        })
        .catch((e) => {
          // Generic error
          rej({
            header: "Erreur !",
            detail:
              "Erreur lors de la suppression du compte, veuillez réessayer",
          });
        });
    });
  },
  // Update password service
  updatePassword(
    userEmail: string,
    previousPassword: string,
    newPassword: string
  ): Promise<Alert> {
    return new Promise((res, rej) => {
      // Get authenticated user credential
      const credential = EmailAuthProvider.credential(
        userEmail,
        previousPassword
      );
      const currentUser = auth.currentUser;
      if (currentUser) {
        reauthenticateWithCredential(currentUser, credential)
          .then(() => {
            updatePassword(currentUser, newPassword)
              .then(() => {
                // Success
                res(success.PASSWORD_UPDATE_SUCCESS_ALERT);
              })
              .catch((err) => {
                rej(errors.PASSWORD_UPDATE_ERROR_ALERT);
              });
          })
          .catch((err) => {
            console.log(err);
            rej(errors.WRONG_CREDENTIALS_PASSWORD_UPDATE_ERROR_ALERT);
          });
      } else {
        rej(errors.WRONG_CREDENTIALS_PASSWORD_UPDATE_ERROR_ALERT);
      }
    });
  },
  updateEmail(previousEmail: string, newEmail: string): Promise<Alert> {
    return new Promise((res, rej) => {
      // Get authenticated user credential

      const currentUser = auth.currentUser;
      if (currentUser) {
        if (currentUser.email == previousEmail) {
          updateEmail(currentUser, newEmail)
            .then(() => {
              // Success
              res(success.EMAIL_UPDATE_SUCCESS_ALERT);
            })
            .catch((err) => {
              if (err.code == "auth/email-already-in-use") {
                rej({
                  header: "Erreur !",
                  detail: "Cette email est déjà utilisé sur un autre compte",
                });
              } else {
                rej(errors.EMAIL_UPDATE_ERROR_ALERT);
              }
            });
        } else {
          rej(errors.WRONG_CREDENTIALS_EMAIL_UPDATE_ERROR_ALERT);
        }
      } else {
        rej(errors.WRONG_CREDENTIALS_EMAIL_UPDATE_ERROR_ALERT);
      }
    });
  },
  // Reset password service
  sendPasswordResetEmail(email: string): Promise<FunctionalAlert> {
    return new Promise((res, rej) => {
      sendPasswordResetEmail(auth, email)
        .then((data) => res(success.SEND_PASSWORD_RESET_EMAIL_SUCCESS_ALERT))
        .catch((e) => {
          console.log(e.code);
          if (e.code == "auth/user-not-found") {
            // custom error if user not found
            rej(errors.RESET_PASSWORD_USER_NOT_FOUND);
          }
          // else generic error
          rej(errors.RESET_PASSWORD_ERROR_ALERT);
        });
    });
  },

  // Reset password service
  resetPassword(code: string, newPassword: string): Promise<Alert> {
    return new Promise((res, rej) => {
      confirmPasswordReset(auth, code, newPassword)
        .then((data) => res(success.PASSWORD_RESET_SUCCESS_ALERT))
        .catch((e) => {
          console.log(e.code);
          if (e.code == "auth/user-not-found") {
            // custom error if user not found
            rej(errors.RESET_PASSWORD_USER_NOT_FOUND);
          }
          // else generic error
          rej(errors.RESET_PASSWORD_ERROR_ALERT);
        });
    });
  },

  // SignIn with Google
  SignInWithGoogle(): Promise<void> {
    return new Promise((res, rej) => {
      // Set Google Provider
      const provider = new GoogleAuthProvider();
      signInWithPopup(auth, provider)
        .then((result) => {
          // The signed-in user info.
          const user = result.user;
          // Create user in DB if not exists
          const createNewUserWithProvider = httpsCallable<
            SignUpWithProviderReq,
            SignUpRes
          >(functions, "createNewUserWithProvider");

          return createNewUserWithProvider({
            userId: user.uid,
            email: user.email,
          });
        })
        .then((result) => {
          if (result.data.status == "ERROR") {
            // User already exists OK resolve
            if (result.data.fbCode == "id-already-exists") {
              res();
            } else {
              // generic error
              rej(errors.GOOGLE_SIGN_IN_ERROR);
            }
          } else {
            return res();
          }
        })
        .catch((e) => {
          // Handle Errors here.
          rej(errors.GOOGLE_SIGN_IN_ERROR);
        });
    });
  },
  listenToUserData(userId: string, listenerCallback: Function) {
    const userDataDoc = doc(firestore, "users", userId);

    const userDataListener = onSnapshot(userDataDoc, async (userDataSnap) => {
      let userData: UserData;
      if (userDataSnap.exists) {
        userData = userDataSnap.data() as UserData;
      }
      listenerCallback(userData);
    });
    return userDataListener;
  },
  updateUserData(userId: string, userData: UserData): Promise<Alert> {
    return new Promise((res, rej) => {
      const updateUser = httpsCallable<any, SignUpRes>(
        functions,
        "updateUserData"
      );
      updateUser({ ...userData })
        .then((result) => {
          if (result.data.status == "ERROR") {
            if (result.data.fbCode == "btcAdress-already-in-use") {
              // If email already exists custom error
              rej({
                header: "Erreur",
                detail:
                  "L'adresse BTC est déjà enregistrée sur un autre compte",
              });
            } else {
              // generic error
              rej(errors.PERSONAL_DATA_ERROR);
            }
          } else {
            return res(success.PERSONAL_DATA_SUCCES);
          }
        })
        .catch((e) => {
          console.log(e);
          return rej(errors.PERSONAL_DATA_ERROR);
        });

      // const usersUserDataDoc = doc(firestore, "users", userId);

      // updateDoc(usersUserDataDoc, {
      //   ...userData,
      // })
      //   .then(() => {
      //     res(success.PERSONAL_DATA_SUCCES);
      //   })
      //   .catch((e) => {
      //     console.log(e);
      //     rej(errors.PERSONAL_DATA_ERROR);
      //   });
    });
  },
  createEmail(email: string): Promise<Alert> {
    return new Promise((res, rej) => {
      const emailsCollection = collection(firestore, "emails");

      addDoc(emailsCollection, {
        email: email,
      })
        .then(() => {
          res(success.PERSONAL_DATA_SUCCES);
        })
        .catch((e) => {
          console.log(e);
          rej(errors.ERROR_GENERIC);
        });
    });
  },
  listenToWalletAddresses(userId: string, listenerCallback: Function) {
    const userWalletsCollection = collection(
      firestore,
      `users/${userId}/walletAddresses`
    );

    const walletAddressesListener = onSnapshot(
      userWalletsCollection,
      async (walletAddressesSnap) => {
        const userWalletAdresses: WalletAddress[] = [];
        if (!walletAddressesSnap.empty) {
          for (const walletAddress of walletAddressesSnap.docs) {
            userWalletAdresses.push({
              id: walletAddress.id,
              ...(walletAddress.data() as WalletAddress),
            });
          }
        }
        const sortedUserWalletAdresses = userWalletAdresses.sort(
          (a, b) => b.activeAt.seconds - a.activeAt.seconds
        );
        listenerCallback(sortedUserWalletAdresses);
      }
    );
    return walletAddressesListener;
  },
  addWalletAddress(
    userId: string,
    walletAddress: WalletAddress
  ): Promise<Alert> {
    return new Promise((res, rej) => {
      const userWalletsCollection = collection(
        firestore,
        `users/${userId}/walletAddresses`
      );
      addDoc(userWalletsCollection, {
        ...walletAddress,
      })
        .then(() => {
          res(success.WALLET_ADDRESS_CREATE_SUCCES);
        })
        .catch((e) => {
          console.log(e);
          rej(errors.WALLET_ADDRESS_CREATE_ERROR);
        });
    });
  },
  deleteWalletAddress(userId: string, walletId: string): Promise<Alert> {
    return new Promise((res, rej) => {
      const walletDoc = doc(
        firestore,
        `users/${userId}/walletAddresses`,
        walletId
      );
      deleteDoc(walletDoc)
        .then(() => {
          res(success.WALLET_ADDRESS_DELETE_SUCCES);
        })
        .catch((e) => {
          console.log(e);
          rej(errors.WALLET_ADDRESS_DELETE_ERROR);
        });
    });
  },
  getTOTP(): Promise<string> {
    return new Promise((res, rej) => {
      // Create new user in DB and in Firebase auth Cloud functions
      const getTOTP = httpsCallable(functions, "getTOTP");
      getTOTP()
        .then((result: any) => {
          if (result.data.status == "ERROR") {
            // generic error
            rej(errors.ERROR_GENERIC);
          } else {
            res(result.data.otpauth);
          }
        })
        .catch((e) => {
          console.log(e);
          // generic error
          return rej(errors.ERROR_GENERIC);
        });
    });
  },
  verifyTOTP(token: string): Promise<boolean> {
    return new Promise((res, rej) => {
      // Create new user in DB and in Firebase auth Cloud functions
      const verifyTOTP = httpsCallable(functions, "verifyTOTP");
      verifyTOTP({ token })
        .then((result: any) => {
          if (result.data.status == "ERROR") {
            // generic error
            rej(errors.ERROR_GENERIC);
          } else {
            res(result.data.isValid);
          }
        })
        .catch((e) => {
          console.log(e);
          // generic error
          return rej(errors.ERROR_GENERIC);
        });
    });
  },
};

export default authServices;
