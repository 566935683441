import React, { useState } from "react";
import {
  View,
  StyleSheet,
  PressableProps,
  Platform,
  Pressable,
  ActivityIndicator,
  ImageBackground,
  StyleProp,
} from "react-native";
import BaseLoader from "../BaseLoader/BaseLoader";
import { SvgProps } from "react-native-svg";
import BaseText from "../BaseText/BaseText";
import colors from "../../../configs/colors";
import Ionicons from "@expo/vector-icons/Ionicons";
import useIsMobile from "../../../utils/useIsMobile";
import { BaseTextProps } from "../SalesFunnel/BaseTextSalesFunnel/BaseTextSalesFunnel";
interface BaseButtonProps extends PressableProps {
  title?: string;
  isLoading?: boolean;
  noGradient?: boolean;
  bgColor?: string;
  textColor?: string;
  iconName?: any;
  iconSize?: number;
  iconColor?: string;
  Icon?: React.ReactNode;
  IconRight?: React.FC<SvgProps>;
  borderColor?: string;
  notFullWidth?: boolean;
  smallPadding?: boolean;
  textProps?: BaseTextProps;
  href?: string;
  filename?: string;
  style?: StyleProp<any>;
}
const BaseButton: React.FC<BaseButtonProps> = (props) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isPressed, setIsPressed] = useState(false);
  const isMobile = useIsMobile();
  const handleMouseEnter = () => {
    if (Platform.OS === "web") {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    if (Platform.OS === "web") {
      setIsHovered(false);
    }
  };

  const handlePressIn = () => {
    setIsPressed(true);
  };

  const handlePressOut = () => {
    setIsPressed(false);
  };
  return (
    <Pressable
      {...props}
      style={[
        props.noGradient ? styles.containerNoGradient : styles.container,
        (isHovered || isPressed) && styles.containerHover,
        props.bgColor && { backgroundColor: props.bgColor },
        props.notFullWidth &&
          !props.smallPadding && {
            alignSelf: "flex-start",
            paddingHorizontal: isMobile ? 42 : 62,
          },
        props.notFullWidth &&
          props.smallPadding && {
            alignSelf: "flex-start",
            paddingHorizontal: 8,
          },
        props.borderColor && {
          borderWidth: 1,
          borderColor: props.borderColor,
        },
        props.style && {
          ...props.style,
        },
      ]}
      disabled={props.disabled || props.isLoading}
      onHoverIn={handleMouseEnter}
      onHoverOut={handleMouseLeave}
      onPressIn={handlePressIn}
      onPressOut={handlePressOut}
    >
      {props.noGradient ? null : (
        <ImageBackground
          source={require("../../../../assets/images/button-background-min.png")}
          style={styles.gradient1}
        />
      )}

      {props.isLoading ? (
        <View style={{ padding: 8 }}>
          <BaseLoader
            size={22}
            color={
              props.bgColor == "#FFFFFF" || props.bgColor == "#fff"
                ? "#000"
                : "#FFFFFF"
            }
          />
        </View>
      ) : (
        <View style={styles.buttonContentStyle}>
          {props.iconName ? (
            <Ionicons
              name={props.iconName}
              size={props.iconSize}
              color={props.iconColor}
            />
          ) : null}
          {props.Icon ? (
            <View style={{ paddingRight: 8 }}>{props.Icon}</View>
          ) : null}
          {props.title ? (
            <BaseText
              text={props.title}
              bold
              style={
                props.noGradient
                  ? {
                      ...styles.textNoGradient,
                      ...(props.textColor && { color: props.textColor }),
                    }
                  : {
                      ...styles.text,
                      ...(props.textColor && { color: props.textColor }),
                    }
              }
              {...props.textProps}
            />
          ) : null}

          {props.IconRight ? (
            <View style={{ paddingRight: 8 }}>
              <props.IconRight />
            </View>
          ) : null}
        </View>
      )}
    </Pressable>
  );
};

export default BaseButton;

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
    overflow: "hidden",
    paddingVertical: 8,
    paddingHorizontal: 8,
    marginVertical: 10,
    marginHorizontal: 4,
  },
  containerNoGradient: {
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
    marginVertical: 6,
    marginHorizontal: 4,
    paddingVertical: 8,
    paddingHorizontal: 8,
    overflow: "hidden",
  },
  buttonContentStyle: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  gradient1: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 0,
  },
  text: {
    zIndex: 10,
    color: colors.white,
  },
  textNoGradient: {
    zIndex: 10,
    color: colors.white,
  },
  containerHover: {
    opacity: 0.7,
  },
});
