import * as React from "react";
import Svg, {
  Path,
  SvgProps,
  Defs,
  LinearGradient,
  Stop,
  G,
} from "react-native-svg";

const DubaiFlagIcon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width={24} height={17} viewBox="0 0 24 17" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.2262 15H3.48488C3.19538 15 2.96094 14.7656 2.96094 14.4761V2.52394C2.96094 2.23445 3.19538 2 3.48488 2H21.2262C21.5157 2 21.7501 2.23445 21.7501 2.52394V14.4761C21.7501 14.7656 21.5156 15 21.2262 15Z"
        fill="#FE5022"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.41577 10.9059L7.56348 15H21.2262C21.5156 15 21.7501 14.7656 21.7501 14.4761V10.9059L9.41577 10.9059Z"
        fill="#4D4D4D"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.56495 2L9.41617 6.09413H21.7507V2.52394C21.7507 2.23445 21.5162 2 21.2267 2L7.56495 2Z"
        fill="#378B4E"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.52333 6.39809V10.6023C9.52333 10.6453 9.51431 10.688 9.49658 10.7272L9.41575 10.9058H21.75V6.09412H9.41553L9.49649 6.27321C9.51422 6.31248 9.52333 6.355 9.52333 6.39809Z"
        fill="#F2F2F2"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.56345 13.8561H3.48488C3.19538 13.8561 2.96094 13.6216 2.96094 13.3322V14.4761C2.96094 14.7655 3.19538 15 3.48488 15H7.56345L8.08097 13.8561H7.56345Z"
        fill="#E5481F"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.2261 13.8561H8.081L7.56348 15H21.2262C21.5156 15 21.7501 14.7655 21.7501 14.4761V13.3322C21.7501 13.6216 21.5155 13.8561 21.2261 13.8561Z"
        fill="#454545"
      />
    </Svg>
  );
};

export default DubaiFlagIcon;
