import React, { useContext } from "react";
import { View, Image, StyleSheet } from "react-native";

import BaseText from "../../Base/BaseText/BaseText";
import NewOrderContext from "../../../contexts/new-order.ctx";
import ResumeItem from "./ResumeItem";
import useIsMobile from "../../../utils/useIsMobile";
import BasePressable from "../../Base/BasePressable/BasePressable";
import Ionicons from "@expo/vector-icons/Ionicons";
import { StackNavigationProp } from "@react-navigation/stack";
import Header from "../Header/Header";
import SFContainer from "../SFContainer/SFContainer";
import colors from "../../../configs/colors";
import { useRoute } from "@react-navigation/native";
import routes from "../../../routes";
const FAQItem: React.FC<{ title: string; body: string }> = ({
  title,
  body,
}) => {
  return (
    <View style={{ marginVertical: "3%" }}>
      <BaseText
        style={{ fontSize: 16, fontWeight: "700" }}
        bold
        text={title}
      ></BaseText>
      <BaseText
        style={{ fontSize: 15, fontWeight: "400" }}
        text={body}
      ></BaseText>
    </View>
  );
};
const InvestmentResume: React.FC<{
  navigation: StackNavigationProp<any>;
  hideOrderFlow?: boolean;
}> = (props) => {
  const newOrderCtx = useContext(NewOrderContext);
  const isMobile = useIsMobile();
  const route = useRoute();
  return (
    <View style={isMobile ? {} : { flex: 1 }}>
      {isMobile ? (
        <SFContainer
          navigation={props.navigation}
          hideMobileHeader
          bg={"l"}
          style={{ height: "auto" }}
        >
          <View>
            <ResumeItem
              style={{
                marginVertical: "4%",
                padding: "4%",
                borderWidth: 1,
                borderColor: colors.white,
                borderRadius: 10,
                marginHorizontal: 14,
              }}
              machineAmountToBuy={newOrderCtx.machineAmountToBuy}
              machineName={newOrderCtx.selectedMachine.name}
              total={newOrderCtx.total}
              imageUrl={newOrderCtx.selectedMachine.imageUrl}
              corporationCare={newOrderCtx.corporationCare}
            />
          </View>
        </SFContainer>
      ) : (
        <View
          style={
            isMobile
              ? { marginHorizontal: "3%", marginVertical: "1%" }
              : {
                  marginHorizontal: "3%",
                  paddingVertical: "1%",
                  flex: 1,
                }
          }
        >
          <BasePressable
            style={styles.backContainer}
            onClick={() => props.navigation.goBack()}
          >
            <Ionicons name="chevron-back-outline" size={22} color="#fff" />
            {isMobile ? null : (
              <BaseText
                text="Retour"
                style={{ fontSize: 20, fontWeight: "400" }}
              ></BaseText>
            )}
          </BasePressable>
          {newOrderCtx.selectedMachine && newOrderCtx.isInOrderFlow ? (
            <View
              style={
                isMobile
                  ? {
                      marginLeft: "6%",
                      marginVertical: "6%",
                      marginRight: "5%",
                    }
                  : {
                      marginLeft: "6%",
                      marginVertical: "6%",
                      marginRight: "5%",
                      flex: 1,
                    }
              }
            >
              <ResumeItem
                machineAmountToBuy={newOrderCtx.machineAmountToBuy}
                machineName={newOrderCtx.selectedMachine.name}
                total={newOrderCtx.total}
                imageUrl={
                  newOrderCtx.selectedMachine.imageUrl ||
                  "https://firebasestorage.googleapis.com/v0/b/corporations-fd072.appspot.com/o/products_image%2Fasic-min.png?alt=media"
                }
                corporationCare={newOrderCtx.corporationCare}
              />
              <View
                style={{
                  height: 1,
                  backgroundColor: "#fff",
                  marginTop: "5%",
                  marginBottom: "6%",
                }}
              ></View>
              <View
                style={{
                  // paddingVertical: 40,
                  justifyContent: "center",
                  alignItems: "center",
                  flex: 1,
                }}
              >
                <FAQItem
                  title={
                    route.name ===
                    routes.salesFunnel.screens.salesSignIn.screenName
                      ? "Comment se déroule le processus après le paiement ?"
                      : "Est-ce possible de payer mes factures d'électricité avec les cryptos que j'ai minés ?"
                  }
                  body={
                    route.name ===
                    routes.salesFunnel.screens.salesSignIn.screenName
                      ? "Une fois votre paiement reçu, votre commande est lancée. Il faudra ensuite patienter le temps de la réception de la machine dans nos entrepôts et de sa mise en service. Vous serez informé par e-mail de l'avancement de chaque étape."
                      : "Absolument ! L'un des nombreux avantages de miner avec nous est que nous offrons la possibilité à nos clients de payer leurs frais d'électricité directement en crypto."
                  }
                />
                <FAQItem
                  title={
                    route.name ===
                    routes.salesFunnel.screens.salesSignIn.screenName
                      ? "Comment suivre les performances de mes machines ?"
                      : "Comment gagnez-vous de l'argent sans facturer de frais de fonctionnement ?"
                  }
                  body={
                    route.name ===
                    routes.salesFunnel.screens.salesSignIn.screenName
                      ? "Nous avons une application qui vous permet de suivre en temps réel toutes les données importantes de votre investissement"
                      : "La plupart des sociétés de minage facturent des frais d'hébergement en plus du coût de l'électricité, entre 10 à 30 % de votre production de bitcoins minés en moyenne. Notre modèle d'affaires repose uniquement sur une marge prise lors de votre achat. Aucuns frais de fonctionnement d'hébergement ne vous seront facturés."
                  }
                />
                <FAQItem
                  title={
                    route.name ===
                    routes.salesFunnel.screens.salesSignIn.screenName
                      ? "Débranchez-vous les machines si la valeur du bitcoin baisse trop ? "
                      : "Puis-je visiter les fermes ?"
                  }
                  body={
                    route.name ===
                    routes.salesFunnel.screens.salesSignIn.screenName
                      ? "Oui, si la valeur du bitcoin baisse et que le coût de l'électricité dépasse la valeur des bitcoins minés, nous débranchons les machines sans vous facturer de frais, nous sommes avec vous dans les bons comme dans les mauvais moments, nous sommes une équipe soudée !"
                      : "Les visites sont réservées à nos clients. Si vous êtes dans la région, nous pouvons organiser une visite. "
                  }
                />
              </View>
            </View>
          ) : (
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <BaseText
                bold
                style={{ fontSize: 24 }}
                text="No Machine Selected"
              ></BaseText>
            </View>
          )}
        </View>
      )}
    </View>
  );
};

export default InvestmentResume;

const styles = StyleSheet.create({
  logoContainer: {
    paddingVertical: 16,
  },
  logo: {
    width: 200,
    height: 50,
    resizeMode: "contain",
  },
  backContainer: {
    flexDirection: "row",
    alignItems: "center",
    padding: 10,
  },
});
