import { HostingCountry, HostingCountryData } from "../../types";
import { auth, functions, firestore } from "../app/firebase-service";

import {
  doc,
  onSnapshot,
  updateDoc,
  collection,
  addDoc,
  getDocs,
  where,
  query,
  deleteDoc,
  getDoc,
  orderBy
} from "firebase/firestore";

const hostingCountryServices = {
  // SignUp Service

  listentToHostingCountries(listenerCallback: Function) {
    const hostinCountriesCollection = collection(
      firestore,
      "hosting_countries"
    );
    const q = query(hostinCountriesCollection, orderBy("activated", "desc"));
    const hostinCountriesListener = onSnapshot(
      q,
      async (hostinCountriesSnap) => {
        const hostinCountries: HostingCountry[] = [];
        console.log(hostinCountriesSnap.size);
        if (!hostinCountriesSnap.empty) {
          for (const hostinCountry of hostinCountriesSnap.docs) {
            hostinCountries.push({
              id: hostinCountry.id,
              ...(hostinCountry.data() as HostingCountry),
            });
          }
        }
        listenerCallback(hostinCountries);
      }
    );
    return hostinCountriesListener;
  },

  getHostingCountry(hostingId: string): Promise<HostingCountry | undefined> {
    return new Promise((res, rej) => {
      if (hostingId) {
        const hostingDataDoc = doc(firestore, "hosting_countries", hostingId);

        getDoc(hostingDataDoc)
          .then((hostingSnap) => {
            if (hostingSnap.exists()) {
              res({
                id: hostingSnap.id,
                ...(hostingSnap.data() as HostingCountryData),
              });
            } else {
              res(null);
            }
          })
          .catch((e) => {
            console.log(e);
            rej("");
          });
      } else {
        res(undefined);
      }
    });
  },
  createHostingCountry(hostingData: HostingCountry): Promise<any> {
    return new Promise((res, rej) => {
      const hostingDataDoc = collection(firestore, "hosting_countries");

      addDoc(hostingDataDoc, {
        ...hostingData,
      })
        .then(() => {
          res("");
        })
        .catch((e) => {
          console.log(e);
          rej("");
        });
    });
  },

  updateHostingCountry(
    hostingId: string,
    hostingData: HostingCountry
  ): Promise<any> {
    return new Promise((res, rej) => {
      const hostingDataDoc = doc(firestore, "hosting_countries", hostingId);

      updateDoc(hostingDataDoc, {
        ...hostingData,
      })
        .then(() => {
          res("");
        })
        .catch((e) => {
          console.log(e);
          rej("");
        });
    });
  },

  deleteHostingCountry(hostingId: string): Promise<any> {
    return new Promise((res, rej) => {
      const hostingDataDoc = doc(firestore, "hosting_countries", hostingId);

      deleteDoc(hostingDataDoc)
        .then(() => {
          res("");
        })
        .catch((e) => {
          console.log(e);
          rej("");
        });
    });
  },
};
export default hostingCountryServices;
