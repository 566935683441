import { Machine } from "../../types";
import { auth, functions, firestore } from "../app/firebase-service";

import {
  doc,
  onSnapshot,
  updateDoc,
  collection,
  addDoc,
  getDocs,
  where,
  query,
  deleteDoc,
  getDoc,
} from "firebase/firestore";

const machinesServices = {
  // SignUp Service

  listentToMachines(listenerCallback: Function) {
    const machinesCollection = collection(firestore, "products");
    const machinesListener = onSnapshot(
      machinesCollection,
      async (machinesSnap) => {
        const machines: Machine[] = [];
        if (!machinesSnap.empty) {
          for (const machine of machinesSnap.docs) {
            machines.push({
              id: machine.id,
              ...(machine.data() as Machine),
            });
          }
        }
        listenerCallback(machines);
      }
    );
    return machinesListener;
  },

  createMachine(machineData: Machine): Promise<any> {
    return new Promise((res, rej) => {
      const machineDataDoc = collection(firestore, "products");
      if (machineData.live) {
        getDocs(machineDataDoc)
          .then((snaps) => {
            snaps.forEach((document) => {
              if (document.data().live) {
                const machineDocument = doc(firestore, "products", document.id);
                updateDoc(machineDocument, { live: false });
              }
            });
          })
          .catch((err) => {
            console.log(err);
            rej("");
          });
      }
      addDoc(machineDataDoc, {
        ...machineData,
      })
        .then(() => {
          res("");
        })
        .catch((e) => {
          console.log(e);
          rej("");
        });
    });
  },
  getMachine(id: string): Promise<Machine> {
    return new Promise((res, rej) => {
      const machineDoc = doc(firestore, "products", id);
      getDoc(machineDoc)
        .then((machineSnap) => {
          if (machineSnap.exists()) {
            res({
              id: machineSnap.id,
              ...(machineSnap.data() as Machine),
            });
          } else {
            rej();
          }
        })
        .catch((e) => {
          console.log(e);
          rej("");
        });
    });
  },
  updateMachine(machineId: string, machineData: Machine): Promise<any> {
    return new Promise((res, rej) => {
      const machineDataDoc = doc(firestore, "products", machineId);
      const machineDataCollection = collection(firestore, "products");

      getDocs(machineDataCollection)
        .then(async (snaps) => {
          if (machineData.live) {
            for (const document of snaps.docs) {
              if (document.data().live && document.id !== machineId) {
                const machineDocument = doc(firestore, "products", document.id);
                await updateDoc(machineDocument, { live: false });
              }
            }
          }
          return updateDoc(machineDataDoc, {
            ...machineData,
          });
        })
        .then(() => {
          res("");
        })
        .catch((err) => {
          console.log(err);
          rej("");
        });
    });
  },

  deleteMachine(machineId: string): Promise<any> {
    return new Promise((res, rej) => {
      const machineDataDoc = doc(firestore, "products", machineId);

      deleteDoc(machineDataDoc)
        .then(() => {
          res("");
        })
        .catch((e) => {
          console.log(e);
          rej("");
        });
    });
  },
};
export default machinesServices;
