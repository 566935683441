// React
import React, { useEffect, useState, PropsWithChildren } from "react";

// Services
import { auth } from "../services/app/firebase-service";
import { UserData, WalletAddress } from "../types";
import authServices from "../services/apis/auth-service";
import { Timestamp, serverTimestamp } from "firebase/firestore";
import i18n from "../translations/i18n";
import AsyncStorage from "@react-native-async-storage/async-storage";
// Auth states
export enum AuthStates {
  "AUTH",
  "NOT_AUTH",
  "NOT_INIT",
}

const AuthContext = React.createContext({
  authState: AuthStates.NOT_INIT,
  isAdmin: false,
  userId: "",
  userEmail: "",
  userData: {} as UserData,
  activeWallet: {} as WalletAddress,
  walletAddresses: [] as WalletAddress[],
  userCreationTime: "",
  userLastSignInTime: "",
  isLoading: false,
  payoutThreshold: 0.005,
  lang: "fr",
  initUserData: () => {},
  signOut: async (): Promise<void> => {},
  getTOTP: async (): Promise<any> => {},
  verifyTOTP: async (token: string): Promise<any> => {},
  deleteUser: async (): Promise<void> => {},
  langHandler: (lang: string) => {},
});

export const AuthContextProvider: React.FC<PropsWithChildren> = (
  props: any
) => {
  const [authState, setAuthState] = useState(AuthStates.NOT_INIT);
  const [isAdmin, setIsAdmin] = useState(false);
  const [userId, setUserId] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [payoutThreshold, setpayoutThreshold] = useState(0.005);
  const [userData, setUserData] = useState<UserData>({} as UserData);
  const [lang, setLang] = useState("fr");

  const [activeWallet, setActiveWallet] = useState<WalletAddress | null>(null);
  const [walletAddresses, setWalletAddresses] = useState<WalletAddress[]>([]);
  const [userCreationTime, setUserCreationTime] = useState("");
  const [userLastSignInTime, setUserLastSignInTime] = useState("");
  const [key, setKey] = useState(Math.random());
  const [rtListenersList, setRtListenersList] = useState([] as Function[]);

  const langHandler = (newLang: string) => {
    setLang(newLang);
    AsyncStorage.setItem("locale", newLang);
  };
  const getTOTP = async () => {
    setisLoading(true);
    try {
      const otpauth = await authServices.getTOTP();
      setisLoading(false);
      return otpauth;
    } catch (err) {
      console.log(err);
      setisLoading(false);
      return null;
    }
  };

  useEffect(() => {
    i18n.locale = lang;
    setKey(Math.random());
  }, [lang]);

  const verifyTOTP = async (token: string) => {
    setisLoading(true);
    try {
      const isValid = await authServices.verifyTOTP(token);
      setisLoading(false);
      return isValid;
    } catch (err) {
      setisLoading(false);
      console.log(err);
      return null;
    }
  };

  const deleteUser = async () => {
    setisLoading(true);
    try {
      await authServices.deleteUserAccount();
      setisLoading(false);
    } catch (err) {
      console.log(err);
      setisLoading(false);
    }
  };
  const initUserData = () => {
    const userDataListener: Function = authServices.listenToUserData(
      userId,
      (userData: UserData) => setUserData(userData)
    );

    const walletAddressesListener: Function =
      authServices.listenToWalletAddresses(
        userId,
        (walletAddresses: WalletAddress[]) =>
          setWalletAddresses(walletAddresses)
      );

    rtListenersList.map((listener: Function) => listener());
    setRtListenersList([userDataListener, walletAddressesListener]);
  };

  useEffect(() => {
    const authStateChangedUnsubscribe = auth.onAuthStateChanged((user) => {
      // unset user info if no user or logged out
      if (!user) {
        setAuthState(AuthStates.NOT_AUTH);
        setIsAdmin(false);
        setUserId("");
        setUserEmail("");
        setUserLastSignInTime("");
        setUserCreationTime("");
      } else {
        // Set user info if user is logged in
        console.log(user);
        user.getIdTokenResult().then((token) => {
          setAuthState(AuthStates.AUTH);
          setIsAdmin(token.claims.admin);
          setUserId(user.uid);
          setUserEmail(user.email || "");
          setUserLastSignInTime(user.metadata.lastSignInTime);
          setUserCreationTime(user.metadata.creationTime);
        });
      }
    });
    AsyncStorage.getItem("locale").then((storedLocale) => {
      console.log(storedLocale);
      if (storedLocale) {
        console.log(storedLocale);
        setLang(storedLocale);
      }
    });
    // Unsubscribe to listener when unmounted
    return () => authStateChangedUnsubscribe();
  }, []);

  useEffect(() => {
    // Set active wallet address (most recet one)
    if (walletAddresses.length) {
      setActiveWallet(
        walletAddresses.reduce((prev, current) => {
          if (prev.activeAt && current.activeAt) {
            return prev.activeAt.seconds > current.activeAt.seconds
              ? prev
              : current;
          }
          return !prev.activeAt ? prev : !current.activeAt ? current : null;
        })
      );
    }
  }, [walletAddresses]);

  const signOutHandler = async () => {
    setisLoading(true);
    try {
      await auth.signOut();
      setisLoading(false);
    } catch (err) {
      console.log(err);
      setisLoading(false);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        authState,
        isAdmin,
        userId,
        userEmail,
        userData,
        activeWallet,
        walletAddresses,
        userCreationTime,
        userLastSignInTime,
        isLoading,
        payoutThreshold,
        lang,
        initUserData,
        signOut: signOutHandler,
        getTOTP,
        verifyTOTP,
        deleteUser,
        langHandler,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
