import {
  PromoCode,
  PromoCodeData,
  User,
  UserData,
  Order,
  OrderData,
  AbandonedCart,
} from "../../types";
import { auth, functions, firestore } from "../app/firebase-service";

import {
  doc,
  onSnapshot,
  updateDoc,
  collection,
  addDoc,
  getDocs,
  where,
  query,
  deleteDoc,
  orderBy,
  getDoc,
  Query,
  DocumentData,
  startAt,
  endAt,
  limit,
} from "firebase/firestore";

const adminServices = {
  // SignUp Service

  listentToPromoCode(listenerCallback: Function) {
    const promoCodeCollection = collection(firestore, "promo_code");
    const promoCodeListener = onSnapshot(
      promoCodeCollection,
      async (promoCodeSnap) => {
        const promoCodes: PromoCode[] = [];
        if (!promoCodeSnap.empty) {
          for (const promoCode of promoCodeSnap.docs) {
            promoCodes.push({
              id: promoCode.id,
              ...(promoCode.data() as PromoCode),
            });
          }
        }
        listenerCallback(promoCodes);
      }
    );
    return promoCodeListener;
  },
  updateUserData(user: User): Promise<void> {
    return new Promise((res, rej) => {
      const userDoc = doc(firestore, "users", user.id);
      updateDoc(userDoc, { ...user })
        .then((result) => {
          res();
        })
        .catch((e) => {
          console.log(e);
          rej(e);
        });
    });
  },
  getUser(id: string): Promise<User> {
    return new Promise((res, rej) => {
      const userDoc = doc(firestore, "users", id);
      getDoc(userDoc)
        .then((userSNap) => {
          if (userSNap.exists()) {
            res({
              id: userSNap.id,
              ...(userSNap.data() as User),
            });
          } else {
            rej();
          }
        })
        .catch((e) => {
          console.log(e);
          rej("");
        });
    });
  },
  deleteUser(id: string): Promise<void> {
    return new Promise((res, rej) => {
      const userDoc = doc(firestore, "users", id);
      deleteDoc(userDoc)
        .then((userSNap) => {
          res();
        })
        .catch((e) => {
          console.log(e);
          rej("");
        });
    });
  },
  listentToUsers(
    listenerCallback: Function,
    orderByField: string = "firstname",
    searchValue?: string
  ) {
    const usersCollection = collection(firestore, "users");
    let q: Query<DocumentData>;
    if (searchValue) {
      q = query(
        usersCollection,
        orderBy(orderByField),
        startAt(searchValue),
        endAt(searchValue + "\uf8ff")
      );
    } else {
      q = query(usersCollection, orderBy(orderByField));
    }
    const usersListener = onSnapshot(q, async (userSnap) => {
      const users: User[] = [];
      if (!userSnap.empty) {
        for (const user of userSnap.docs) {
          users.push({
            id: user.id,
            ...(user.data() as User),
          });
        }
      }
      listenerCallback(users);
    });
    return usersListener;
  },

  getUserOrders(userId: string): Promise<Order[]> {
    return new Promise((res, rej) => {
      const orderCollection = collection(firestore, "orders");
      const q = query(orderCollection, where("userId", "==", userId));

      getDocs(q)
        .then((ordersSnap) => {
          const orders: Order[] = [];
          if (!ordersSnap.empty) {
            for (const order of ordersSnap.docs) {
              orders.push({
                id: order.id,
                ...(order.data() as Order),
              });
            }
          }
          res(orders);
        })
        .catch((e) => {
          console.log(e);
          rej("");
        });
    });
  },
  createPromoCode(promoCodeData: PromoCodeData): Promise<any> {
    return new Promise((res, rej) => {
      const promoCodeCollection = collection(firestore, "promo_code");
      const q = query(
        promoCodeCollection,
        where("code", "==", promoCodeData.code)
      );

      getDocs(q)
        .then((promoCodeSnaps) => {
          if (promoCodeSnaps.empty) {
            return addDoc(promoCodeCollection, {
              ...promoCodeData,
            });
          } else {
            rej("Error");
          }
        })

        .then(() => {
          res("");
        })
        .catch((e) => {
          console.log(e);
          rej("");
        });
    });
  },

  updatePromoCode(
    promoCodeId: string,
    promoCodeData: PromoCodeData
  ): Promise<any> {
    return new Promise((res, rej) => {
      const machineDataDoc = doc(firestore, "promo_code", promoCodeId);

      updateDoc(machineDataDoc, {
        ...promoCodeData,
      })
        .then(() => {
          res("");
        })
        .catch((e) => {
          console.log(e);
          rej("");
        });
    });
  },

  updateOrder(orderId: string, orderData: any): Promise<any> {
    return new Promise((res, rej) => {
      const orderDoc = doc(firestore, "orders", orderId);

      updateDoc(orderDoc, {
        ...orderData,
      })
        .then(() => {
          res("");
        })
        .catch((e) => {
          console.log(e);
          rej("");
        });
    });
  },
  createOrder(orderData: OrderData): Promise<any> {
    return new Promise((res, rej) => {
      const orderCollection = collection(firestore, "orders");
      const q = query(
        orderCollection,
        orderBy("orderNumber", "desc"),
        limit(1)
      );
      getDocs(q)
        .then((latestOrder) => {
          if (!latestOrder.empty) {
            const latestOrderNumber: number =
              latestOrder.docs[0].data()?.orderNumber;
            return addDoc(orderCollection, {
              ...orderData,
              orderNumber: latestOrderNumber + 1,
              orderNumberStr: (latestOrderNumber + 1)?.toString(),
            });
          }
        })
        .then(() => {
          res("");
        })
        .catch((e) => {
          console.log(e);
          rej("");
        });
    });
  },
  getOrder(id: string): Promise<Order> {
    return new Promise((res, rej) => {
      const orderDoc = doc(firestore, "orders", id);
      getDoc(orderDoc)
        .then((orderSnap) => {
          if (orderSnap.exists()) {
            res({
              id: orderSnap.id,
              ...(orderSnap.data() as Order),
            });
          } else {
            rej();
          }
        })
        .catch((e) => {
          console.log(e);
          rej("");
        });
    });
  },
  getAbandonedCart(id: string): Promise<AbandonedCart> {
    return new Promise((res, rej) => {
      const abandonedCartDoc = doc(firestore, "abandoned_cart", id);
      getDoc(abandonedCartDoc)
        .then((abandonedCartSnap) => {
          if (abandonedCartSnap.exists()) {
            res({
              id: abandonedCartSnap.id,
              ...(abandonedCartSnap.data() as AbandonedCart),
            });
          } else {
            rej();
          }
        })
        .catch((e) => {
          console.log(e);
          rej("");
        });
    });
  },

  deletePromoCode(promoCodeId: string): Promise<any> {
    return new Promise((res, rej) => {
      const promoCodeDoc = doc(firestore, "promo_code", promoCodeId);

      deleteDoc(promoCodeDoc)
        .then(() => {
          res("");
        })
        .catch((e) => {
          console.log(e);
          rej("");
        });
    });
  },
};
export default adminServices;
