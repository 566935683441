import config from "../../configs/firebase-service.config";
import { initializeApp } from "firebase/app";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import "firebase/analytics";
import buildEnvConfig from "../../build.env.json";
import { getAuth, connectAuthEmulator, initializeAuth } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getReactNativePersistence } from "firebase/auth/react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";

const emulators = {
  auth: {
    host: "localhost",
    port: 9099,
  },
  functions: {
    host: "localhost",
    port: 5001,
  },
  firestore: {
    host: "localhost",
    port: 8080,
  },
  storage: {
    host: "localhost",
    port: 9199,
  },
};

const firebaseConfig = config();

const app = initializeApp(firebaseConfig);

const firestore = getFirestore(app);
const functions = getFunctions(app);

const storage = getStorage(app);
const auth = initializeAuth(app, {
  persistence: getReactNativePersistence(AsyncStorage),
});

if (buildEnvConfig.BUILD_ENV == "EMU") {
  connectFirestoreEmulator(
    firestore,
    emulators.firestore.host,
    emulators.firestore.port
  );
  connectFunctionsEmulator(
    functions,
    emulators.functions.host,
    emulators.functions.port
  );
  connectStorageEmulator(
    storage,
    emulators.storage.host,
    emulators.storage.port
  );
  connectAuthEmulator(
    auth,
    `http://${emulators.auth.host}:${emulators.auth.port}`
  );
}

export { firestore, functions, auth, storage };
