// React
import React, { useEffect, useState, PropsWithChildren } from "react";

import { User, Order, Workers, UserData } from "../types";
import adminServices from "../services/apis/admin-service";
import cruxPoolServices from "../services/apis/cruxpool-service";
const UsersContext = React.createContext({
  users: null as User[] | null,
  orderByField: "firstname",
  currentClient: undefined as undefined | User,
  isLoadingOrders: false,
  isLoading: false,
  orders: null as Order[] | null,
  workers: null as Workers[] | null,
  totalPrice: 0,
  totalMachine: 0,
  searchValue: "",
  affiSelectedOrders: null as Order[] | null,
  orderByFieldHandler: (field: "firstname" | "lastname") => {},
  currentClientHandler: (user: User | undefined) => {},
  initUsersData: () => {},
  getUserData: (id: string) => {},
  updateUser: (user: User) => {},
  searchValueHandler: (value: string) => {},
  affiSelectedOrdersHandler: (orders: Order[]) => {},
  deleteUser: (userId: string) => {},
});

export const UsersContextProvider: React.FC<PropsWithChildren> = (
  props: any
) => {
  const [users, setUsers] = useState<User[] | null>(null);
  const [orders, setOrders] = useState<Order[] | null>(null);
  const [affiSelectedOrders, setAffiSelectedOrders] = useState<Order[] | null>(
    null
  );
  const [workers, setWorkers] = useState<Workers[] | null>(null);
  const [searchValue, setSearchValue] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalMachine, setTotalMachine] = useState(0);
  const [isLoadingOrders, setIsLoadingOrders] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentClient, setCurrentClient] = useState<User | undefined>();
  const [orderByField, setOrderByField] = useState<"firstname" | "lastname">(
    "firstname"
  );
  const [rtListenersList, setRtListenersList] = useState([] as Function[]);

  const searchValueHandler = (value: string) => {
    setSearchValue(value);
  };
  const affiSelectedOrdersHandler = (orders: Order[]) => {
    setAffiSelectedOrders(orders);
  };
  const initUsersData = () => {
    const usersListener: Function = adminServices.listentToUsers(
      (users: User[]) => setUsers(users),
      orderByField,
      searchValue
    );

    rtListenersList.map((listener: Function) => listener());
    setRtListenersList([usersListener]);
  };

  const getUserData = async (id: string) => {
    const user = await adminServices.getUser(id);
    currentClientHandler(user);
  };
  useEffect(() => {
    const usersListener: Function = adminServices.listentToUsers(
      (users: User[]) => setUsers(users),
      orderByField,
      searchValue
    );

    rtListenersList.map((listener: Function) => listener());
    setRtListenersList([usersListener]);
  }, [orderByField, searchValue]);
  const orderByFieldHandler = (field: "firstname" | "lastname") => {
    setOrderByField(field);
  };
  const currentClientHandler = (user: User | undefined) => {
    setCurrentClient(user);
  };
  const updateUser = async (user: UserData) => {
    try {
      setIsLoading(true);
      await adminServices.updateUserData({
        id: currentClient.id,
        ...(user as UserData),
      });
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };
  const getAndSetWorker = async () => {
    try {
      const workers = await cruxPoolServices.getMinerWorkers(
        currentClient?.walletAddress
      );

      setWorkers(workers);
    } catch (err) {
      console.log(err);
      setWorkers([]);
    }
  };

  const deleteUser = async (userId: string) => {
    setIsLoadingOrders(true);
    await adminServices.deleteUser(userId);
    setIsLoadingOrders(false);
  };
  useEffect(() => {
    if (currentClient) {
      setIsLoadingOrders(true);
      adminServices
        .getUserOrders(currentClient.id)
        .then((newOrders) => {
          setOrders(newOrders);
          setIsLoadingOrders(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoadingOrders(false);
        });
      getAndSetWorker();
    }
  }, [currentClient]);

  useEffect(() => {
    if (orders && orders.length) {
      const newtotalPrice = orders.reduce(
        (acc, order) => acc + order.totalPrice,
        0
      );
      const newtotalMachine = orders.reduce(
        (acc, order) => acc + order.machineAmount,
        0
      );
      setTotalPrice(newtotalPrice);
      setTotalMachine(newtotalMachine);
    }
  }, [orders]);
  return (
    <UsersContext.Provider
      value={{
        users,
        orderByField,
        currentClient,
        isLoadingOrders,
        orders,
        workers,
        totalPrice,
        totalMachine,
        isLoading,
        searchValue,
        affiSelectedOrders,
        orderByFieldHandler,
        currentClientHandler,
        initUsersData,
        getUserData,
        updateUser,
        searchValueHandler,
        affiSelectedOrdersHandler,
        deleteUser,
      }}
    >
      {props.children}
    </UsersContext.Provider>
  );
};

export default UsersContext;
