import React from "react";
import { View, Image } from "react-native";
import BaseText from "../../Base/BaseText/BaseText";

import useIsMobile from "../../../utils/useIsMobile";

const ResumeItem: React.FC<{
  style?: any;
  machineAmountToBuy: number;
  machineName: string;
  imageUrl?: any;
  total: number;
  corporationCare?: boolean;
}> = ({
  style,
  machineAmountToBuy,
  machineName,
  imageUrl,
  total,
  corporationCare,
}) => {
  const isMobile = useIsMobile();
  return (
    <View
      style={
        isMobile
          ? {
              alignItems: "center",
              flexDirection: "row-reverse",
              ...style,
            }
          : {
              flexDirection: "row",
              flex: 0.5,
              alignItems: "center",
              ...style,
            }
      }
    >
      <View
        style={
          isMobile ? { width: 131, height: 88 } : { width: 192, height: 128 }
        }
      >
        <Image
          resizeMode="cover"
          source={
            imageUrl ||
            "https://firebasestorage.googleapis.com/v0/b/corporations-fd072.appspot.com/o/products_image%2Fasic-min.png?alt=media"
          }
          style={
            isMobile ? { width: 131, height: 88 } : { width: 192, height: 128 }
          }
        />
      </View>
      <View
        style={
          isMobile ? { marginVertical: 10, flex: 1 } : { marginLeft: "3%" }
        }
      >
        <BaseText
          text="Résumé de mon investissement"
          bold={isMobile}
          style={{
            fontSize: isMobile ? 16 : 22,
            padding: 0,
            paddingVertical: 2,
            fontWeight: "400",
          }}
        />
        <BaseText
          bold
          text={`${total} €`}
          style={{
            fontSize: isMobile ? 20 : 24,
            padding: 0,
            paddingVertical: 2,
            color: "#1ABBFE",
          }}
        />
        <BaseText
          text={`X${machineAmountToBuy} ${machineName}`}
          style={{
            fontSize: isMobile ? 12 : 22,
            padding: 0,
            fontWeight: "400",
            textTransform: isMobile ? "uppercase" : "none",
            paddingVertical: 2,
          }}
        />
        {corporationCare ? (
          <BaseText
            text={`CoporationsCare inclus`}
            style={{
              fontSize: isMobile ? 12 : 16,
              padding: 0,
              fontWeight: "400",
              textTransform: isMobile ? "uppercase" : "none",
              paddingVertical: 2,
            }}
          />
        ) : null}
      </View>
    </View>
  );
};

export default ResumeItem;
