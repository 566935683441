import React, { PropsWithChildren, useContext } from "react";
import { ScrollView, StyleSheet, View, Image, ViewStyle } from "react-native";
import Header from "../Header/Header";
import routes from "../../../routes";
import { useRoute } from "@react-navigation/native";
import colors from "../../../configs/colors";
import { StackNavigationProp } from "@react-navigation/stack";
import useIsMobile, { useDimensions } from "../../../utils/useIsMobile";
import NewOrderContext from "../../../contexts/new-order.ctx";

const sellSteps = [
  "Offre",
  "Options",
  "Identification",
  "Paiement",
  "Livraison",
];

const SFContainer: React.FC<
  PropsWithChildren & {
    navigation: StackNavigationProp<any>;
    bg?: any;
    rightLogo?: any;
    orderSuccessLogo?: any;
    hideMobileHeader?: boolean;
    style?: ViewStyle;
    fixedComponent?: React.ReactNode;
  }
> = (props) => {
  const route = useRoute();
  const isMobile = useIsMobile();
  const dimensions = useDimensions();
  const routeIndex =
    route.name === routes.salesFunnel.screens.salesInvestingAmount.screenName
      ? 0
      : route.name ===
          routes.salesFunnel.screens.salesMachineSelection.screenName ||
        route.name ===
          routes.salesFunnel.screens.salesOptionsSelection.screenName ||
        route.name === routes.salesFunnel.screens.salesDolphin.screenName
      ? 1
      : route.name === routes.signin.screenName ||
        route.name === routes.signup.screenName ||
        route.name === routes.accounType.screenName
      ? 3
      : 4;
  return (
    <View
      style={{
        height: isMobile ? dimensions.height : "100%",
        justifyContent: "center",

        ...props.style,
      }}
    >
      <ScrollView
        contentContainerStyle={
          isMobile ? { flexGrow: 1 } : styles.scrollContainer
        }
        style={isMobile ? {} : styles.container}
      >
        <Header
          currentStep={routeIndex}
          steps={sellSteps}
          goBack={() => {
            props.navigation.goBack();
          }}
          hideMobileStep={props.hideMobileHeader}
        />
        <View>{props.children}</View>
        <Image
          resizeMode="cover"
          source={
            props.bg
              ? props.bg
              : require("../../../../assets/sales_funnel_bg-min.png")
          }
          style={styles.gradientBackground}
        />
        {props.rightLogo ? (
          <Image
            resizeMode="cover"
            source={props.rightLogo}
            style={isMobile ? styles.rightLogoMobile : styles.rightLogo}
          />
        ) : null}
        {props.orderSuccessLogo ? (
          <Image
            resizeMode="cover"
            source={props.orderSuccessLogo}
            style={
              isMobile ? styles.orderSuccessLogoMobile : styles.orderSuccessLogo
            }
          />
        ) : null}
      </ScrollView>
      {props.fixedComponent}
    </View>
  );
};

export default SFContainer;

const styles = StyleSheet.create({
  scrollContainer: {
    flexGrow: 1,
    backgroundColor: colors.black,
  },
  container: {
    flex: 1,
  },

  gradientBackground: {
    position: "absolute",
    right: 0,
    bottom: 0,
    flex: 1,
    height: "100%",
    width: "100%",
    zIndex: -1,
  },
  rightLogoMobile: {
    position: "absolute",
    right: 0,
    bottom: 0,
    width: 421,
    height: 308,
    zIndex: -2,
  },
  rightLogo: {
    position: "absolute",
    right: 0,
    bottom: 0,
    width: 842,
    height: 616,
    zIndex: -2,
  },
  orderSuccessLogo: {
    position: "absolute",
    right: 0,
    bottom: 0,
    width: 1034,
    height: 852,
    zIndex: -1,
  },
  orderSuccessLogoMobile: {
    width: 344,
    height: 284,
    zIndex: -1,
  },
});
