const routes = {
  signup: {
    screenName: "Register",
    screenUrl: "signup",
  },
  signupNoSF: {
    screenName: "Register No SF",
    screenUrl: "signup-no-sf",
  },
  signin: {
    screenName: "Login",
    screenUrl: "signin",
  },
  accounType: {
    screenName: "Account Type",
    screenUrl: "choose-account",
  },
  sendResetPassword: {
    screenName: "Send Reset Password Email",
    screenUrl: "send-reset-password-email",
  },
  resetPassword: {
    screenName: "Reset Password",
    screenUrl: "reset-password",
  },
  drawer: {
    screenName: "App",
    screenUrl: "app",
    screens: {
      dashboard: {
        screenName: "Dashboard",
        screenUrl: "dashboard",
      },
      admin: {
        screenName: "Admin",
        screenUrl: "admin",
      },
      settings: {
        screenName: "Settings",
        screenUrl: "settings",
      },
      statistics: {
        screenName: "Statistics",
        screenUrl: "statistics",
      },
      earnings: {
        screenName: "Earnings",
        screenUrl: "earnings",
      },
      orders: {
        screenName: "Orders",
        screenUrl: "orders",
      },
      live: {
        screenName: "Live",
        screenUrl: "live",
      },
      support: {
        screenName: "Support",
        screenUrl: "support",
      },
    },
  },
  drawerAdmin: {
    screenName: "Admin",
    screenUrl: "admin",
    screens: {
      product: {
        screenName: "Product",
        screenUrl: "product",
      },
      authorizations: {
        screenName: "Authorizations",
        screenUrl: "authorizations",
      },
      adminStatistics: {
        screenName: "Admin Statistics",
        screenUrl: "statistics",
      },
      clients: {
        screenName: "Clients",
        screenUrl: "clients",
        screens: {
          clientList: {
            screenName: "Clients List",
            screenUrl: "client-list",
          },
          singleClient: {
            screenName: "Single Client",
            screenUrl: "single-client/:id",
          },
        },
      },
      adminOrders: {
        screenName: "Admin Orders",
        screenUrl: "admin-orders",
        screens: {
          orderList: {
            screenName: "Orders List",
            screenUrl: "order-list",
          },
          singleOrder: {
            screenName: "Single Order",
            screenUrl: "single-order/:id",
          },
          abandonedCartList: {
            screenName: "Abandoned Cart List",
            screenUrl: "abandoned-cart-list",
          },
          singleAbandonedCart: {
            screenName: "Single Abandoned Cart",
            screenUrl: "single-abandoned-cart/:id",
          },
        },
      },
      electricity: {
        screenName: "Electricity",
        screenUrl: "electricity",
      },
      invoice: {
        screenName: "Invoice",
        screenUrl: "invoice",
        screens: {
          allInvoices: {
            screenName: "Invoices",
            screenUrl: "invoices",
          },
          elecInvoice: {
            screenName: "Electricity Invoices",
            screenUrl: "elec-invoices",
          },
          machineInvoice: {
            screenName: "Machine Invoices",
            screenUrl: "machine-invoices",
          },
        },
      },
    },
  },

  salesFunnel: {
    screenName: "Sales Funnel",
    screenUrl: "sales-funnel",
    screens: {
      salesInvestingAmount: {
        screenName: "Investing Amount",
        screenUrl: "investing-amount",
      },
      salesDolphin: {
        screenName: "Program",
        screenUrl: "program-signup",
      },
      salesMachineSelection: {
        screenName: "Machine selection",
        screenUrl: "machine-selection",
      },
      salesOptionsSelection: {
        screenName: "Options Selections",
        screenUrl: "options-selection",
      },
      salesSignIn: {
        screenName: "Login",
        screenUrl: "signin",
      },
      salesCGV: {
        screenName: "CGV",
        screenUrl: "cgv",
      },
      salesOrderSuccess: {
        screenName: "Order success",
        screenUrl: "order-success",
      },
      salesOrderError: {
        screenName: "Order error",
        screenUrl: "order-error",
      },
      salesPaymentMethod: {
        screenName: "Payment Method",
        screenUrl: "payment-method",
      },
    },
  },
};

export default routes;
