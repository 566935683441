import {
  Alert,
  SignUpWithMailReq,
  SignUpWithProviderReq,
  SignUpRes,
  FunctionalAlert,
  UserData,
  MinerEstEarnings,
  MinerHashrate,
  MinerHashrateHistory,
  MinerPayments,
  MinerHistory,
} from "../../types";

import errors from "../../messages/error";
import success from "../../messages/success";

import axios from "axios";

const CRUX_API_URL = "https://www.cruxpool.com/api/btc";
const CRUX_APIV2_URL = "https://api.cruxpool.com/api/v2/btc";
const api = axios.create({
  baseURL: CRUX_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const apiV2 = axios.create({
  baseURL: CRUX_APIV2_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const cruxPoolServices = {
  getEstimatedEarnings(hashrate: number): Promise<MinerEstEarnings> {
    return new Promise((res, rej) => {
      api
        .get(`/estimatedEarnings/${hashrate}`)
        .then((response) => {
          res(response.data.data);
        })
        .catch((err) => {
          rej(err.response.data);
        });
    });
  },

  getMinerBalance(minerId: string): Promise<string> {
    return new Promise((res, rej) => {
      api
        .get(`/miner/${minerId}/balance`)
        .then((response) => {
          if (response.data.data) {
            res(response.data.data.balance);
          }
        })
        .catch((err) => {
          rej(err.response.data);
        });
    });
  },

  getMinerHashrate(minerId: string): Promise<MinerHashrate> {
    return new Promise((res, rej) => {
      api
        .get(`/miner/${minerId}/hashrate`)
        .then((response) => {
          res(response.data.data);
        })
        .catch((err) => {
          rej(err.response.data);
        });
    });
  },

  getMinerHashrateHistoryMonth(minerId: string): Promise<MinerHashrateHistory> {
    return new Promise((res, rej) => {
      api
        .get(`/miner/${minerId}/history/month`)
        .then((response) => {
          res(response.data.data.history);
        })
        .catch((err) => {
          rej(err.response.data);
        });
    });
  },
  getMinerHashrateHistoryDay(minerId: string): Promise<MinerHashrateHistory> {
    return new Promise((res, rej) => {
      api
        .get(`/miner/${minerId}/history/day`)
        .then((response) => {
          res(response.data.data.history);
        })
        .catch((err) => {
          rej(err.response.data);
        });
    });
  },
  getMinerPayments(minerId: string): Promise<MinerPayments> {
    return new Promise((res, rej) => {
      api
        .get(`/miner/${minerId}/payments`)
        .then((response) => {
          res(response.data.data.payments);
        })
        .catch((err) => {
          rej(err.response.data);
        });
    });
  },
  getMinerWorkers(minerId: string): Promise<any> {
    return new Promise((res, rej) => {
      api
        .get(`/miner/${minerId}/workers`)
        .then((response) => {
          let workers = [];
          if (response.data?.data?.workers) {
            for (const name of Object.keys(response.data?.data?.workers)) {
              workers.push({
                name: name,
                ...response.data?.data?.workers[name],
              });
            }
          }
          res(workers);
        })
        .catch((err) => {
          rej(err.response.data);
        });
    });
  },
  getMinerHistory(
    minerId: string,
    startDate: string,
    endDate: string,
    type: string
  ): Promise<MinerHistory[]> {
    return new Promise((res, rej) => {
      apiV2
        .get(
          `/addresses/${minerId}/mining/history?start=${startDate}&end=${endDate}&type=${type}`
        )
        .then((response) => {
          res(response.data);
        })
        .catch((err) => {
          rej(err.response.data);
        });
    });
  },
  // getMinerWorkerHistory(minerId: string,workerName: string): Promise<any> {
  //   return new Promise((res, rej) => {
  //     api
  //       .get(`/miner/${minerId}/workers/${name}/history`)
  //       .then((response) => {
  //         let workers = [];
  //         if (response.data?.data?.workers) {
  //           for (const name of Object.keys(response.data?.data?.workers)) {
  //             workers.push({
  //               name: name,
  //               ...response.data?.data?.workers[name],
  //             });
  //           }
  //         }
  //         res(workers);
  //       })
  //       .catch((err) => {
  //         rej(err.response.data);
  //       });
  //   });
  // },
};

export default cruxPoolServices;
