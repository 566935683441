import React from "react";
import { Text, TextProps, StyleSheet } from "react-native";
import colors from "../../../../configs/colors";
export interface BaseTextProps extends TextProps {
  text?: string | React.ReactNode;
  bold?: boolean;
}
const BaseText: React.FC<BaseTextProps> = (props: BaseTextProps) => {
  return (
    <Text
      numberOfLines={6}
      ellipsizeMode="tail"
      {...props}
      style={[
        styles.text,
        props.style,
        { fontFamily: props.bold ? "PoppinsBold" : "PoppinsRegular" },
      ]}
    >
      {props.text}
    </Text>
  );
};

const styles = StyleSheet.create({
  text: {
    fontSize: 16,
    color: colors.white,
    padding: 6,
    fontFamily: "Inter",
    userSelect: "text",
  },
});

export default BaseText;
