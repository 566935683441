import React, { CSSProperties, useState } from "react";
import {
  StyleSheet,
  TextProps,
  Platform,
  TextStyle,
  View,
  Text as RNText,
} from "react-native";
import MaskedView from "@react-native-masked-view/masked-view";
// import { LinearGradient } from "expo-linear-gradient";
import BaseText from "../BaseText/BaseText";
import { Defs, LinearGradient, Stop, Text, Svg } from "react-native-svg";
interface BaseGradientTextProps extends TextProps {
  text: string;
  fontSize?: number;
  fontFamily?: string;
  margin?: number;
  padding?: number;
  gradient?: string;
  baseColor?: string;
  style?: any;
  width?: number;
  height?: number;
  startGradient?: string;
  endGradient?: string;
}
const BaseGradientText: React.FC<BaseGradientTextProps> = (props) => {
  const [size, setSize] = useState({ width: 0, height: 0 });
  if (Platform.OS == "web") {
    return (
      <span
        style={{
          background: props.gradient
            ? props.gradient
            : "linear-gradient(92.75deg, #A5259D -0.65%, #4890FE 100%), #0A0A0A",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          fontFamily: "PoppinsBold",
          fontSize: props.fontSize || 32,
          padding: props.padding || 0,
          margin: props.margin || 0,
          ...props.style,
        }}
      >
        {props.text}
      </span>
    );
  }
  return (
    <>
      <Svg
        width={
          (props.text.length * (props.fontSize / 1.3))?.toString() || "200"
        }
        height={(props.fontSize * 1.5)?.toString() || "60"}
      >
        <Defs>
          <LinearGradient id="grad" x1="0" y1="0" x2="1" y2="0">
            <Stop
              offset="0"
              stopColor={props.startGradient || "#ff00cc"}
              stopOpacity="1"
            />
            <Stop
              offset="1"
              stopColor={props.endGradient || "#333399"}
              stopOpacity="1"
            />
          </LinearGradient>
        </Defs>
        <Text
          fill="url(#grad)"
          x={
            ((props.text.length * (props.fontSize / 1.3)) / 2)?.toString() ||
            "100"
          }
          y={((props.fontSize * 1.5) / 1.5)?.toString() || "30"}
          fontSize={props.fontSize?.toString() || "32"}
          fontWeight="bold"
          textAnchor="middle"
          fontFamily="PoppinsBold"
        >
          {props.text}
        </Text>
      </Svg>
    </>
  );
};

const styles = StyleSheet.create({
  gradient: { width: 50, height: 50, zIndex: 2 },
  container: { position: "relative", zIndex: 10 },
});

export default BaseGradientText;
