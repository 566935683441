import React, { useState } from "react";
import {
  View,
  ViewStyle,
  TextInputAndroidProps,
  TextInputProps,
  StyleSheet,
} from "react-native";
import { InputSchema } from "../../../types";
import {
  useForm,
  Controller,
  SubmitHandler,
  FieldValues,
} from "react-hook-form";
import { Picker } from "@react-native-picker/picker";

import formRules from "../../../utils/formRules";
import i18n from "../../../translations/i18n";
import BaseInput from "../BaseInput/BaseInput";
import BaseButton from "../BaseButton/BaseButton";
import BaseText from "../BaseText/BaseText";
import colors from "../../../configs/colors";
import Ionicons from "@expo/vector-icons/Ionicons";
import BasePressable from "../BasePressable/BasePressable";
interface BaseFormProps {
  schema: InputSchema[];
  onSubmit: SubmitHandler<FieldValues>;
  buttonTitle: string;
  isLoading: boolean;
  style?: ViewStyle;
  buttonNotFullWidth?: boolean;
  defaultValue?: any;
}

const BaseForm: React.FC<BaseFormProps> = ({
  schema,
  onSubmit,
  buttonTitle,
  isLoading,
  style,
  buttonNotFullWidth,
  defaultValue,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    defaultValues: React.useMemo(() => {
      return defaultValue;
    }, [defaultValue]),
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  React.useEffect(() => {
    reset(defaultValue);
  }, [reset, defaultValue]);

  return (
    <View style={style}>
      {schema.map((input, key) => {
        if (input.type == "section") {
          return (
            <View key={key} style={styles.sectionStyle}>
              <BaseText style={{ fontSize: 20 }} text={i18n.t(input.label)} />
            </View>
          );
        }
        if (input.type == "select") {
          return (
            <Controller
              key={key}
              control={control}
              defaultValue={input.defaultValue}
              name={input.name}
              rules={formRules[input.name]}
              render={({ field: { onChange, onBlur, value } }) => {
                return (
                  <>
                    <BaseText text={i18n.t(input.placeholder)} />
                    <Picker
                      selectedValue={value}
                      onValueChange={(itemValue: any, itemIndex: number) => {
                        onChange(itemValue, itemIndex);
                        input.onChange(itemIndex);
                      }}
                      enabled={!input.notEditable}
                      style={styles.pickerStyle}
                    >
                      {input.selectionsValues.map((value, key) => (
                        <Picker.Item key={key} label={value} value={value} />
                      ))}
                    </Picker>
                    {errors[input.name] && (
                      <BaseText
                        text={errors[input.name].message.toString()}
                        style={{ color: colors.red }}
                      />
                    )}
                    {input.helperText ? (
                      <BaseText
                        style={{ fontSize: 12 }}
                        text={i18n.t(input.helperText)}
                      />
                    ) : null}
                  </>
                );
              }}
            />
          );
        }
        return (
          <Controller
            key={key}
            control={control}
            rules={
              input.name == "confirmPassword"
                ? {
                    validate: (val: string) => {
                      if (watch("password") != val) {
                        return "Your passwords do no match";
                      }
                    },
                  }
                : input.name == "confirmNewEmail"
                ? {
                    validate: (val: string) => {
                      if (watch("newEmail") != val) {
                        return "Your emails do no match";
                      }
                    },
                  }
                : formRules[input.name]
            }
            defaultValue={
              defaultValue && defaultValue[input.name]
                ? defaultValue[input.name]
                : ""
            }
            name={input.name}
            render={({ field: { onChange, onBlur, value } }) => (
              <BaseInput
                onChangeText={onChange}
                onBlur={onBlur}
                value={value}
                label={i18n.t(input.label)}
                autoComplete={
                  input.autoComplete as TextInputAndroidProps["autoComplete"]
                }
                logo={input.logo ? input.logo : null}
                secureTextEntry={
                  input.secureTextEntry ? !showPassword : input.secureTextEntry
                }
                inputMode={input.inputMode as TextInputProps["inputMode"]}
                placeholder={i18n.t(input.placeholder)}
                error={errors[input.name] && errors[input.name].message}
                helpText={input.helperText ? i18n.t(input.helperText) : null}
                editable={!input.notEditable}
                rightLogo={
                  input.name == "confirmPassword" ||
                  input.name == "password" ||
                  input.name == "previousPassword" ? (
                    <BasePressable onClick={handleShowPassword}>
                      <Ionicons
                        name={showPassword ? "eye-outline" : "eye-off-outline"}
                        size={20}
                        color="#fff"
                      />
                    </BasePressable>
                  ) : null
                }
              />
            )}
          />
        );
      })}
      <View style={styles.buttonContainer}>
        <BaseButton
          title={i18n.t(buttonTitle)}
          isLoading={isLoading}
          onPress={handleSubmit(onSubmit)}
          notFullWidth={buttonNotFullWidth}
        />
      </View>
    </View>
  );
};

export default BaseForm;

const styles = StyleSheet.create({
  pickerStyle: {
    borderRadius: 8,
    paddingHorizontal: 16,
    paddingVertical: 10,
    height: 60,
    width: "100%",
    fontSize: 16,
    backgroundColor: colors.dark,
    color: colors.white,
    textDecorationColor: colors.white,
  },
  sectionStyle: {
    paddingVertical: 20,
  },
  buttonContainer: {
    marginVertical: 12,
  },
});
