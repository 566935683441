import React, { useEffect, useState } from "react";
import { View, StyleSheet, ViewStyle, Text } from "react-native";
import BasePressable from "../Base/BasePressable/BasePressable";
import BaseText from "../Base/BaseText/BaseText";
import colors from "../../configs/colors";
import countries from "../../utils/countries";
import Ionicons from "@expo/vector-icons/Ionicons";
import { ScrollView } from "react-native-gesture-handler";
import { TextStyle } from "react-native";
import useIsMobile from "../../utils/useIsMobile";
import BaseInput from "../Base/BaseInput/BaseInput";
interface CountryPickerProps {
  onPress: (data: any) => void;
  label?: string;
  type?: string;
  defaultValue?: string;
  labelStyle?: TextStyle;
  containerStyle?: ViewStyle;
  absoluteViewStyle?: ViewStyle;
  customCountries?: {
    name: string;
    dial_code: string;
    code: string;
    flag: string;
  }[];
  hideSearch?: boolean;
}
const CountryPicker: React.FC<CountryPickerProps> = ({
  onPress,
  label,
  type,
  defaultValue,
  labelStyle,
  containerStyle,
  absoluteViewStyle,
  customCountries,
  hideSearch,
}) => {
  const [isSelectingCountry, setIsSelectingCountry] = useState(false);
  const [country, setCountry] = useState(
    customCountries
      ? customCountries.find((countryInfo) => countryInfo.name == defaultValue)
      : countries.find((countryInfo) => countryInfo.name == defaultValue)
  );

  const [countriesToDisplay, setCountriesToDisplay] = useState(
    customCountries ? customCountries : countries
  );
  const [wordSearching, setWordSearching] = useState("");

  const isMobile = useIsMobile();

  const toggleIsSelecting = () => {
    setIsSelectingCountry(!isSelectingCountry);
  };
  useEffect(() => {
    setCountriesToDisplay(
      customCountries
        ? [...customCountries].filter((countryItem) =>
            countryItem.name.toLowerCase().includes(wordSearching.toLowerCase())
          )
        : [...countries].filter((countryItem) =>
            countryItem.name.toLowerCase().includes(wordSearching.toLowerCase())
          )
    );
  }, [wordSearching]);
  useEffect(() => {
    const defaultCountry = customCountries
      ? customCountries.find((countryInfo) => countryInfo.name == defaultValue)
      : countries.find((countryInfo) => countryInfo.name == defaultValue);

    setCountry(defaultCountry);
  }, [defaultValue]);

  return (
    <View
      style={{
        zIndex: 20,
        marginVertical: isMobile ? 0 : 10,
      }}
    >
      {label ? (
        <BaseText
          style={{
            ...styles.labelText,

            color: isMobile ? "#fff" : "#000",
            ...labelStyle,
          }}
          text={label}
        ></BaseText>
      ) : null}
      <BasePressable
        style={{
          ...styles.container,
          borderWidth: containerStyle ? 1 : isMobile ? 0 : 2,
          borderColor: "#E4E4E4",
          borderRadius: 6,
          // paddingHorizontal: isMobile ? 0 : "2%",
          // paddingVertical: isMobile ? 0 : "1%",

          backgroundColor: colors.white,
          ...containerStyle,
        }}
        onClick={toggleIsSelecting}
      >
        <View
          style={{
            borderRadius: 4,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            padding: 4,
            flex: 1,
            height: 50,
            ...containerStyle,
            borderWidth: 0,
          }}
        >
          {country ? (
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <BaseText
                style={{
                  color: "#4D4D4D",
                  padding: 0,
                  margin: 0,
                  paddingHorizontal: 6,
                  fontSize: 16,
                  fontFamily: "San Francisco",
                  ...labelStyle,
                }}
                text={country.flag}
              />
              {type == "phone" ? (
                <BaseText
                  style={{
                    color: "#4D4D4D",
                    padding: 0,
                    margin: 0,
                    paddingHorizontal: 6,
                    fontSize: 16,
                    ...labelStyle,
                  }}
                  text={country.dial_code}
                />
              ) : (
                <BaseText
                  style={{
                    color: "#4D4D4D",
                    padding: 0,
                    margin: 0,
                    paddingHorizontal: 6,
                    fontSize: 16,
                    ...labelStyle,
                  }}
                  text={country.name}
                />
              )}
            </View>
          ) : (
            <BaseText
              style={{
                color: "#4D4D4D",
                padding: 0,
                margin: 0,
                paddingHorizontal: 0,
                fontSize: isMobile ? 14 : 16,
                paddingLeft: 16,
                ...labelStyle,
              }}
              text={type == "phone" ? "Indicatif" : "Pays"}
            />
          )}

          <Ionicons name={"chevron-down-outline"} size={20} color={"#4D4D4D"} />
        </View>
      </BasePressable>

      {isSelectingCountry ? (
        <View
          style={{
            position: "absolute",
            zIndex: 10,
            elevation: 10,
            left: 0,
            right: 0,
            top: "100%",
            backgroundColor: "#fff",
            padding: "2%",
            borderRadius: 6,
            maxHeight: 180,
            borderWidth: 2,
            borderColor: "#E4E4E4",
            ...containerStyle,
            ...absoluteViewStyle,
          }}
        >
          {hideSearch ? null : (
            <BaseInput
              onChangeText={(value: string) => setWordSearching(value)}
              value={wordSearching}
              inputStyle={{ fontSize: 14, height: 36, ...labelStyle }}
              inputContentStyle={{
                ...containerStyle,
                borderWidth: labelStyle.color ? 1 : 0,
              }}
              leftLogo={
                <Ionicons
                  name={"search-outline"}
                  size={20}
                  color={labelStyle.color || "#fff"}
                  style={{ marginRight: 8 }}
                />
              }
            ></BaseInput>
          )}

          <ScrollView>
            {countriesToDisplay.map((country, index) => (
              <BasePressable
                key={index}
                style={{
                  ...styles.container,
                  justifyContent: "space-between",
                  overflow: "visible",
                  paddingVertical: 4,
                }}
                onClick={() => {
                  onPress(country);
                  setCountry(country);
                  toggleIsSelecting();
                }}
              >
                <View
                  style={{
                    ...styles.container,
                    marginLeft: country.name == "Etats-Unis" ? 3 : 0,
                  }}
                >
                  <BaseText
                    style={{
                      color: "#4D4D4D",
                      paddingVertical: 0,
                      paddingHorizontal: 0,
                      fontSize: 14,
                      fontFamily: "San Francisco",
                      ...labelStyle,
                    }}
                    text={country.flag}
                  />
                  <BaseText
                    style={{
                      color: "#4D4D4D",
                      paddingVertical: 0,
                      fontSize: 14,
                      ...labelStyle,
                    }}
                    text={type == "phone" ? country.dial_code : country.name}
                  />
                </View>
              </BasePressable>
            ))}
          </ScrollView>
        </View>
      ) : null}
    </View>
  );
};

export default CountryPicker;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
  },
  didvider: {
    height: 1,
    backgroundColor: "#fff",
    width: "100%",
    marginVertical: "4%",
  },
  buttonContainerWeb: {
    marginVertical: "3%",
  },
  buttonContainerMobile: {
    flexDirection: "column",
    alignItems: "center",
  },
  labelText: {
    fontSize: 20,
    color: colors.black,
    paddingBottom: 8,
  },
});
