import React, { PropsWithChildren } from "react";
import { AuthContextProvider } from "../auth.ctx";
import { MinerContextProvider } from "../miner.ctx";
import { NewOrderContextProvider } from "../new-order.ctx";
import { OrderContextProvider } from "../order.ctx";
import { MachinesContextProvider } from "../machines.ctx";
import { HostingCountriesContextProvider } from "../hosting-country.ctx";
import { PromoCodesContextProvider } from "../promo-code.ctx";
import { UsersContextProvider } from "../users.ctx";
import { OrderAdminContextProvider } from "../order-admin.ctx";
const RootProvider: React.FC<PropsWithChildren> = (props) => {
  return (
    <AuthContextProvider>
      <MinerContextProvider>
        <OrderContextProvider>
          <MachinesContextProvider>
            <HostingCountriesContextProvider>
              <PromoCodesContextProvider>
                <UsersContextProvider>
                  <OrderAdminContextProvider>
                    <NewOrderContextProvider>
                      {props.children}
                    </NewOrderContextProvider>
                  </OrderAdminContextProvider>
                </UsersContextProvider>
              </PromoCodesContextProvider>
            </HostingCountriesContextProvider>
          </MachinesContextProvider>
        </OrderContextProvider>
      </MinerContextProvider>
    </AuthContextProvider>
  );
};

export default RootProvider;
