// React
import React, { useEffect, useState, PropsWithChildren } from "react";

import { PromoCode } from "../types";
import adminServices from "../services/apis/admin-service";
const PromoCodesContext = React.createContext({
  promoCodes: null as PromoCode[] | null,
  initPromoCodesData: () => {},
});

export const PromoCodesContextProvider: React.FC<PropsWithChildren> = (
  props: any
) => {
  const [promoCodes, setPromoCodes] = useState<PromoCode[] | null>(null);

  const [rtListenersList, setRtListenersList] = useState([] as Function[]);

  const initPromoCodesData = () => {
    const promoCodesListener: Function = adminServices.listentToPromoCode(
      (promoCodes: PromoCode[]) => setPromoCodes(promoCodes)
    );

    rtListenersList.map((listener: Function) => listener());
    setRtListenersList([promoCodesListener]);
  };

  return (
    <PromoCodesContext.Provider
      value={{
        promoCodes,
        initPromoCodesData,
      }}
    >
      {props.children}
    </PromoCodesContext.Provider>
  );
};

export default PromoCodesContext;
