import * as React from "react";
import Svg, {
  Path,
  SvgProps,
  Defs,
  LinearGradient,
  Use,
  Pattern,
  Image,
  Stop,
} from "react-native-svg";

const UserIcon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width={91} height={87} viewBox="0 0 91 87" fill="none" {...props}>
      <Path
        d="M45.5548 55.889C60.4359 55.889 72.4993 43.8255 72.4993 28.9445C72.4993 14.0635 60.4359 2 45.5548 2C30.6738 2 18.6104 14.0635 18.6104 28.9445C18.6104 43.8255 30.6738 55.889 45.5548 55.889Z"
        stroke="#0A0A0A"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M45.5548 55.889C60.4359 55.889 72.4993 43.8255 72.4993 28.9445C72.4993 14.0635 60.4359 2 45.5548 2C30.6738 2 18.6104 14.0635 18.6104 28.9445C18.6104 43.8255 30.6738 55.889 45.5548 55.889Z"
        stroke="url(#paint0_linear_1736_169)"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M45.5548 55.889C60.4359 55.889 72.4993 43.8255 72.4993 28.9445C72.4993 14.0635 60.4359 2 45.5548 2C30.6738 2 18.6104 14.0635 18.6104 28.9445C18.6104 43.8255 30.6738 55.889 45.5548 55.889Z"
        stroke="url(#pattern0)"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M88.6667 99.0001C88.6667 87.5663 84.1247 76.6008 76.0397 68.5159C67.9548 60.431 56.9893 55.8889 45.5555 55.8889C34.1217 55.8889 23.1562 60.431 15.0713 68.5159C6.9864 76.6008 2.44434 87.5663 2.44434 99.0001"
        stroke="#0A0A0A"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M88.6667 99.0001C88.6667 87.5663 84.1247 76.6008 76.0397 68.5159C67.9548 60.431 56.9893 55.8889 45.5555 55.8889C34.1217 55.8889 23.1562 60.431 15.0713 68.5159C6.9864 76.6008 2.44434 87.5663 2.44434 99.0001"
        stroke="url(#paint1_linear_1736_169)"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M88.6667 99.0001C88.6667 87.5663 84.1247 76.6008 76.0397 68.5159C67.9548 60.431 56.9893 55.8889 45.5555 55.8889C34.1217 55.8889 23.1562 60.431 15.0713 68.5159C6.9864 76.6008 2.44434 87.5663 2.44434 99.0001"
        stroke="url(#pattern1)"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Defs>
        <Pattern
          id="pattern0"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <Use
            xlinkHref="#image0_1736_169"
            transform="translate(-0.166667) scale(0.00111111)"
          />
        </Pattern>
        <Pattern
          id="pattern1"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <Use
            xlinkHref="#image0_1736_169"
            transform="matrix(0.000833333 0 0 0.00166667 0 -0.25)"
          />
        </Pattern>
        <LinearGradient
          id="paint0_linear_1736_169"
          x1="18.6104"
          y1="-5.65091"
          x2="75.3238"
          y2="-2.92746"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#A5259D" />
          <Stop offset="1" stopColor="#4890FE" />
        </LinearGradient>
        <LinearGradient
          id="paint1_linear_1736_169"
          x1="2.44434"
          y1="49.7682"
          x2="92.5638"
          y2="58.4235"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#A5259D" />
          <Stop offset="1" stopColor="#4890FE" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
};

export default UserIcon;
