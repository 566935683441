import buildEnvConfig from "../build.env.json";

const prodConfig = {
  apiKey: "AIzaSyAIMH3h_f1L21dRDu4O36KtzPSvJrVpeOI",
  authDomain: "corporations-fd072.firebaseapp.com",
  projectId: "corporations-fd072",
  storageBucket: "corporations-fd072.appspot.com",
  messagingSenderId: "1031255039012",
  appId: "1:1031255039012:web:3287b7f774b2191c5eccb1",
  measurementId: "G-5QMX5GBG8L",
};

// const devConfig = {
//   apiKey: "AIzaSyAnqIWDZeL_BMkRVQSVVGNZMMo-omDTkvw",
//   authDomain: "resume-crafter-dev.firebaseapp.com",
//   projectId: "resume-crafter-dev",
//   storageBucket: "resume-crafter-dev.appspot.com",
//   messagingSenderId: "490351342702",
//   appId: "1:490351342702:web:e2dbed6acf2dd131bf227f",
//   measurementId: "G-Y11N1LKX51",
// };

const emuConfig = {
  apiKey: "AIzaSyAIMH3h_f1L21dRDu4O36KtzPSvJrVpeOI",
  authDomain: "corporations-fd072.firebaseapp.com",
  projectId: "corporations-fd072",
  storageBucket: "corporations-fd072.appspot.com",
  messagingSenderId: "1031255039012",
  appId: "1:1031255039012:web:3287b7f774b2191c5eccb1",
  measurementId: "G-5QMX5GBG8L",
};

const exportConfig = () => {
  if (buildEnvConfig.BUILD_ENV == "PROD") {
    return prodConfig;
  } else {
    return emuConfig;
  }
};

export default exportConfig;
