// React
import React, { useEffect, useState, PropsWithChildren } from "react";

import { Machine } from "../types";
import machinesServices from "../services/apis/machines-service";

const MachinesContext = React.createContext({
  machines: null as Machine[] | null,
  initMachinesData: () => {},
});

export const MachinesContextProvider: React.FC<PropsWithChildren> = (
  props: any
) => {
  const [machines, setMachines] = useState<Machine[] | null>(null);

  const [rtListenersList, setRtListenersList] = useState([] as Function[]);

  const initMachinesData = () => {
    const machinesListener: Function = machinesServices.listentToMachines(
      (machines: Machine[]) => setMachines(machines)
    );

    rtListenersList.map((listener: Function) => listener());
    setRtListenersList([machinesListener]);
  };

  return (
    <MachinesContext.Provider
      value={{
        machines,
        initMachinesData,
      }}
    >
      {props.children}
    </MachinesContext.Provider>
  );
};

export default MachinesContext;
