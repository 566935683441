import React, { useState } from "react";
import {
  StyleSheet,
  SafeAreaView,
  Image,
  View,
  Platform,
  Text,
} from "react-native";
import { Asset } from "expo-asset";
import RootProvider from "./src/contexts/@store/RootProvider";
import MainPage from "./src/components/MainPage/MainPage";
import { NavigationContainer, DefaultTheme } from "@react-navigation/native";
import FullScreenLoader from "./src/components/Loader/FullScreenLoader";
import * as Linking from "expo-linking";
import { useEffect } from "react";
import "react-native-gesture-handler";
import routes from "./src/routes";
import { useFonts, loadAsync } from "expo-font";
import * as SplashScreen from "expo-splash-screen";
import colors from "./src/configs/colors";
import Ionicons from "@expo/vector-icons/Ionicons";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import SafeViewAndroid from "./src/components/SafeViewAndroid/SafeViewAndroid";
import CustomisableAlert from "react-native-customisable-alert";
import "@expo/metro-runtime";

const MyTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: colors.black,
  },
  dark: false,
};

const prefix = Linking.createURL("/");

SplashScreen.preventAutoHideAsync();

function cacheImages(images: Array<any>) {
  return images.map((image) => {
    if (typeof image === "string") {
      return Image.prefetch(image);
    } else {
      return Asset.fromModule(image).downloadAsync();
    }
  });
}

function cacheFonts(fonts: Array<any>) {
  return fonts.map((font) => loadAsync(font));
}

export default function App() {
  // const [fontsLoaded] = useFonts({
  //   BiennaleRegular: require("./assets/fonts/Biennale-Regular.otf"),
  //   BiennaleBold: require("./assets/fonts/Biennale-Bold.otf"),
  // });

  const [appIsReady, setAppIsReady] = useState(false);

  useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        SplashScreen.preventAutoHideAsync();

        const imageAssets = cacheImages([
          "https://firebasestorage.googleapis.com/v0/b/corporations-fd072.appspot.com/o/products_image%2Fasic-min.jpg?alt=media",
          "https://firebasestorage.googleapis.com/v0/b/corporations-fd072.appspot.com/o/products_image%2Fasic_no-bg.png?alt=media",
          "https://firebasestorage.googleapis.com/v0/b/corporations-fd072.appspot.com/o/products_image%2Fasic-min.png?alt=media",
          require("./assets/images/account_type_bg-min.png"),
          require("./assets/images/background-gradient-min.png"),
          require("./assets/images/button-background-min.png"),
          require("./assets/images/wallet-min.png"),
          require("./assets/images/worker-min.png"),
          require("./assets/images/investment_resume_bg-min.png"),
          require("./assets/images/logo-corp-3.png"),
          require("./assets/images/logo-corp-4.png"),
          require("./assets/sales_funnel_bg-min.png"),
          require("./assets/images/support_portrait.png"),
          require("./assets/images/asic-min.png"),
          require("./assets/images/asic_no-bg.png"),
          require("./assets/images/account_type_logo-min.png"),
        ]);

        const fontAssets = cacheFonts([
          {
            PoppinsRegular: require("./assets/fonts/Poppins-Regular.ttf"),
            PoppinsBold: require("./assets/fonts/Poppins-Bold.ttf"),
            BiennaleRegular: require("./assets/fonts/Biennale-Regular.otf"),
            BiennaleBold: require("./assets/fonts/Biennale-Bold.otf"),
          },
        ]);

        await Promise.all([...imageAssets, ...fontAssets]);
      } catch (e) {
        // You might want to provide this error information to an error reporting service
        console.warn(e);
      } finally {
        setAppIsReady(true);
        SplashScreen.hideAsync();
      }
    }

    loadResourcesAndDataAsync();
  }, []);

  const linkingConfig = {};
  Object.keys(routes).forEach((route) => {
    let screens = {};
    if (routes[route].screens) {
      Object.keys(routes[route].screens).forEach((nestedRoute) => {
        if (routes[route].screens[nestedRoute].screens) {
          let nestedScreens = {};

          Object.keys(routes[route].screens[nestedRoute].screens).forEach(
            (deepRoute) => {
              nestedScreens[
                routes[route].screens[nestedRoute].screens[deepRoute].screenName
              ] =
                routes[route].screens[nestedRoute].screens[deepRoute].screenUrl;
            }
          );
          screens[routes[route].screens[nestedRoute].screenName] = {};
          screens[routes[route].screens[nestedRoute].screenName].path =
            routes[route].screens[nestedRoute].screenUrl;
          screens[routes[route].screens[nestedRoute].screenName].screens =
            nestedScreens;
        } else {
          screens[routes[route].screens[nestedRoute].screenName] =
            routes[route].screens[nestedRoute].screenUrl;
        }
      });
    }
    linkingConfig[routes[route].screenName] = {
      path: routes[route].screenUrl,
      screens: screens,
    };
  });
  const linking = {
    prefixes: [prefix, "corporations-app://"],
    config: {
      screens: linkingConfig,
    },
  };

  if (!appIsReady) {
    return null;
  }
  return (
    <RootProvider>
      <GestureHandlerRootView style={{ flex: 1 }}>
        <NavigationContainer
          theme={MyTheme}
          linking={linking}
          fallback={<FullScreenLoader />}
        >
          {Platform.OS !== "ios" ? (
            <View style={SafeViewAndroid.AndroidSafeArea}>
              <MainPage />
              <CustomisableAlert
                titleStyle={{
                  fontSize: 18,
                  fontFamily: "PoppinsBold",
                  color: "#fff",
                  marginVertical: 12,
                }}
                textStyle={{
                  fontSize: 16,
                  fontFamily: "PoppinsRegular",
                  color: "#fff",
                }}
                btnStyle={{
                  borderRadius: 10,
                  backgroundColor: "#fff",
                }}
                btnLabelStyle={{
                  fontSize: 18,
                  fontFamily: "PoppinsBold",
                  color: "#000",
                }}
                defaultSuccessIcon={
                  <Ionicons
                    name="checkmark-circle-outline"
                    size={50}
                    color="#0DFF34"
                  />
                }
                defaultErrorIcon={
                  <Ionicons
                    name="close-circle-outline"
                    size={50}
                    color="#FF5B5B"
                  />
                }
                alertContainerStyle={{
                  backgroundColor: "#1E1F23",
                  maxWidth: 600,
                }}
              />
            </View>
          ) : (
            <SafeAreaView style={SafeViewAndroid.AndroidSafeArea}>
              <MainPage />
              <CustomisableAlert
                titleStyle={{
                  fontSize: 18,
                  fontFamily: "PoppinsBold",
                  color: "#fff",
                  marginVertical: 12,
                }}
                textStyle={{
                  fontSize: 16,
                  fontFamily: "PoppinsRegular",
                  color: "#fff",
                }}
                btnStyle={{
                  borderRadius: 10,
                  backgroundColor: "#fff",
                }}
                btnLabelStyle={{
                  fontSize: 18,
                  fontFamily: "PoppinsBold",
                  color: "#000",
                }}
                defaultSuccessIcon={
                  <Ionicons
                    name="checkmark-circle-outline"
                    size={50}
                    color="#0DFF34"
                  />
                }
                defaultErrorIcon={
                  <Ionicons
                    name="close-circle-outline"
                    size={50}
                    color="#FF5B5B"
                  />
                }
                alertContainerStyle={{
                  backgroundColor: "#1E1F23",
                  maxWidth: 600,
                }}
              />
            </SafeAreaView>
          )}
        </NavigationContainer>
      </GestureHandlerRootView>
    </RootProvider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
});
