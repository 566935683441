import React, { useContext, useState } from "react";
import { Alert, Platform, View } from "react-native";
import { StackNavigationProp } from "@react-navigation/stack";
import { RouteProp } from "@react-navigation/native";
import authServices from "../../services/apis/auth-service";
import { Alert as AlertType, UserData } from "../../types";
import routes from "../../routes";
import BaseAuthForm from "./BaseAuthForm";
import signupProFormSchema from "../../forms/signup-pro.json";
import signupParticulierFormSchema from "../../forms/signup-particulier.json";
import BaseForm from "../../components/Base/SalesFunnel/BaseFormSalesFunnel/BaseFormSalesFunnel";
import NewOrderContext from "../../contexts/new-order.ctx";
import AuthContainer from "./AuthContainer";
import BaseText from "../Base/BaseText/BaseText";
import useIsMobile from "../../utils/useIsMobile";
import colors from "../../configs/colors";
import BaseButton from "../Base/SalesFunnel/BaseButtonSalesFunnel/BaseButtonSalesFunnel";
import BaseInput from "../Base/SalesFunnel/BaseInputSalesFunnel/BaseInputSalesFunnel";
import { serverTimestamp } from "firebase/firestore";
import { showAlert } from "react-native-customisable-alert";
import Ionicons from "@expo/vector-icons/Ionicons";

interface SignUpProps {
  navigation: StackNavigationProp<any>;
  route: RouteProp<{ params: { type: string } }, "params">;
}
const SignUp: React.FC<SignUpProps> = ({ navigation, route }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasBtcAdress, setHasBtcAddress] = useState(false);
  const [btcAdress, setBtcAddress] = useState("");
  const newOrderCtx = useContext(NewOrderContext);
  const isMobile = useIsMobile();
  const onSubmit = (data: Record<string, any>) => {
    if (!isLoading) {
      setIsLoading(true);
      authServices
        .signUpWithEmailAndPassword(data.email, data.password, {
          email: data.email,
          firstname: data.firstname,
          lastname: data.lastname,
          address: data.address,
          country: data.country?.name || null,
          countryCode: data.country?.code || null,
          city: data.city,
          phone: data.phone,
          phoneDialCode: data.phoneCountry?.dial_code || null,
          phoneCountry: data.phoneCountry?.name || null,
          type: route.params?.type ? route.params.type : "particulier",
          companyName: data.companyName,
          currency: data.currency,
          companyAddress: data.companyAddress,
          walletAddress: hasBtcAdress ? btcAdress : null,
          walletAddressActiveAt: hasBtcAdress ? serverTimestamp() : null,
        } as UserData)
        .then(async (alertMessage: AlertType) => {
          await authServices.signInWithEmailAndPassword(
            data.email,
            data.password
          );

          setIsLoading(false);
          showAlert({
            title: "Succès !",
            message: "Création du compte réussie !",
            alertType: "success",
            onPress: () => console.log(""),
          });
          if (Platform.OS == "web") {
            if (newOrderCtx.isInOrderFlow) {
              navigation.navigate(routes.salesFunnel.screenName, {
                screen: routes.salesFunnel.screens.salesCGV.screenName,
              });
            } else {
              navigation.navigate(routes.drawer.screenName);
            }
          } else {
            navigation.navigate(routes.drawer.screenName);
          }
        })
        .then(() => {})
        .catch((alertMessage: AlertType) => {
          console.log("error", alertMessage);
          setIsLoading(false);
          showAlert({
            title: alertMessage.header,
            message: alertMessage.detail,
            alertType: "error",
            customIcon: (
              <Ionicons name="close-circle-outline" size={50} color="#FF5B5B" />
            ),
            onPress: () => console.log(""),
          });
        });
    }
  };
  return (
    <AuthContainer navigation={navigation}>
      <BaseAuthForm>
        <View style={{}}>
          <BaseText
            bold
            text="Vos informations"
            style={{
              fontSize: isMobile ? 22 : 30,
              color: isMobile ? colors.white : "#000",
              fontWeight: "700",
            }}
          />
        </View>
        <BaseForm
          schema={
            route.params?.type == "pro"
              ? signupProFormSchema
              : signupParticulierFormSchema
          }
          onSubmit={onSubmit}
          buttonTitle={
            newOrderCtx.isInOrderFlow
              ? "Continuer vers le paiement"
              : "Inscription"
          }
          isLoading={isLoading}
          style={{
            flex: 1,
            width: "100%",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
          labelStyle={isMobile ? { color: colors.white, fontSize: 18 } : {}}
          buttonBgColor={isMobile ? colors.white : "#000"}
          buttonTitleColor={isMobile ? colors.black : "#fff"}
          inputTextStyle={isMobile ? { fontSize: 14 } : undefined}
          children={
            <View style={{ width: "100%" }}>
              <BaseText
                text="Avez-vous une adresse bitcoin"
                style={{
                  fontSize: isMobile ? 18 : 20,
                  color: isMobile ? colors.white : "#000",
                }}
              />
              <View style={{ flexDirection: "row", flex: 1 }}>
                <BaseButton
                  title={"OUI"}
                  onPress={() => setHasBtcAddress(true)}
                  notFullWidth
                  smallPadding
                  bgColor={
                    hasBtcAdress
                      ? "#1ABBFE"
                      : isMobile
                      ? colors.white
                      : colors.black
                  }
                  style={{
                    paddingVertical: 12,
                  }}
                  textColor={isMobile ? colors.black : colors.white}
                  textProps={{
                    style: {
                      color: isMobile ? colors.black : colors.white,
                    },
                  }}
                />
                <BaseButton
                  title={"NON"}
                  onPress={() => setHasBtcAddress(false)}
                  notFullWidth
                  smallPadding
                  bgColor={
                    !hasBtcAdress
                      ? "#1ABBFE"
                      : isMobile
                      ? colors.white
                      : colors.black
                  }
                  style={{
                    paddingVertical: 12,
                  }}
                  textColor={isMobile ? colors.black : colors.white}
                  textProps={{
                    style: {
                      color: isMobile ? colors.black : colors.white,
                    },
                  }}
                />
                {!hasBtcAdress ? null : (
                  <View style={{ flex: 1 }}>
                    <BaseInput
                      onChangeText={(value) => setBtcAddress(value)}
                      value={btcAdress}
                      placeholder={"Adresse bitcoin"}
                      containerStyle={{ flex: 1 }}
                    />
                  </View>
                )}
              </View>
            </View>
          }
        />
      </BaseAuthForm>
    </AuthContainer>
  );
};

export default SignUp;
