import React from "react";
import { View, StyleSheet } from "react-native";
import SFContainer from "../SalesFunnel/SFContainer/SFContainer";
import BaseText from "../Base/BaseText/BaseText";
import ProIcon from "../../svg/ProIcon";
import UserIcon from "../../svg/UserIcon";
import BasePressable from "../Base/BasePressable/BasePressable";
import routes from "../../routes";
import colors from "../../configs/colors";
import useIsMobile from "../../utils/useIsMobile";

const AccountType = ({ navigation }) => {
  const isMobile = useIsMobile();
  const handleNavPro = () => {
    navigation.navigate(routes.signup.screenName, {
      type: "pro",
    });
  };

  const handleNavUser = () => {
    navigation.navigate(routes.signup.screenName, {
      type: "particulier",
    });
  };
  return (
    <SFContainer
      navigation={navigation}
      bg={
        isMobile
          ? "l"
          : require("../../../assets/images/account_type_bg-min.png")
      }
      rightLogo={require("../../../assets/images/account_type_logo-min.png")}
      hideMobileHeader={isMobile}
    >
      <View style={{ margin: "10%" }}>
        <View>
          <BaseText
            style={{ fontSize: isMobile ? 24 : 30, textTransform: "uppercase" }}
            bold
            text={"Créez votre compte Corporations"}
          />
        </View>
        <View style={{ marginVertical: "5%" }}>
          <BaseText
            style={{ fontSize: 20, marginBottom: 12 }}
            text={"Vous êtes"}
          />
          <View style={{ flexDirection: isMobile ? "column" : "row" }}>
            <BasePressable
              onClick={handleNavUser}
              style={
                isMobile
                  ? {
                      ...styles.itemContainerMobile,
                      marginRight: isMobile ? 0 : "2%",
                    }
                  : {
                      ...styles.itemContainerWeb,
                      marginRight: isMobile ? 0 : "2%",
                    }
              }
            >
              <BaseText
                bold
                style={styles.itemText}
                text={"Un particulier"}
              ></BaseText>
              <UserIcon />
            </BasePressable>
            <BasePressable
              onClick={handleNavPro}
              style={
                isMobile
                  ? {
                      ...styles.itemContainerMobile,
                      marginLeft: isMobile ? 0 : "2%",
                    }
                  : {
                      ...styles.itemContainerWeb,
                      marginLeft: isMobile ? 0 : "2%",
                    }
              }
            >
              <BaseText
                bold
                style={{ ...styles.itemText, marginBottom: 26 }}
                text={"Un professionel"}
              ></BaseText>
              <ProIcon />
            </BasePressable>
          </View>
        </View>
      </View>
    </SFContainer>
  );
};

export default AccountType;

const styles = StyleSheet.create({
  itemContainerWeb: {
    backgroundColor: colors.white,
    width: 360,
    height: 170,
    alignItems: "center",
    justifyContent: "flex-end",
    borderRadius: 10,
  },
  itemContainerMobile: {
    backgroundColor: colors.white,
    width: 300,
    height: 140,
    alignItems: "center",
    justifyContent: "flex-end",
    marginVertical: "3%",
    borderRadius: 10,
  },
  itemText: {
    color: colors.black,
    fontSize: 20,
    marginBottom: 12,
  },
  gradientBackground: {
    position: "absolute",
    right: 0,
    bottom: 0,
    flex: 1,
    height: "100%",
    width: "100%",
    zIndex: -1,
  },
});
