import { Dimensions, Platform } from "react-native";
import { useState } from "react";

const useIsMobile = () => {
  const [screenData, setScreenData] = useState(
    Dimensions.get("window").width < 1000 || Platform.OS !== "web"
  );

  return screenData;
};

const useDimensions = () => {
  if (Dimensions) {
    const [screenSize, setScreenData] = useState(Dimensions.get("window"));

    return screenSize;
  }
};

const useScreenScale = () => {
  const [screenSize, setScreenData] = useState(
    Dimensions.get("window").width / 1540
  );
  return screenSize;
};

export { useDimensions, useScreenScale };
export default useIsMobile;
