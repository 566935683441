import { Machine } from "../../types";
import { auth, functions, firestore } from "../app/firebase-service";

import {
  doc,
  onSnapshot,
  updateDoc,
  collection,
  addDoc,
  getDocs,
  where,
  query,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import error from "../../messages/error";
import success from "../../messages/success";
const paymentServices = {
  // SignUp Service
  createAbandonedCart(
    machineId: string,
    machineAmount: number,
    price: number,
    hostingCountryId: string,
    corporationCare: boolean,
    promoCodeId?: string
  ): Promise<{ abandonedCartId: string }> {
    return new Promise((res, rej) => {
      // Create new user in DB and in Firebase auth Cloud functions
      const createNewAbandonedCart = httpsCallable(
        functions,
        "createAbandonedCart"
      );
      createNewAbandonedCart({
        machineId,
        machineAmount,
        price,
        corporationCare,
        hostingCountryId,
        promoCodeId,
      })
        .then((result: any) => {
          if (result.data.status == "ERROR") {
            // If email already exists custom error
            rej(error.ERROR_GENERIC);
          } else {
            return res(result.data);
          }
        })
        .catch((e) => {
          console.log(e);
          // generic error
          return rej(error.ERROR_GENERIC);
        });
    });
  },

  createOrderVivaWallet(
    machineId: string,
    machineName: string,
    machineAmount: number,
    machineImageUrl: string,
    price: number,
    hostingCountryId: string,
    corporationCare: boolean,
    countryCode?: string,
    requestLang?: string,
    promoCodeId?: string,
    abandonedCartId?: string
  ): Promise<{ orderCode: string; orderNumber: number }> {
    return new Promise((res, rej) => {
      // Create new user in DB and in Firebase auth Cloud functions
      const createNewOrder = httpsCallable(functions, "createOrderVivaWallet");
      createNewOrder({
        machineId,
        machineName,
        machineAmount,
        machineImageUrl,
        price,
        countryCode,
        corporationCare,
        requestLang,
        hostingCountryId,
        promoCodeId,
        abandonedCartId,
      })
        .then((result: any) => {
          if (result.data.status == "ERROR") {
            // If email already exists custom error
            rej(error.ERROR_GENERIC);
          } else {
            return res(result.data);
          }
        })
        .catch((e) => {
          console.log(e);
          // generic error
          return rej(error.ERROR_GENERIC);
        });
    });
  },

  createOrderCoinbase(
    machineId: string,
    machineName: string,
    machineAmount: number,
    machineImageUrl: string,
    price: number,
    hostingCountryId: string,
    corporationCare: boolean,
    promoCodeId?: string,
    abandonedCartId?: string
  ): Promise<{ orderCode: string; orderNumber: number; hosted_url: string }> {
    return new Promise((res, rej) => {
      // Create new user in DB and in Firebase auth Cloud functions
      const createNewOrder = httpsCallable(functions, "createOrderCoinbase");
      createNewOrder({
        machineId,
        machineName,
        machineAmount,
        machineImageUrl,
        price,
        corporationCare,
        hostingCountryId,
        promoCodeId,
        abandonedCartId,
      })
        .then((result: any) => {
          if (result.data.status == "ERROR") {
            // If email already exists custom error
            rej(error.ERROR_GENERIC);
          } else {
            return res(result.data);
          }
        })
        .catch((e) => {
          console.log(e);
          // generic error
          return rej(error.ERROR_GENERIC);
        });
    });
  },

  createOrderTransfer(
    machineId: string,
    machineName: string,
    machineAmount: number,
    machineImageUrl: string,
    price: number,
    hostingCountryId: string,
    corporationCare: boolean,
    promoCodeId?: string,
    abandonedCartId?: string
  ): Promise<{ orderCode: string; orderNumber: number }> {
    return new Promise((res, rej) => {
      // Create new user in DB and in Firebase auth Cloud functions
      const createNewOrder = httpsCallable(functions, "createOrderTransfer");
      createNewOrder({
        machineId,
        machineName,
        machineAmount,
        machineImageUrl,
        price,
        corporationCare,
        hostingCountryId,
        promoCodeId,
        abandonedCartId,
      })
        .then((result: any) => {
          if (result.data.status == "ERROR") {
            // If email already exists custom error
            rej(error.ERROR_GENERIC);
          } else {
            return res(result.data);
          }
        })
        .catch((e) => {
          console.log(e);
          // generic error
          return rej(error.ERROR_GENERIC);
        });
    });
  },
};
export default paymentServices;
