import React, { useEffect, useState } from "react";
import { View, StyleSheet, ViewStyle } from "react-native";
import BasePressable from "../Base/BasePressable/BasePressable";
import BaseText from "../Base/BaseText/BaseText";
import colors from "../../configs/colors";
import countries from "../../utils/countries";
import Ionicons from "@expo/vector-icons/Ionicons";
import { ScrollView } from "react-native-gesture-handler";
import { TextStyle } from "react-native";
import useIsMobile from "../../utils/useIsMobile";
import BaseInput from "../Base/BaseInput/BaseInput";
import i18n from "../../translations/i18n";
interface CustomPickerProps {
  onPress: (data: any, index?: number) => void;
  label?: string;
  type?: string;
  defaultValue?: any;
  labelStyle?: TextStyle;
  containerStyle?: ViewStyle;
  popoverStyle?: ViewStyle;
  popoverTextStyle?: TextStyle;
  values?: any[];
  placeholder?: string;
  selectedKeys?: string[];
  selectedKey?: string;
}
const CustomPicker: React.FC<CustomPickerProps> = ({
  onPress,
  label,
  type,
  defaultValue,
  labelStyle,
  containerStyle,
  values,
  placeholder,
  selectedKeys,
  selectedKey,
  popoverStyle,
  popoverTextStyle,
}) => {
  const [isSelecting, setisSelecting] = useState(false);
  const [currentValue, setCUrrentValue] = useState(defaultValue);

  const isMobile = useIsMobile();

  const toggleIsSelecting = () => {
    setisSelecting(!isSelecting);
  };

  useEffect(() => {
    setCUrrentValue(defaultValue);
  }, [defaultValue]);

  const displayValue = (value: any) => {
    let displayValue = "";
    if (selectedKeys) {
      selectedKeys.forEach((currentKey) => {
        console.log(displayValue);

        displayValue = displayValue
          ? `${displayValue} ${i18n.t(value[currentKey], {
              defaultValue: value[currentKey],
            })}`
          : i18n.t(value[currentKey], {
              defaultValue: value[currentKey],
            });
      });
    } else if (selectedKey) {
      displayValue = i18n.t(value[selectedKey], {
        defaultValue: value[selectedKey],
      });
    } else {
      displayValue = i18n.t(value, { defaultValue: value });
    }

    return displayValue;
  };
  return (
    <View
      style={{
        zIndex: 8,
        marginVertical: isMobile ? 0 : 10,
      }}
    >
      {label ? (
        <BaseText
          style={{
            ...styles.labelText,

            color: isMobile ? "#fff" : "#000",
            ...labelStyle,
          }}
          text={label}
        ></BaseText>
      ) : null}
      <BasePressable
        style={{
          ...styles.container,
          borderWidth: containerStyle ? 1 : isMobile ? 0 : 2,
          borderColor: "#E4E4E4",
          borderRadius: 6,
          // paddingHorizontal: isMobile ? 0 : "2%",
          // paddingVertical: isMobile ? 0 : "1%",

          backgroundColor: colors.white,
          ...containerStyle,
        }}
        onClick={toggleIsSelecting}
      >
        <View
          style={{
            borderRadius: 4,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            padding: 4,
            flex: 1,
            height: 50,
            ...containerStyle,
            borderWidth: 0,
          }}
        >
          {currentValue ? (
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <BaseText
                style={{
                  color: "#4D4D4D",
                  padding: 0,
                  margin: 0,
                  paddingHorizontal: 6,
                  fontSize: 16,
                  ...labelStyle,
                }}
                text={displayValue(currentValue)}
              />
            </View>
          ) : (
            <BaseText
              style={{
                color: "#4D4D4D",
                padding: 0,
                margin: 0,
                paddingHorizontal: 0,
                fontSize: isMobile ? 14 : 16,
                paddingLeft: 16,
                ...labelStyle,
              }}
              text={placeholder || "Séléctionnez une valeur"}
            />
          )}

          <Ionicons name={"chevron-down-outline"} size={20} color={"#4D4D4D"} />
        </View>
      </BasePressable>

      {isSelecting ? (
        <View
          style={{
            position: "absolute",
            zIndex: 10,
            elevation: 10,
            left: 0,
            right: 0,
            top: "100%",
            backgroundColor: "#fff",
            padding: "2%",
            borderRadius: 6,
            maxHeight: 180,
            borderWidth: 2,
            borderColor: "#E4E4E4",
            minWidth: 100,
            ...containerStyle,
            ...popoverStyle,
          }}
        >
          <ScrollView>
            {values?.map((value, index) => (
              <BasePressable
                key={index}
                style={{
                  ...styles.container,
                  justifyContent: "space-between",
                  overflow: "visible",
                  paddingVertical: 6,
                }}
                onClick={() => {
                  onPress(value, index);
                  setCUrrentValue(value);
                  toggleIsSelecting();
                }}
              >
                <View
                  style={{
                    ...styles.container,
                    marginLeft: 4,
                  }}
                >
                  <BaseText
                    style={{
                      color: "#4D4D4D",
                      paddingVertical: 0,
                      paddingHorizontal: 0,
                      fontSize: 14,
                      ...labelStyle,
                      ...popoverTextStyle,
                    }}
                    text={displayValue(value)}
                  />
                </View>
              </BasePressable>
            ))}
          </ScrollView>
        </View>
      ) : null}
    </View>
  );
};

export default CustomPicker;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
  },
  didvider: {
    height: 1,
    backgroundColor: "#fff",
    width: "100%",
    marginVertical: "4%",
  },
  buttonContainerWeb: {
    marginVertical: "3%",
  },
  buttonContainerMobile: {
    flexDirection: "column",
    alignItems: "center",
  },
  labelText: {
    fontSize: 20,
    color: colors.black,
    paddingBottom: 8,
  },
});
