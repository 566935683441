import React from "react";
import { View, StyleSheet, Image } from "react-native";
import BaseText from "../../Base/BaseText/BaseText";

import Ionicons from "@expo/vector-icons/Ionicons";
import colors from "../../../configs/colors";
import useIsMobile from "../../../utils/useIsMobile";
import { LinearGradient } from "expo-linear-gradient";
import BaseGradientText from "../../Base/BaseGradientText/BaseGradientText";
import BasePressable from "../../Base/BasePressable/BasePressable";
interface HeaderProps {
  steps: string[];
  currentStep: number;
  hideCallButton?: boolean;
  goBack: () => void;
  children?: React.ReactNode;
  hideMobileStep?: boolean;
}
const Header: React.FC<HeaderProps> = ({
  steps,
  currentStep,
  hideCallButton,
  hideMobileStep,
  goBack,
}) => {
  const isMobile = useIsMobile();

  return (
    <View>
      <LinearGradient
        // Les couleurs et les emplacements doivent être ajustés pour correspondre à la syntaxe de CSS
        colors={[
          "rgba(255, 255, 255, 0)",
          "rgba(255, 255, 255, 0.05)",
          "rgba(255, 255, 255, 0)",
          "rgba(196, 196, 196, 0.05)",
        ]}
        start={{ x: 0, y: 0 }}
        end={{ x: Math.cos(85.96), y: Math.sin(85.96) }}
        style={styles.bannerContainer} // Remplacez par vos propres styles
      >
        <BasePressable
          style={{ ...styles.backContainer, width: isMobile ? 100 : 200 }}
          onClick={goBack}
        >
          <Ionicons name="chevron-back-outline" size={22} color="#fff" />

          <BaseText
            text="Retour"
            style={{ fontSize: isMobile ? 18 : 20, fontWeight: "400" }}
          ></BaseText>
        </BasePressable>
        {/* {isMobile ? (
        <View style={{ marginVertical: 16 }}>
          <BaseGradientText
            text={steps[currentStep]}
            style={{
              fontSize: 18,
              fontWeight: "600",
              fontFamily: "BiennaleRegular",
            }}
            gradient="linear-gradient(90.3deg, #7BFFF1 0%, #BFFE2C 100%), linear-gradient(90.3deg, #F10348 0%, #A5259D 45.83%, #4890FE 100%), linear-gradient(92.75deg, #A5259D -0.65%, #4890FE 100%), #0A0A0A"
          />
        </View>
      ) : null} */}
        <View>
          <View style={{ ...styles.stepsContainer, marginVertical: "3%" }}>
            {isMobile
              ? null
              : steps.map((step, index) => (
                  <View style={styles.cellContainer} key={index}>
                    {currentStep >= index ? (
                      <BaseGradientText
                        text={step}
                        style={{
                          fontSize: 14,
                          fontWeight: "600",
                          fontFamily: "PoppinsRegular",
                        }}
                        baseColor="#7BFFF1"
                        gradient="linear-gradient(90.3deg, #7BFFF1 0%, #BFFE2C 100%), linear-gradient(90.3deg, #F10348 0%, #A5259D 45.83%, #4890FE 100%), linear-gradient(92.75deg, #A5259D -0.65%, #4890FE 100%), #0A0A0A"
                      />
                    ) : (
                      <BaseText
                        style={{ fontSize: 14, fontWeight: "400", padding: 0 }}
                        text={step}
                      ></BaseText>
                    )}
                  </View>
                ))}
          </View>
          <View style={styles.stepsContainer}>
            {isMobile
              ? null
              : steps.map((step, index) => (
                  <View key={index}>
                    {index == 0 ? (
                      <View style={styles.cellContainer}>
                        <LinearGradient
                          colors={["#7BFFF1", "#BFFE2C"]}
                          start={{ x: 0, y: 0 }}
                          end={{
                            x: Math.cos((90.3 * Math.PI) / 180),
                            y: Math.sin((90.3 * Math.PI) / 180),
                          }}
                          style={styles.circle}
                        />
                        {currentStep > index ? (
                          <LinearGradient
                            colors={["#7BFFF1", "#BFFE2C"]}
                            start={{ x: 0, y: 0 }}
                            end={{
                              x: Math.cos((90.3 * Math.PI) / 180),
                              y: Math.sin((90.3 * Math.PI) / 180),
                            }}
                            style={styles.lineRight}
                          />
                        ) : (
                          <View
                            style={{
                              ...styles.lineRight,
                              backgroundColor: colors.white,
                            }}
                          />
                        )}
                      </View>
                    ) : index == steps.length - 1 ? (
                      <>
                        {currentStep >= index ? (
                          <View style={styles.cellContainer}>
                            <LinearGradient
                              colors={["#7BFFF1", "#BFFE2C"]}
                              start={{ x: 0, y: 0 }}
                              end={{
                                x: Math.cos((90.3 * Math.PI) / 180),
                                y: Math.sin((90.3 * Math.PI) / 180),
                              }}
                              style={styles.circle}
                            />
                            <LinearGradient
                              colors={["#7BFFF1", "#BFFE2C"]}
                              start={{ x: 0, y: 0 }}
                              end={{
                                x: Math.cos((90.3 * Math.PI) / 180),
                                y: Math.sin((90.3 * Math.PI) / 180),
                              }}
                              style={styles.lineLeft}
                            />
                          </View>
                        ) : (
                          <View style={styles.cellContainer}>
                            <View
                              style={{
                                ...styles.circle,
                                backgroundColor: colors.white,
                              }}
                            />
                            <View
                              style={{
                                ...styles.lineLeft,
                                backgroundColor: colors.white,
                              }}
                            />
                          </View>
                        )}
                      </>
                    ) : (
                      <>
                        {currentStep === index ? (
                          <View style={styles.cellContainer}>
                            <LinearGradient
                              colors={["#7BFFF1", "#BFFE2C"]}
                              start={{ x: 0, y: 0 }}
                              end={{
                                x: Math.cos((90.3 * Math.PI) / 180),
                                y: Math.sin((90.3 * Math.PI) / 180),
                              }}
                              style={styles.circle}
                            />
                            <View
                              style={{
                                ...styles.lineRight,
                                backgroundColor: colors.white,
                              }}
                            />
                            <LinearGradient
                              colors={["#7BFFF1", "#BFFE2C"]}
                              start={{ x: 0, y: 0 }}
                              end={{
                                x: Math.cos((90.3 * Math.PI) / 180),
                                y: Math.sin((90.3 * Math.PI) / 180),
                              }}
                              style={styles.lineLeft}
                            />
                          </View>
                        ) : currentStep > index ? (
                          <View style={styles.cellContainer}>
                            <LinearGradient
                              colors={["#7BFFF1", "#BFFE2C"]}
                              start={{ x: 0, y: 0 }}
                              end={{
                                x: Math.cos((90.3 * Math.PI) / 180),
                                y: Math.sin((90.3 * Math.PI) / 180),
                              }}
                              style={styles.circle}
                            />

                            <LinearGradient
                              colors={["#7BFFF1", "#BFFE2C"]}
                              start={{ x: 0, y: 0 }}
                              end={{
                                x: Math.cos((90.3 * Math.PI) / 180),
                                y: Math.sin((90.3 * Math.PI) / 180),
                              }}
                              style={styles.fullLine}
                            />
                          </View>
                        ) : (
                          <View style={styles.cellContainer}>
                            <View
                              style={{
                                ...styles.circle,
                                backgroundColor: colors.white,
                              }}
                            />
                            <View
                              style={{
                                ...styles.fullLine,
                                backgroundColor: colors.white,
                              }}
                            />
                          </View>
                        )}
                      </>
                    )}
                  </View>
                ))}
          </View>
        </View>

        <View style={styles.logoContainer}>
          <Image
            resizeMode="contain"
            source={require("../../../../assets/images/logo-corp-3.png")}
            style={styles.logo}
          />
        </View>
      </LinearGradient>
      {isMobile && !hideMobileStep ? (
        <View style={styles.mobileStepContainer}>
          <View style={styles.mobileStep}>
            <BaseGradientText
              text={`${steps[currentStep]} ${currentStep + 1}/${steps.length}`}
              style={{
                fontSize: 18,
                fontWeight: "600",
                fontFamily: "PoppinsRegular",
              }}
              baseColor="#7BFFF1"
              gradient="linear-gradient(90.3deg, #7BFFF1 0%, #BFFE2C 100%), linear-gradient(90.3deg, #F10348 0%, #A5259D 45.83%, #4890FE 100%), linear-gradient(92.75deg, #A5259D -0.65%, #4890FE 100%), #0A0A0A"
            />
          </View>
        </View>
      ) : null}
    </View>
  );
};

export default Header;

const styles = StyleSheet.create({
  bannerContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: "3%",
    shadowColor: "#000",
    shadowOffset: {
      width: -6,
      height: 6,
    },
    shadowOpacity: 0.47,
    shadowRadius: 28,
    elevation: 5,
    borderBottomWidth: 4,
    borderBottomColor: "#C4C4C40D",
  },
  backContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  cellContainer: {
    width: 100,
    alignItems: "center",
    justifyContent: "center",
  },
  stepsContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  circle: {
    height: 10,
    width: 10,
    borderRadius: 5,
  },
  lineLeft: {
    position: "absolute",
    zIndex: -1,
    top: 4,
    left: 0,
    height: 2,
    width: "50%",
  },
  lineRight: {
    position: "absolute",
    zIndex: -1,
    top: 4,
    right: 0,
    height: 2,
    width: "50%",
  },
  fullLine: {
    position: "absolute",
    zIndex: -1,
    top: 4,
    right: 0,
    height: 2,
    width: "100%",
  },
  stepsContent: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  step: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: colors.gray,
  },
  stepSelected: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: colors.startGradient,
  },
  triangleLeft: {
    width: 0,
    height: 0,
    borderTopWidth: 20,
    borderBottomWidth: 20,
    borderLeftWidth: 20,
    borderTopColor: "transparent",
    borderBottomColor: "transparent",
    borderLeftColor: colors.black,
  },
  triangleRight: {
    width: 0,
    height: 0,
    borderTopWidth: 20,
    borderBottomWidth: 20,
    borderLeftWidth: 20,
    borderTopColor: "transparent",
    borderBottomColor: "transparent",
    backgroundColor: colors.black,
  },
  logoContainer: {
    paddingVertical: 16,
    justifyContent: "center",
    alignItems: "center",
  },
  logo: {
    width: 200,
    height: 50,
    resizeMode: "contain",
  },
  mobileStepContainer: {
    marginTop: 24,
    alignItems: "center",
  },
  mobileStep: {
    borderWidth: 1,
    borderColor: colors.white,
    borderRadius: 10,
    paddingVertical: 5,
    paddingHorizontal: 10,
  },
});
