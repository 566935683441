export default {
  primary: "rgb(255, 45, 85)",
  black: "#000000",
  dark: "#212121",
  red: "#FF5B5B",
  gray: "#383B47",
  white: "#FFFFFF",
  grayDark: "#646464",
  lightGray: "#1E1F23",
  green: "#7BFFF1",
  startGradient: "#7BFFF1",
  endGradient: "#BFFE2C",
};
