import React, { PropsWithChildren, useContext } from "react";
import { View, StyleSheet, Image, ScrollView, Platform } from "react-native";
import InvestmentResume from "../SalesFunnel/InvestmentResume/InvestmentResume";
import useIsMobile, { useScreenScale } from "../../utils/useIsMobile";
import colors from "../../configs/colors";
import { StackNavigationProp } from "@react-navigation/stack";
import { useCode } from "react-native-reanimated";
import NewOrderContext from "../../contexts/new-order.ctx";
import BasePressable from "../Base/BasePressable/BasePressable";
import BaseText from "../Base/BaseText/BaseText";
import Ionicons from "@expo/vector-icons/Ionicons";

const AuthContainer: React.FC<
  PropsWithChildren & {
    navigation: StackNavigationProp<any>;
    hideOrderFlow?: boolean;
  }
> = (props) => {
  const isMobile = useIsMobile();
  const newOrderCtx = useContext(NewOrderContext);
  const scale = useScreenScale();
  return (
    <View
      style={
        isMobile
          ? { flexDirection: "column", flex: 1 }
          : { flexDirection: "row", flex: 1 }
      }
    >
      {newOrderCtx.isInOrderFlow ? (
        <InvestmentResume navigation={props.navigation} />
      ) : null}

      {isMobile || props.hideOrderFlow ? null : (
        <Image
          resizeMode="cover"
          source={require("../../../assets/images/investment_resume_bg-min.png")}
          style={styles.gradientBackground}
        />
      )}

      {props.hideOrderFlow ? (
        <View
          style={
            isMobile
              ? { flexDirection: "row", justifyContent: "space-between" }
              : { flex: 1 }
          }
        >
          {Platform.OS == "web" ? (
            <BasePressable
              style={styles.backContainer}
              onClick={() =>
                (window.location.href = "https://www.corporations.fr/")
              }
            >
              <Ionicons name="chevron-back-outline" size={22} color="#fff" />

              <BaseText
                text="Retour"
                style={{ fontSize: 20, fontWeight: "400" }}
              ></BaseText>
            </BasePressable>
          ) : (
            <View></View>
          )}

          {isMobile ? (
            <View
              style={{
                alignItems: "flex-end",
                paddingVertical: 12,
                paddingHorizontal: 4,
              }}
            >
              <Image
                resizeMode="cover"
                source={require("../../../assets/images/logo-corp-3.png")}
                style={styles.logoCorp}
              />
            </View>
          ) : (
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Image
                resizeMode="cover"
                source={require("../../../assets/images/asic-min.png")}
                style={{
                  width: 650 * scale,
                  height: 580 * scale,
                }}
              />
            </View>
          )}
        </View>
      ) : null}

      <ScrollView
        style={{
          flex: 1,
          backgroundColor: props.hideOrderFlow ? colors.black : colors.white,
        }}
        contentContainerStyle={{ flexGrow: 1 }}
      >
        {isMobile ? null : (
          <View
            style={{
              alignItems: "flex-end",
              paddingVertical: 12,
              paddingHorizontal: 12,
            }}
          >
            <Image
              resizeMode="cover"
              source={
                props.hideOrderFlow
                  ? require("../../../assets/images/logo-corp-3.png")
                  : require("../../../assets/images/logo-corp-4.png")
              }
              style={styles.logoCorp}
            />
          </View>
        )}

        {props.children}
        {isMobile && props.hideOrderFlow ? (
          <View
            style={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Image
              resizeMode="cover"
              source={require("../../../assets/images/asic-min.png")}
              style={{
                width: 290,
                height: 198,
              }}
            />
          </View>
        ) : null}
      </ScrollView>
    </View>
  );
};

export default AuthContainer;

const styles = StyleSheet.create({
  logoContainer: {
    paddingVertical: 16,
  },
  logo: {
    width: 200,
    height: 50,
    resizeMode: "contain",
  },
  gradientBackground: {
    position: "absolute",
    right: 0,
    bottom: 0,
    flex: 1,
    height: "100%",
    width: "100%",
    zIndex: -1,
  },
  logoCorp: {
    width: 236,
    height: 48,
  },
  backContainer: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 16,
    paddingHorizontal: 6,
  },
});
