// React
import React, {
  useEffect,
  useState,
  PropsWithChildren,
  useContext,
} from "react";
import {
  Machine,
  Company,
  CompanyBoss,
  Order,
  OrderData,
  orderStep,
  User,
  HostingCountry,
  AbandonedCart,
  AbandonedCartData,
} from "../types";
import orderServices from "../services/apis/order-service";
import AuthContext from "./auth.ctx";
import adminServices from "../services/apis/admin-service";
import hostingCountryServices from "../services/apis/hostingCountry-service";
import machinesServices from "../services/apis/machines-service";
import exchangeServices from "../services/apis/exchange-service";

export const invoiceOrderByValues = ["asc", "desc"] as const;
const OrderAdminContext = React.createContext({
  orders: [] as Order[],
  currentOrder: null as Order | null,
  currentClient: null as User | null,
  currentHostingCountry: null as HostingCountry | null | undefined,
  currentMachine: null as Machine | null,
  isLoading: false,
  searchValue: undefined as number | undefined | null,
  searchInvoiceValue: undefined as number | undefined | null,
  abandonedCarts: [] as AbandonedCart[],
  currentAbandonedCart: null as AbandonedCart | null,
  eurToDol: 1.1,
  invoiceOrders: [] as Order[],
  initOrders: () => {},
  getOrderData: (id: string) => {},
  currentOrderHandler: (order: Order | undefined) => {},
  updateOrder: async (order: any) => {},
  filterByHandler: (orderStep: filterByType) => {},
  createOrder: async (order: OrderData) => {},
  searchValueHandler: (value: string) => {},
  currentAbandonedCartHandler: (abandonedCart: AbandonedCart | undefined) => {},
  updateAbandonedCart: async (abandonedCart: any) => {},
  getAbandonedCartData: async (id: string) => {},
  searchInvoiceValueHandler: (value: string) => {},
  invoiceOrderByHandler: (invoiceOrderBy: invoiceOrderBy) => {},
  invoiceYearHandler: (value: string) => {},
});

export type filterByType = orderStep | "all";
export type invoiceOrderBy = "desc" | "asc";
export const OrderAdminContextProvider: React.FC<PropsWithChildren> = (
  props: any
) => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [abandonedCarts, setAbandonedCarts] = useState<AbandonedCart[]>([]);
  const [currentClient, setCurrentClient] = useState<User | undefined>();
  const [currentMachine, setCurrentMachine] = useState<Machine | undefined>();
  const [currentHostingCountry, setCurrentHostingCountry] = useState<
    HostingCountry | undefined
  >();
  const [filterBy, setFilerBy] = useState<filterByType>("all");
  const [invoiceOrderBy, setInvoiceOrderBy] = useState<invoiceOrderBy>("asc");
  const [invoiceYear, setInvoiceYear] = useState<string>(
    new Date().getFullYear().toString()
  );
  const [currentOrder, setCurrentOrder] = useState<Order | null>(null);
  const [currentAbandonedCart, setCurrentAbandonedCart] =
    useState<AbandonedCart | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [rtListenersList, setRtListenersList] = useState([] as Function[]);
  const authCtx = useContext(AuthContext);
  const [searchValue, setSearchValue] = useState<number | undefined | null>();
  const [searchInvoiceValue, setSearchInvoiceValue] = useState<
    number | undefined | null
  >();
  const [invoiceOrders, setInvoiceOrders] = useState<Order[]>([]);
  const initOrders = () => {
    const orderListener: Function = orderServices.listenToAllOrders(
      (orders: Order[]) => setOrders(orders),
      filterBy,
      searchValue > 1100 ? searchValue - 1100 : searchValue
    );

    const abandonedCartListener: Function =
      orderServices.listenToAllAbandonedCart(
        (abandonedCarts: AbandonedCart[]) => setAbandonedCarts(abandonedCarts)
      );

    const invoiceOrderListener: Function =
      orderServices.listenToAllOrderWithInvoice(
        (orders: Order[]) => setInvoiceOrders(orders),
        invoiceOrderBy,
        searchInvoiceValue > 1100
          ? searchInvoiceValue - 1100
          : searchInvoiceValue,
        invoiceYear
      );
    rtListenersList.map((listener: Function) => listener());
    setRtListenersList([
      orderListener,
      abandonedCartListener,
      invoiceOrderListener,
    ]);
  };

  const [eurToDol, seteurToDol] = useState(1.1);
  React.useEffect(() => {
    exchangeServices
      .getEurDollarChange()
      .then((exchangeRate) => {
        seteurToDol(exchangeRate);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const searchValueHandler = (value: string) => {
    setSearchValue(parseInt(value) ? parseInt(value) : undefined);
  };
  const invoiceYearHandler = (value: string) => {
    setInvoiceYear(value);
  };
  const searchInvoiceValueHandler = (value: string) => {
    setSearchInvoiceValue(parseInt(value) ? parseInt(value) : undefined);
  };
  useEffect(() => {
    const orderListener: Function = orderServices.listenToAllOrders(
      (orders: Order[]) => setOrders(orders),
      filterBy,
      searchValue > 1100 ? searchValue - 1100 : searchValue
    );
    const abandonedCartListener: Function =
      orderServices.listenToAllAbandonedCart(
        (abandonedCarts: AbandonedCart[]) => setAbandonedCarts(abandonedCarts)
      );

    const invoiceOrderListener: Function =
      orderServices.listenToAllOrderWithInvoice(
        (orders: Order[]) => setInvoiceOrders(orders),
        invoiceOrderBy,
        searchInvoiceValue > 1100
          ? searchInvoiceValue - 1100
          : searchInvoiceValue,
        invoiceYear
      );
    rtListenersList.map((listener: Function) => listener());
    setRtListenersList([
      orderListener,
      abandonedCartListener,
      invoiceOrderListener,
    ]);
  }, [filterBy, searchValue, invoiceOrderBy, invoiceYear]);

  useEffect(() => {
    if (currentOrder && currentOrder.userId) {
      setIsLoading(true);
      adminServices
        .getUser(currentOrder.userId)
        .then((user) => {
          setCurrentClient(user);
          return hostingCountryServices.getHostingCountry(
            currentOrder.hostingCountryId
          );
        })
        .then((hostingCountry) => {
          setCurrentHostingCountry(hostingCountry);
          return machinesServices.getMachine(currentOrder.machineId);
        })
        .then((machine) => {
          setCurrentMachine(machine);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  }, [currentOrder]);

  useEffect(() => {
    if (currentAbandonedCart && currentAbandonedCart.userId) {
      setIsLoading(true);
      adminServices
        .getUser(currentAbandonedCart.userId)
        .then((user) => {
          setCurrentClient(user);
          return hostingCountryServices.getHostingCountry(
            currentAbandonedCart.hostingCountryId
          );
        })
        .then((hostingCountry) => {
          setCurrentHostingCountry(hostingCountry);
          return machinesServices.getMachine(currentAbandonedCart.machineId);
        })
        .then((machine) => {
          setCurrentMachine(machine);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  }, [currentAbandonedCart]);

  const filterByHandler = (orderStep: filterByType) => {
    setFilerBy(orderStep);
  };

  const invoiceOrderByHandler = (invoiceOrderBy: invoiceOrderBy) => {
    setInvoiceOrderBy(invoiceOrderBy);
  };
  const getOrderData = async (id: string) => {
    try {
      setIsLoading(true);
      const order = await adminServices.getOrder(id);
      setCurrentOrder(order);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };
  const getAbandonedCartData = async (id: string) => {
    try {
      setIsLoading(true);
      const abandonedCart = await adminServices.getAbandonedCart(id);
      setCurrentAbandonedCart(abandonedCart);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };
  const currentOrderHandler = (order: Order | undefined) => {
    setCurrentOrder(order);
  };

  const currentAbandonedCartHandler = (
    abandonedCart: AbandonedCart | undefined
  ) => {
    setCurrentAbandonedCart(abandonedCart);
  };

  const updateAbandonedCart = async (abandonedCart: any) => {
    try {
      setIsLoading(true);
      await orderServices.updateAbandonedCart(
        currentAbandonedCart.id,
        abandonedCart
      );

      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const updateOrder = async (order: any) => {
    try {
      setIsLoading(true);
      await adminServices.updateOrder(currentOrder.id, order);

      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const createOrder = async (order: OrderData) => {
    try {
      setIsLoading(true);
      await adminServices.createOrder(order);

      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  return (
    <OrderAdminContext.Provider
      value={{
        orders,
        currentOrder,
        currentClient,
        currentHostingCountry,
        currentMachine,
        isLoading,
        searchValue,
        searchInvoiceValue,
        abandonedCarts,
        currentAbandonedCart,
        eurToDol,
        invoiceOrders,
        initOrders,
        getOrderData,
        currentOrderHandler,
        updateOrder,
        filterByHandler,
        createOrder,
        searchValueHandler,
        currentAbandonedCartHandler,
        updateAbandonedCart,
        getAbandonedCartData,
        searchInvoiceValueHandler,
        invoiceOrderByHandler,
        invoiceYearHandler,
      }}
    >
      {props.children}
    </OrderAdminContext.Provider>
  );
};

export default OrderAdminContext;
