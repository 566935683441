import {
  Order,
  PromoCode,
  UserData,
  AbandonedCart,
  AbandonedCartData,
} from "../../types";
import { auth, functions, firestore } from "../app/firebase-service";
import { httpsCallable } from "firebase/functions";

import {
  doc,
  onSnapshot,
  updateDoc,
  collection,
  addDoc,
  getDocs,
  where,
  query,
  getDoc,
  orderBy,
  startAt,
  endAt,
  Timestamp
} from "firebase/firestore";
import { Alert } from "../../types";
import errors from "../../messages/error";
import success from "../../messages/success";
import { filterByType, invoiceOrderBy } from "../../contexts/order-admin.ctx";
const orderServices = {
  listenToOrders(userId: string, listenerCallback: Function) {
    const ordersCollection = collection(firestore, `orders`);
    const q = query(ordersCollection, where("userId", "==", userId));
    const ordersListener = onSnapshot(q, async (ordersSnap) => {
      const orders: Order[] = [];
      if (!ordersSnap.empty) {
        for (const order of ordersSnap.docs) {
          orders.push({
            ...(order.data() as Order),
            id: order.id,
          });
        }
      }
      const sortedOrders = orders.sort(
        (a, b) => b.date.seconds - a.date.seconds
      );
      listenerCallback(sortedOrders);
    });
    return ordersListener;
  },
  listenToAllOrders(
    listenerCallback: Function,
    filterBy: filterByType,
    searchValue?: number
  ) {
    const ordersCollection = collection(firestore, `orders`);
    console.log(searchValue);
    const q =
      filterBy == "all"
        ? searchValue
          ? query(
              ordersCollection,
              where("orderNumber", ">=", searchValue),
              orderBy("orderNumber", "asc")
            )
          : query(ordersCollection, orderBy("orderNumber", "desc"))
        : searchValue
        ? query(
            ordersCollection,
            where("orderStep", "==", filterBy),
            where("orderNumber", ">=", searchValue),
            orderBy("orderNumber", "asc")
          )
        : query(
            ordersCollection,
            where("orderStep", "==", filterBy),
            orderBy("orderNumber", "desc")
          );
    const ordersListener = onSnapshot(q, async (ordersSnap) => {
      const orders: Order[] = [];
      if (!ordersSnap.empty) {
        for (const order of ordersSnap.docs) {
          // const userrsCollection = doc(firestore, `users`, order.data().userId);
          // const userData = await getDoc(userrsCollection);
          orders.push({
            ...(order.data() as Order),
            // user: {
            //   id: userData.id,
            //   ...(userData.data() as UserData),
            // },
            id: order.id,
          });
        }
      }
      // const sortedOrders = orders.sort(
      //   (a, b) => b.date.seconds - a.date.seconds
      // );
      listenerCallback(orders);
    });
    return ordersListener;
  },
  listenToAllOrderWithInvoice(
    listenerCallback: Function,
    direction: invoiceOrderBy,
    searchValue?: number,
    year?:string
  ) {
    const ordersCollection = collection(firestore, `orders`);
    console.log(searchValue);
    let startOfYearTimestamp;
    let endOfYearTimestamp;
    if(year){
      const startOfYear = new Date(`${year}-01-01`);
    const endOfYear = new Date(`${year}-12-31`);
    startOfYearTimestamp = Timestamp.fromDate(startOfYear);
    endOfYearTimestamp = Timestamp.fromDate(endOfYear);
    }
    const q =
    startOfYearTimestamp && endOfYearTimestamp ? 
    searchValue
          ? query(
              ordersCollection,
              where("date", ">=", startOfYearTimestamp),
              where("date", "<=", endOfYearTimestamp),
              where("orderNumber", ">=", searchValue),
              orderBy("orderNumber",direction)
            )
          : query(ordersCollection, where("date", ">=", startOfYearTimestamp),
          where("date", "<=", endOfYearTimestamp),orderBy("date", "desc")) :  searchValue
          ? query(
              ordersCollection,
              where("orderNumber", ">=", searchValue),
              orderBy("orderNumber",direction)
            )
          : query(ordersCollection, orderBy("orderNumber", direction));

    const ordersListener = onSnapshot(q, async (ordersSnap) => {
      const orders: Order[] = [];
      if (!ordersSnap.empty) {
        for (const order of ordersSnap.docs) {
          orders.push({
            ...(order.data() as Order),

            id: order.id,
          });
        }
      }

      listenerCallback(orders);
    });
    return ordersListener;
  },
  listenToAllAbandonedCart(
    listenerCallback: Function
    // filterBy: filterByType,
    // searchValue?: number
  ) {
    const abandonedCartCollection = collection(firestore, `abandoned_cart`);

    const abandonedCartListener = onSnapshot(
      abandonedCartCollection,
      async (abandonedCartSnap) => {
        const abandonedCarts: AbandonedCart[] = [];
        if (!abandonedCartSnap.empty) {
          for (const abandonedCart of abandonedCartSnap.docs) {
            abandonedCarts.push({
              ...(abandonedCart.data() as AbandonedCart),
              id: abandonedCart.id,
            });
          }
        }
        const sortedAbandonedCarts = abandonedCarts.sort(
          (a, b) => b.date.seconds - a.date.seconds
        );
        listenerCallback(sortedAbandonedCarts);
      }
    );
    return abandonedCartListener;
  },
  updateAbandonedCart(
    abandonedCartId: string,
    abandonedCartData: any
  ): Promise<any> {
    return new Promise((res, rej) => {
      const abandonedCartDoc = doc(
        firestore,
        "abandoned_cart",
        abandonedCartId
      );

      updateDoc(abandonedCartDoc, {
        ...abandonedCartData,
      })
        .then(() => {
          res("");
        })
        .catch((e) => {
          console.log(e);
          rej("");
        });
    });
  },
  goToNextStepOrder(orderId: string): Promise<any> {
    return new Promise((res, rej) => {
      const orderDataDoc = doc(firestore, "orders", orderId);
      getDoc(orderDataDoc)
        .then((orderData) => {
          return updateDoc(orderDataDoc, {
            orderStep:
              orderData.data().orderStep == "waiting"
                ? "confirmed"
                : orderData.data().orderStep == "confirmed"
                ? "installed"
                : "error",
          });
        })
        .then(() => {
          res(success.PERSONAL_DATA_SUCCES);
        })
        .catch((e) => {
          console.log(e);
          rej(errors.PERSONAL_DATA_ERROR);
        });
    });
  },
  getPromoCode(code: string): Promise<PromoCode> {
    return new Promise((res, rej) => {
      // Create new user in DB and in Firebase auth Cloud functions
      const promoCodeCollection = collection(firestore, "promo_code");

      const q = query(promoCodeCollection, where("code", "==", code));
      getDocs(q)
        .then((promoCodeSnap) => {
          if (!promoCodeSnap.empty) {
            res({
              id: promoCodeSnap.docs[0].id,
              ...promoCodeSnap.docs[0].data(),
            } as PromoCode);
          } else {
            rej({
              header: "Code introuvable",
              detail: "Veuillez essayer un autre code promo",
            });
          }
        })
        .catch((e) => {
          console.log(e);
          // generic error
          rej(errors.SIGNUP_ERROR_ALERT);
        });
    });
  },
  signUpDolphinUser(
    email: string,
    firstname: string,
    lastname: string,
    phone: string,
    country: string,
    countryDialCode: string,
    countryCode: string
  ): Promise<Alert> {
    return new Promise((res, rej) => {
      // Create new user in DB and in Firebase auth Cloud functions
      const createDolphinUser = httpsCallable(functions, "createDolphinUser");
      createDolphinUser({
        email,
        firstname,
        lastname,
        phone,
        country,
        countryDialCode,
        countryCode,
      })
        .then((result: any) => {
          if (result.data.status == "ERROR") {
            rej(errors.SIGNUP_ERROR_ALERT);
          } else {
            res({
              header: "Succès !",
              detail: "Vous avez bien souscris au programme !",
            });
          }
        })
        .catch((e) => {
          console.log(e);
          // generic error
          rej(errors.SIGNUP_ERROR_ALERT);
        });
    });
  },
};

export default orderServices;
