import React, { useState } from "react";
import { Alert, Platform } from "react-native";
import { StackNavigationProp } from "@react-navigation/stack";

import authServices from "../services/apis/auth-service";
import BaseButton from "../components/Base/BaseButton/BaseButton";
import { Alert as AlertType } from "../types";
import i18n from "../translations/i18n";
import routes from "../routes";
import BaseAuthForm from "../components/Auth/BaseAuthForm";
import loginFormSchema from "../forms/login.json";
import BaseForm from "../components/Base/BaseForm/BaseForm";
import SignInComponent from "../components/Auth/SignIn";
interface SignInProps {
  navigation: StackNavigationProp<any>;
}
const SignIn: React.FC<SignInProps> = ({ navigation }) => {
  return <SignInComponent navigation={navigation} />;
};

export default SignIn;
