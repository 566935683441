import React, { PropsWithChildren } from "react";
import { View, StyleSheet } from "react-native";

import colors from "../../configs/colors";
import useIsMobile, { useDimensions } from "../../utils/useIsMobile";

const widthThreshold = 550; // Width threshold for responsiveness

const BaseAuthForm: React.FC<PropsWithChildren & { bgColor?: string }> = (
  props
) => {
  const deviceDimensions = useDimensions();
  const isMobile = useIsMobile(); // get device dimensions
  return (
    <View
      style={
        isMobile
          ? styles.containerMobile
          : { ...styles.container, backgroundColor: props.bgColor || "#fff" }
      }
    >
      <View
        style={[
          styles.inputContainer,
          {
            width:
              deviceDimensions.width < widthThreshold ? "90%" : widthThreshold,
          },
        ]}
      >
        {props.children}
      </View>
    </View>
  );
};

export default BaseAuthForm;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.white,
  },
  containerMobile: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.black,
  },
  inputContainer: {
    padding: 10,
  },
});
