import * as React from "react";
import Svg, {
  Path,
  SvgProps,
  Defs,
  LinearGradient,
  Use,
  Pattern,
  Image,
  Stop,
} from "react-native-svg";

const ProIcon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width={109} height={72} viewBox="0 0 109 72" fill="none" {...props}>
      <Path
        d="M95.833 22.667H13.1663C7.4594 22.667 2.83301 27.2934 2.83301 33.0003V84.667C2.83301 90.3739 7.4594 95.0003 13.1663 95.0003H95.833C101.54 95.0003 106.166 90.3739 106.166 84.667V33.0003C106.166 27.2934 101.54 22.667 95.833 22.667Z"
        stroke="#0A0A0A"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M95.833 22.667H13.1663C7.4594 22.667 2.83301 27.2934 2.83301 33.0003V84.667C2.83301 90.3739 7.4594 95.0003 13.1663 95.0003H95.833C101.54 95.0003 106.166 90.3739 106.166 84.667V33.0003C106.166 27.2934 101.54 22.667 95.833 22.667Z"
        stroke="url(#paint0_linear_1736_157)"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M95.833 22.667H13.1663C7.4594 22.667 2.83301 27.2934 2.83301 33.0003V84.667C2.83301 90.3739 7.4594 95.0003 13.1663 95.0003H95.833C101.54 95.0003 106.166 90.3739 106.166 84.667V33.0003C106.166 27.2934 101.54 22.667 95.833 22.667Z"
        stroke="url(#pattern0)"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M75.1663 95V12.3333C75.1663 9.59276 74.0777 6.96444 72.1398 5.02656C70.2019 3.08869 67.5736 2 64.833 2H44.1663C41.4258 2 38.7974 3.08869 36.8596 5.02656C34.9217 6.96444 33.833 9.59276 33.833 12.3333V95"
        stroke="#0A0A0A"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M75.1663 95V12.3333C75.1663 9.59276 74.0777 6.96444 72.1398 5.02656C70.2019 3.08869 67.5736 2 64.833 2H44.1663C41.4258 2 38.7974 3.08869 36.8596 5.02656C34.9217 6.96444 33.833 9.59276 33.833 12.3333V95"
        stroke="url(#paint1_linear_1736_157)"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M75.1663 95V12.3333C75.1663 9.59276 74.0777 6.96444 72.1398 5.02656C70.2019 3.08869 67.5736 2 64.833 2H44.1663C41.4258 2 38.7974 3.08869 36.8596 5.02656C34.9217 6.96444 33.833 9.59276 33.833 12.3333V95"
        stroke="url(#pattern1)"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Defs>
        <Pattern
          id="pattern0"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <Use
            xlinkHref="#image0_1736_157"
            transform="matrix(0.000833333 0 0 0.00119048 0 -0.0357142)"
          />
        </Pattern>
        <Pattern
          id="pattern1"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <Use
            xlinkHref="#image0_1736_157"
            transform="matrix(0.0025 0 0 0.00111111 -1 0)"
          />
        </Pattern>
        <LinearGradient
          id="paint0_linear_1736_157"
          x1="2.83301"
          y1="12.3974"
          x2="111.322"
          y2="19.84"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#A5259D" />
          <Stop offset="1" stopColor="#4890FE" />
        </LinearGradient>
        <LinearGradient
          id="paint1_linear_1736_157"
          x1="33.833"
          y1="-11.2037"
          x2="77.4132"
          y2="-10.2736"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#A5259D" />
          <Stop offset="1" stopColor="#4890FE" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
};

export default ProIcon;
