import React, { useState } from "react";
import { Alert, Platform } from "react-native";
import { StackNavigationProp } from "@react-navigation/stack";

import authServices from "../services/apis/auth-service";
import BaseButton from "../components/Base/BaseButton/BaseButton";
import { Alert as AlertType } from "../types";
import i18n from "../translations/i18n";
import routes from "../routes";
import BaseAuthForm from "../components/Auth/BaseAuthForm";
import BaseForm from "../components/Base/BaseForm/BaseForm";

import SignUpComponent from "../components/Auth/Signup";
import { RouteProp } from "@react-navigation/native";
interface SignUpProps {
  navigation: StackNavigationProp<any>;
  route: RouteProp<{ params: { type: string } }, "params">;
}
const SignUp: React.FC<SignUpProps> = ({ navigation, route }) => {
  const [isLoading, setIsLoading] = useState(false);

  return <SignUpComponent navigation={navigation} route={route} />;
};

export default SignUp;
