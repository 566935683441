import React, { useState } from "react";
import { Platform } from "react-native";
import { StackNavigationProp } from "@react-navigation/stack";

import authServices from "../../services/apis/auth-service";
import BaseButton from "../Base/BaseButton/BaseButton";
import { FunctionalAlert, Alert } from "../../types";
import i18n from "../../translations/i18n";
import routes from "../../routes";
import BaseAuthForm from "./BaseAuthForm";
import resetPasswordFormSchema from "../../forms/reset-password.json";
import BaseForm from "../Base/BaseForm/BaseForm";
import { showAlert } from "react-native-customisable-alert";
import Ionicons from "@expo/vector-icons/Ionicons";
import { RouteProp } from "@react-navigation/native";
interface ResetPasswordProps {
  navigation: StackNavigationProp<any>;
  route: RouteProp<any>;
}
const ResetPassword: React.FC<ResetPasswordProps> = ({ navigation, route }) => {
  const [isLoading, setIsLoading] = useState(false);
  console.log(route);
  const onSubmit = (data: Record<string, any>) => {
    if (!isLoading) {
      setIsLoading(true);
      authServices
        .resetPassword(route?.params?.oobCode, data.password)
        .then((alertMessage: Alert) => {
          setIsLoading(false);
          showAlert({
            title: alertMessage.header,
            message: alertMessage.detail,
            alertType: "success",
            onPress: () => navigation.navigate(routes.signin.screenName),
          });
        })
        .catch((alertMessage: Alert) => {
          showAlert({
            title: alertMessage.header,
            message: "Une erreur est survenue.",
            alertType: "error",
            customIcon: (
              <Ionicons name="close-circle-outline" size={50} color="#FF5B5B" />
            ),
            onPress: () => console.log(""),
          });

          console.log("error :", alertMessage);

          setIsLoading(false);
        });
    }
  };
  return (
    <BaseAuthForm bgColor={"#000"}>
      <BaseForm
        schema={resetPasswordFormSchema}
        onSubmit={onSubmit}
        buttonTitle={"resetPassword"}
        isLoading={isLoading}
      />

      <BaseButton
        noGradient
        title={i18n.t("alreadyRegistered")}
        onPress={() => navigation.navigate(routes.signin.screenName)}
      />
    </BaseAuthForm>
  );
};

export default ResetPassword;
