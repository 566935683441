export default {
  email: {
    required: "L'email est requis",
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      message: "Adresse email invalide",
    },
  },
  newEmail: {
    required: "L'email est requis",
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      message: "Adresse email invalide",
    },
  },
  password: {
    required: "Le mot de passe est requis",
    minLength: {
      value: 12,
      message: "Le mot de passe doit contenir au moins 12 caractères",
    },
    pattern: {
      value:
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{12,}$/,

      message:
        "Le mot de passe doit contenir au moins 1 lettre minuscule, 1 lettre majuscule, 1 chiffre et 1 caractère spécial !@#$%^&*",
    },
  },
  address: {
    required: "L'adresse est requise",
  },
  payoutThreshold: {
    required: "Le seul de paiement est requis",
    pattern: {
      value: /^[0-9]*$/,
      message: "Le seuil de paiement doi être un nombre",
    },
  },
  gasPriceLimit: {
    required: "Le prix limite de gas est requis",
    pattern: {
      value: /^[0-9]*$/,
      message: "Le seuil de paiement doi être un nombre",
    },
  },
  amountToInvest: {
    required: "Le montant à investir est requis",
    pattern: {
      value: /^[0-9]*$/,
      message: "Le montant à investir doi être un nombre",
    },
  },
  machine: {
    required: "La machine est requise",
  },
  phone: {
    required: "Le numéro de téléphone est requis",
  },
  phoneCountry: {
    required: "Requis",
  },
  companyName: {
    required: "Le nom de l'entreprise est requis",
  },
  country: {
    required: "Le pays est requis",
  },
  city: {
    required: "La ville est requise",
  },
};
