import React from "react";
import { View, ActivityIndicator, StyleSheet } from "react-native";
import colors from "../../configs/colors";
import BaseLoader from "../Base/BaseLoader/BaseLoader";
const FullScreenLoader: React.FC = () => {
  return (
    <View style={styles.container}>
      <BaseLoader />
      {/* <ActivityIndicator size={"large"} color={colors.green} /> */}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.black,
  },
});

export default FullScreenLoader;
