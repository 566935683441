// React
import React, {
  useEffect,
  useState,
  PropsWithChildren,
  useContext,
} from "react";
import { Machine, Company, CompanyBoss, Order } from "../types";
import orderServices from "../services/apis/order-service";
import AuthContext from "./auth.ctx";
const OrderContext = React.createContext({
  orders: [] as Order[],
  initOrders: () => {},
});

export const OrderContextProvider: React.FC<PropsWithChildren> = (
  props: any
) => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rtListenersList, setRtListenersList] = useState([] as Function[]);
  const authCtx = useContext(AuthContext);
  const initOrders = () => {
    const orderListener: Function = orderServices.listenToOrders(
      authCtx.userId,
      (orders: Order[]) => setOrders(orders)
    );

    rtListenersList.map((listener: Function) => listener());
    setRtListenersList([orderListener]);
  };

  return (
    <OrderContext.Provider
      value={{
        orders,
        initOrders,
      }}
    >
      {props.children}
    </OrderContext.Provider>
  );
};

export default OrderContext;
