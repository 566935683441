import React from "react";
import { ActivityIndicator, Platform } from "react-native";
import colors from "../../../configs/colors";
const BaseLoader: React.FC<{ size?: number; color?: string }> = (props) => {
  if (Platform.OS == "web") {
    return (
      <div>
        <style>{`
                .custom-loader {
                  width: ${props.size || "40"}px;
                  height: ${props.size || "40"}px;
                  color:${props.color || "#766DF4"};
                  position: relative;
                }
                .custom-loader:before {
                  content:'';
                  position: absolute;
                  inset:0;
                  border-radius: 50%;
                  background:
                    conic-gradient(from 0deg,  #0000, currentColor 1deg 120deg,#0000 121deg) top right,
                    conic-gradient(from 120deg,#0000, currentColor 1deg 120deg,#0000 121deg) bottom,
                    conic-gradient(from 240deg,#0000, currentColor 1deg 120deg,#0000 121deg) top left;
                  background-size:${props.size || "40"}px ${
          props.size || "40"
        }px;
                  background-repeat: no-repeat;
                  animation: sp5 2s infinite cubic-bezier(0.3,1,0,1);
                }
                @keyframes sp5 {
                  33%  {inset:-8px;transform: rotate(0deg)}
                  66%  {inset:-8px;transform: rotate(180deg)}
                  100% {inset:0   ;transform: rotate(180deg)}
                }
              `}</style>
        <div className="custom-loader" />
      </div>
    );
  }
  return <ActivityIndicator size={"large"} color={props.color} />;
};

export default BaseLoader;
