import axios from "axios";
const API_KEY = "417ff3170d034695447eb092fa09651d";
const BASE_URL = "http://api.exchangeratesapi.io/v1/latest";
const PARAMS = `?access_key=${API_KEY}&base=EUR&symbols=USD`;
const FULL_URL = `${BASE_URL}${PARAMS}`;
const api = axios.create({
  baseURL: BASE_URL,
});
console.log(BASE_URL);
const exchangeServices = {
  // SignUp Service
  getEurDollarChange(): Promise<number> {
    return new Promise((res, rej) => {
      axios
        .get(FULL_URL)
        .then((response) => {
          res(response.data.rates.USD);
        })
        .catch((err) => {
          console.log(err);
          rej(err.response.data);
        });
    });
  },
};
export default exchangeServices;
