import React from "react";
import {
  View,
  ViewStyle,
  TextInputAndroidProps,
  TextInputProps,
  StyleSheet,
  TextStyle,
  DimensionValue,
} from "react-native";
import { InputSchema } from "../../../../types";
import CountryPicker from "../../../CountryPicker/CountryPicker";
import {
  useForm,
  Controller,
  SubmitHandler,
  FieldValues,
} from "react-hook-form";
import { Picker } from "@react-native-picker/picker";
import CustomPicker from "../../../CountryPicker/CustomPicker";
import formRules from "../../../../utils/formRules";
import BaseInput from "../BaseInputSalesFunnel/BaseInputSalesFunnel";
import BaseButton from "../BaseButtonSalesFunnel/BaseButtonSalesFunnel";
import BaseText from "../BaseTextSalesFunnel/BaseTextSalesFunnel";
import colors from "../../../../configs/colors";
import { useIsFocused } from "@react-navigation/native";
import useIsMobile from "../../../../utils/useIsMobile";
import Ionicons from "@expo/vector-icons/Ionicons";
import BasePressable from "../../BasePressable/BasePressable";
interface BaseFormProps {
  schema: InputSchema[];
  onSubmit: SubmitHandler<FieldValues>;
  buttonTitle: string;
  isLoading: boolean;
  style?: ViewStyle;
  labelStyle?: TextStyle;
  children?: React.ReactNode;
  buttonNotFullWidth?: boolean;
  buttonBgColor?: string;
  buttonTitleColor?: string;
  inputBgColor?: string;
  inputFontColor?: string;
  inputBorderColor?: string;
  inputBorderWidth?: number;
  inputBorderRadius?: number;
  inputTextStyle?: TextStyle;
}

const BaseForm: React.FC<BaseFormProps> = ({
  schema,
  onSubmit,
  buttonTitle,
  isLoading,
  style,
  buttonNotFullWidth,
  labelStyle,
  buttonBgColor,
  buttonTitleColor,
  children,
  inputBgColor,
  inputFontColor,
  inputBorderColor,
  inputBorderRadius,
  inputBorderWidth,
  inputTextStyle,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();
  const isMobile = useIsMobile();

  const [showPassword, setShowPassword] = React.useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <View style={style}>
      {schema.map((input, key) => {
        if (input.type == "section") {
          return (
            <View key={key} style={styles.sectionStyle}>
              <BaseText style={{ fontSize: 20 }} text={input.label} />
            </View>
          );
        }
        if (input.type == "country") {
          return (
            <Controller
              key={key}
              control={control}
              defaultValue={input.defaultValue}
              name={input.name}
              rules={formRules[input.name]}
              render={({ field: { onChange, onBlur, value } }) => {
                return (
                  <View
                    style={{
                      width:
                        input.width && !isMobile
                          ? (input.width as DimensionValue)
                          : ("100%" as DimensionValue),
                      zIndex: 8 * (schema.length - key),
                    }}
                  >
                    <CountryPicker
                      type={input.display}
                      labelStyle={{ color: isMobile ? "#fff" : "#000" }}
                      containerStyle={{
                        backgroundColor: colors.white,
                        borderColor: inputBorderColor || "#E4E4E4",
                        borderWidth: 2,
                        borderStyle: "solid",
                        borderTopWidth: 2,
                        borderBottomWidth: 2,
                        height: 59,
                      }}
                      absoluteViewStyle={{ height: 180 }}
                      onPress={(data: any) => onChange(data)}
                      label={input.label}
                    />
                    {errors[input.name] && (
                      <BaseText
                        text={errors[input.name].message.toString()}
                        style={{
                          color: colors.red,
                          fontSize: 12,
                          marginTop: isMobile ? -4 : -10,
                        }}
                      />
                    )}
                  </View>
                );
              }}
            />
          );
        }

        if (input.type == "select") {
          return (
            <Controller
              key={key}
              control={control}
              defaultValue={input.defaultValue}
              name={input.name}
              rules={formRules[input.name]}
              render={({ field: { onChange, onBlur, value } }) => {
                return (
                  <View
                    style={{
                      width:
                        input.width && !isMobile
                          ? (input.width as DimensionValue)
                          : ("100%" as DimensionValue),
                      zIndex: 8 * (schema.length - key),
                    }}
                  >
                    <CustomPicker
                      type={input.display}
                      labelStyle={{ color: isMobile ? "#fff" : "#000" }}
                      containerStyle={{
                        backgroundColor: colors.white,
                        borderColor: inputBorderColor || "#E4E4E4",
                        borderWidth: 2,
                        borderStyle: "solid",
                        borderTopWidth: 2,
                        borderBottomWidth: 2,
                        height: 59,
                      }}
                      defaultValue={input.defaultValue}
                      values={input.selectionsValues}
                      onPress={(data: any) => onChange(data)}
                      label={input.label}
                    />
                    {errors[input.name] && (
                      <BaseText
                        text={errors[input.name].message.toString()}
                        style={{
                          color: colors.red,
                          fontSize: 12,
                          marginTop: isMobile ? -4 : -10,
                        }}
                      />
                    )}
                  </View>
                );
              }}
            />
          );
        }
        return (
          <Controller
            key={key}
            control={control}
            rules={
              input.name == "confirmPassword"
                ? {
                    validate: (val: string) => {
                      if (watch("password") != val) {
                        return "Your passwords do no match";
                      }
                    },
                  }
                : input.name == "confirmNewEmail"
                ? {
                    validate: (val: string) => {
                      if (watch("newEmail") != val) {
                        return "Your emails do no match";
                      }
                    },
                  }
                : formRules[input.name]
            }
            defaultValue={input.defaultValue ? input.defaultValue : ""}
            name={input.name}
            render={({ field: { onChange, onBlur, value } }) => (
              <View
                style={{
                  width:
                    input.width && !isMobile
                      ? (input.width as DimensionValue)
                      : ("100%" as DimensionValue),
                }}
              >
                <BaseInput
                  onChangeText={onChange}
                  onBlur={onBlur}
                  value={value}
                  labelStyle={labelStyle}
                  label={input.label}
                  // autoComplete={
                  //   input.autoComplete as TextInputAndroidProps["autoComplete"]
                  // }
                  inputTextStyle={inputTextStyle || null}
                  logo={input.logo ? input.logo : null}
                  secureTextEntry={
                    input.secureTextEntry
                      ? !showPassword
                      : input.secureTextEntry
                  }
                  inputMode={input.inputMode as TextInputProps["inputMode"]}
                  placeholder={input.placeholder}
                  error={errors[input.name] && errors[input.name].message}
                  helpText={input.helperText ? input.helperText : null}
                  editable={!input.notEditable}
                  backgroundColor={inputBgColor}
                  fontColor={inputFontColor}
                  borderColor={inputBorderColor}
                  borderRadius={inputBorderRadius}
                  borderWidth={inputBorderWidth}
                  rightLogo={
                    input.name == "confirmPassword" ||
                    input.name == "password" ||
                    input.name == "previousPassword" ? (
                      <BasePressable onClick={handleShowPassword}>
                        <Ionicons
                          name={
                            showPassword ? "eye-outline" : "eye-off-outline"
                          }
                          size={20}
                          color={inputBgColor == "#C4C4C40D" ? "#fff" : "#000"}
                        />
                      </BasePressable>
                    ) : null
                  }
                />
              </View>
            )}
          />
        );
      })}
      {children}
      <View style={styles.buttonContainer}>
        <BaseButton
          title={buttonTitle}
          isLoading={isLoading}
          onPress={handleSubmit(onSubmit)}
          notFullWidth={buttonNotFullWidth}
          bgColor={buttonBgColor}
          textColor={buttonTitleColor}
          textProps={{
            style: {
              color: buttonTitleColor,
            },
          }}
        />
      </View>
    </View>
  );
};

export default BaseForm;

const styles = StyleSheet.create({
  pickerStyle: {
    borderRadius: 8,
    paddingHorizontal: 16,
    paddingVertical: 10,
    height: 60,
    width: "100%",
    fontSize: 16,
    backgroundColor: colors.dark,
    color: colors.white,
    textDecorationColor: colors.white,
  },
  sectionStyle: {
    paddingVertical: 20,
  },
  buttonContainer: {
    marginVertical: 12,
    width: "100%",
  },
});
