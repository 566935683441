import { I18n } from "i18n-js";
import * as RNLocalize from "expo-localization";
import AsyncStorage from "@react-native-async-storage/async-storage";
import en from "./en.json";
import fr from "./fr.json";

const i18n = new I18n({
  en,
  fr,
});
i18n.enableFallback = true;

i18n.locale = RNLocalize.locale;
i18n.defaultLocale = "fr-FR";
console.log(i18n.locale);
export default i18n;
